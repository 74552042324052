const portalView = JSON.parse(localStorage.getItem("portalView"));
const colorFromApi = {
	header_bg_color: portalView?.header_bg_color ? portalView.header_bg_color : '#F9F9F9',
	header_font_color: portalView?.header_font_color ? portalView.header_font_color : '#337AB7',
	card_accent_color: portalView?.card_accent_color ? portalView.card_accent_color : '#24324C',
	card_accent_font_1_color: portalView?.card_accent_font_1_color ? portalView.card_accent_font_1_color : '#B8E1FF',
	card_accent_font_2_color: portalView?.card_accent_font_2_color ? portalView.card_accent_font_2_color : '#B8E1FF',
	link_color: portalView?.link_color ? portalView.link_color : '#337AB7',
	body_bg_color: portalView?.body_bg_color ? portalView.body_bg_color : '#f0f8ff',
	test_name_bg_color: portalView?.test_name_bg_color ? portalView.test_name_bg_color : '#F7FBFF',
	gradient_bg_color_1: portalView?.gradient_bg_color_1 ? portalView.gradient_bg_color_1 : '#2f6ab4',
	gradient_bg_color_2: portalView?.gradient_bg_color_2 ? portalView.gradient_bg_color_2 : '#2a86c7',
	gradient_font_color: portalView?.gradient_font_color ? portalView.gradient_font_color : '#FFFFFF',
	button_bg_color: portalView?.button_bg_color ? portalView.button_bg_color : '#337AB7',
	button_font_color: portalView?.button_font_color ? portalView.button_font_color : '#FFFFFF',
}

function LightenDarkenColor(col, amt) {
	col = parseInt(col.split('#')[1], 16);
	return `#${(((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16)}`;
}

export const color = {
	//todo, set all colors
	palette: {
		primary: {
			light: '#0052cc',
			main: '#0052cc',
			dark: '#0052cc',
		},
		secondary: {
			light: '#F4F5F7',
			main: '#F4F5F7',
			dark: '#F4F5F7',
		},
		success: {
			light: '#81c784',
			main: '#29C64C',
			dark: '#388e3c',
		},
		error: {
			light: '#e57373',
			main: '#f44336',
			dark: '#d32f2f',
		},
		warning: {
			light: '#ffb74d',
			main: '#ff9800',
			dark: '#f57c00',
			warningMsg: '#f7ae04',
		},
		info: {
			light: '#64b5f6',
			main: '#2196f3',
			dark: '#1976d2',
		},
		grey: {
			50: '#fafafa',
			100: '#f5f5f5',
			200: '#eeeeee',
			300: '#e0e0e0',
			400: '#bdbdbd',
			500: '#9e9e9e',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121',
		},
		text: {
			defaultLight: '#FFFFFF',
			defaultDark: '#000000',
			light: '#6E6E6E',
			light1: '#646464',
			main: '#3A3A3A',
			// dark: '#3A3A3A',
			// darkest: '#172b4d',
			lightBlue: '#D4EDFF',
			linkText: colorFromApi.button_bg_color,
			headerText: colorFromApi.header_font_color,
			link: colorFromApi.link_color,
			cardAccentFont1: colorFromApi.card_accent_font_1_color,
			cardAccentFont2: colorFromApi.card_accent_font_2_color,
			gradientFont: colorFromApi.gradient_font_color
		},
		background: {
			light: portalView ? colorFromApi.test_name_bg_color : '#F7FBFF',
			main: '#0747A6',
			dark: '#0747A6',
			menu: colorFromApi.card_accent_color,
			paper: {
				defaultLight: '#FFFFFF',
				defaultDark: '#000000',
				pagebg: colorFromApi.header_bg_color,
				portalbg: colorFromApi.body_bg_color,
				skeletonLoader: '#d1ddef',
				light: '#F4F5F7',
				lightBlue: '#EBF6FF',
				main: '#F2F2F2',
				dark: '#dfe1e6',
				default: '#D2E5FE',
				success: '#E4FCEF',
			},
			loginBurger: colorFromApi.card_accent_color,
			gradient1: colorFromApi.gradient_bg_color_1,
			gradient2: colorFromApi.gradient_bg_color_2,
			testNameBg: colorFromApi.test_name_bg_color
		},
		border: {
			light: '#E3E3E3',
			main: '#C1C7D0',
			dark: '#9aa4b3',
			focus: '#4c9aff',
			underline: colorFromApi.card_accent_color,
		},
		status: {
			complete: '#29C64C',
			received: '#2BB4E8',
			awaitingApproval: '#AEE469',
			approved: '#E4DF69',
			revision: '#F98600',
			cancelled: '#D0D0D0',
			draft: '#bfbfbf',
		},
		highlight: {
			yellow: '#fff974',
		},
	},
};

export const ButtonContainedDisabled = '#D0D0D0';
export const ButtonContainedEnabled = portalView ? colorFromApi.button_bg_color : '#337AB7';
export const ButtonContainedTextDisabled = '#7E7E7E';
export const ButtonContainedText = portalView ? colorFromApi.button_font_color : '#FFFFFF';
export const ButtonContainedHover = portalView && colorFromApi.button_bg_color ? LightenDarkenColor(colorFromApi.button_bg_color, -20) : '#1d609a';
export const ButtonContainedActive = portalView && colorFromApi.button_bg_color ? LightenDarkenColor(colorFromApi.button_bg_color, -20) : '#91BCF2';

export const ButtonOutlinedDisabled = '#E5E5E5';
export const ButtonOutlinedHover = portalView && colorFromApi.button_font_color ? LightenDarkenColor(colorFromApi.button_font_color, -20) : 'rgba(93, 156, 236, 0.04)';
export const ButtonOutlinedActive = 'rgba(93, 156, 236, 0.12)';

export const ButtonTextHover = 'rgba(93, 156, 236, 0.08)';
export const ButtonTextActive = 'rgba(93, 156, 236, 0.12)';

export const Warning = '#F8BB86';

export const breakpoints = {
	mobileOnly: 576,
	mobile: 768,
	tablet: 992,
	wideScreen: 1600,
};

const device = {
	mobileOnly: `(max-width: ${breakpoints.mobileOnly - 1}px)`,
	mobile: `(max-width: ${breakpoints.mobile - 1}px)`,
	tablet: `(max-width: ${breakpoints.tablet - 1}px)`,
	desktop: `(min-width: ${breakpoints.tablet}px)`,
	wideScreen: `(min-width: ${breakpoints.wideScreen}px)`,
};

export { device };
