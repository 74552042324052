import styled from "styled-components";

export const StatusBarCircleStyle = styled.li`
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  right: 24px;
  font-weight: ${({ status }) => (status === "active" ? "500" : "600")};
  padding: 0 4px;
  text-align: center;

  .content {
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }

  &:first-child:after {
    content: none;
  }

  & > span {
    height: 24px;
    width: 24px;
    line-height: 30px;
    border: ${({ status, theme }) => 
      status === "completed"
        ? "4px solid #29C64C"
        : status === "active"
        ? "4px solid ".concat(theme.color.palette.text.cardAccentFont1)
        : "2px solid ".concat(theme.color.palette.text.cardAccentFont1)
      };
    box-sizing: border-box;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: ${({ status }) =>
      status === "completed" ? "#29C64C" : "inherit"};
    color: ${({ status }) => (status === "completed" ? "white" : "inherit")};
    font-size: ${({ status }) =>
      status === "completed" ? "1.5em" : "inherit"};
  }
  &.completed + &:after {
    background: rgba(146, 209, 255);
  }
  &:after {
    width: 60%;
    height: 2px;
    content: "";
    position: absolute;
    top: 11px;
    left: -30%;
    z-index: 1;

    background: ${({ theme }) => theme.color.palette.text.cardAccentFont2};
  }
  .check-icon {
    position: relative;
    bottom: 0.45em;
    right: 0;
    font-size: 0.88em;
  }

  @media ${({ theme }) => theme.device.desktop} {
    width: 100%;
    float: none;
    font-weight: ${({ status }) => (status === "active" ? "500" : "600")};
    display: flex;
    align-items: center;

    & > span {
      display: inline-block;
      margin: 16px 8px;
    }
    &:after {
      width: 2px;
      height: 49%;
      top: -24%;
      left: 23px;
    }
    .check-icon {
      bottom: 0.45em;
      left: 0.06em;
      font-size: 0.88em;
    }
  }
`;

export const StatusBarWizardStyle = styled.div`
  p.title {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
  }
  background: ${({ theme }) => theme.color.palette.background.menu};
  color: ${({ theme }) => theme.color.palette.text.cardAccentFont1};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  height: 112px;
  min-width: 260px;

  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  margin: 0;
  padding-top: 16px;
  text-align: center;

  ul {
    margin-top: 8px;
  }

  @media ${({ theme }) => theme.device.desktop} {
    position: sticky;
    top: 0;
    border-radius: 8px 0 0 8px;
    height: inherit;
    min-width: 260px;
    flex: 0 0 20%;
    z-index: 11;
    padding: 16px 0 0 16px;
    ul {
      width: 100%;
    }
    p.title {
      display: block;
      font-weight: normal;
      font-size: 1.7em;
      color: #ffffff;
      padding: 32px 16px 0 16px;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    height: 170px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    height: 100px;
  }
  @media ${({ theme }) => theme.device.mobileOnly} {
    height: 115px;
  }
`;

export const WizardWrapper = styled.div`
	position: relative;
	background-color: ${({ theme }) =>
		theme.color.palette.background.paper.defaultLight};
	border-radius: 8px;
	overflow: hidden;
	height: 100%;

	@media ${({ theme }) => theme.device.desktop} {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		height: 100%;
		margin-top: 0;
	}
	@media ${({ theme }) => theme.device.mobile} {
    border-radius: 0;
	}

	& > div:nth-child(2) {
		width: 100%;
	}

	& .rsw_2Y,
	.rsw_2f {
		height: 100%;
	}
`;
