import React from 'react';
import CardContainer from 'shared/layout/CardContainer';
import Content from '../App/routes';
import Loading from 'shared/layout/Loading';
import {  StyledDashboard  } from './styles';
;

const Dashboard = props => {
	return (
		<StyledDashboard>
			<React.Suspense fallback={<Loading />}>
				<CardContainer className='wideScreen'>
					<Content />
				</CardContainer>
			</React.Suspense>
		</StyledDashboard>
	);
};

export default Dashboard;
