import { useEffect, useState } from 'react';
import { setLocalStorage, getLocalStorage } from 'shared/theme/storage';
import { PORTALVIEW_PERMISSION } from 'shared/constants/fields';
import { useAppStore } from 'App/store';
import { createColorMap } from 'shared/utils/misc';

const baseURL = process.env.REACT_APP_BASE_URL || "/portal/api";
export const useTheme = () => {
    const defaultTheme = getLocalStorage('default-theme')
    const { appStateDispatch } = useAppStore();
    const [theme, setTheme] = useState(defaultTheme);
    const [themeLoaded, setThemeLoaded] = useState(false);

    useEffect(() => {
        fetch(`${baseURL}/v1/portalview`)
            .then(res => {
                if (res)
                    return res.json();
                else 
                    throw new Error('Something went wrong');
            })
            .then(data => {

                let portalView = data.data;
                let colorFromApi = createColorMap(portalView);

                let color = {
                    //todo, set all colors
                    palette: {
                        primary: {
                            light: '#0052cc',
                            main: '#0052cc',
                            dark: '#0052cc',
                        },
                        secondary: {
                            light: '#F4F5F7',
                            main: '#F4F5F7',
                            dark: '#F4F5F7',
                        },
                        success: {
                            light: '#81c784',
                            main: '#29C64C',
                            dark: '#388e3c',
                        },
                        error: {
                            light: '#e57373',
                            main: '#f44336',
                            dark: '#d32f2f',
                        },
                        warning: {
                            light: '#ffb74d',
                            main: '#ff9800',
                            dark: '#f57c00',
                            warningMsg: '#f7ae04',
                        },
                        info: {
                            light: '#64b5f6',
                            main: '#2196f3',
                            dark: '#1976d2',
                        },
                        grey: {
                            50: '#fafafa',
                            100: '#f5f5f5',
                            200: '#eeeeee',
                            300: '#e0e0e0',
                            400: '#bdbdbd',
                            500: '#9e9e9e',
                            600: '#757575',
                            700: '#616161',
                            800: '#424242',
                            900: '#212121',
                        },
                        text: {
                            defaultLight: '#FFFFFF',
                            defaultDark: '#000000',
                            light: '#6E6E6E',
                            light1: '#646464',
                            main: '#3A3A3A',
                            // dark: '#3A3A3A',
                            // darkest: '#172b4d',
                            lightBlue: '#D4EDFF',
                            linkText: colorFromApi.button_bg_color,
                            headerText: colorFromApi.header_font_color,
                            link: colorFromApi.link_color,
                            cardAccentFont1: colorFromApi.card_accent_font_1_color,
                            cardAccentFont2: colorFromApi.card_accent_font_2_color,
                            gradientFont: colorFromApi.gradient_font_color
                        },
                        background: {
                            light: portalView ? colorFromApi.test_name_bg_color : '#F7FBFF',
                            main: '#0747A6',
                            dark: '#0747A6',
                            menu: colorFromApi.card_accent_color,
                            paper: {
                                defaultLight: '#FFFFFF',
                                defaultDark: '#000000',
                                pagebg: colorFromApi.header_bg_color,
                                portalbg: colorFromApi.body_bg_color,
                                skeletonLoader: '#d1ddef',
                                light: '#F4F5F7',
                                lightBlue: '#EBF6FF',
                                main: '#F2F2F2',
                                dark: '#dfe1e6',
                                default: '#D2E5FE',
                                success: '#E4FCEF',
                            },
                            loginBurger: colorFromApi.card_accent_color,
                            gradient1: colorFromApi.gradient_bg_color_1,
                            gradient2: colorFromApi.gradient_bg_color_2,
                            testNameBg: colorFromApi.test_name_bg_color
                        },
                        border: {
                            light: '#E3E3E3',
                            main: '#C1C7D0',
                            dark: '#9aa4b3',
                            focus: '#4c9aff',
                            underline: colorFromApi.card_accent_color,
                        },
                        status: {
                            complete: '#29C64C',
                            received: '#2BB4E8',
                            awaitingApproval: '#AEE469',
                            approved: '#E4DF69',
                            revision: '#F98600',
                            cancelled: '#D0D0D0',
                            draft: '#bfbfbf',
                        },
                        highlight: {
                            yellow: '#fff974',
                        },
                    },
                };

                let ButtonContainedDisabled = '#D0D0D0';
                let ButtonContainedEnabled = portalView ? colorFromApi.button_bg_color : '#337AB7';
                let ButtonContainedTextDisabled = '#7E7E7E';
                let ButtonContainedText = portalView ? colorFromApi.button_font_color : '#FFFFFF';
                let ButtonContainedHover = portalView && colorFromApi.button_bg_color ? LightenDarkenColor(colorFromApi.button_bg_color, -20) : '#1d609a';
                let ButtonContainedActive = portalView && colorFromApi.button_bg_color ? LightenDarkenColor(colorFromApi.button_bg_color, -20) : '#91BCF2';

                let ButtonOutlinedDisabled = '#E5E5E5';
                let ButtonOutlinedHover = portalView && colorFromApi.button_font_color ? LightenDarkenColor(colorFromApi.button_font_color, -20) : 'rgba(93, 156, 236, 0.04)';
                let ButtonOutlinedActive = 'rgba(93, 156, 236, 0.12)';

                let ButtonTextHover = 'rgba(93, 156, 236, 0.08)';
                let ButtonTextActive = 'rgba(93, 156, 236, 0.12)';

                let Warning = '#F8BB86';

                let breakpoints = {
                    mobileOnly: 576,
                    mobile: 768,
                    tablet: 992,
                    wideScreen: 1600,
                };

                let device = {
                    mobileOnly: `(max-width: ${breakpoints.mobileOnly - 1}px)`,
                    mobile: `(max-width: ${breakpoints.mobile - 1}px)`,
                    tablet: `(max-width: ${breakpoints.tablet - 1}px)`,
                    desktop: `(min-width: ${breakpoints.tablet}px)`,
                    wideScreen: `(min-width: ${breakpoints.wideScreen}px)`,
                };

                let newTheme = Object.assign(theme, { color, ButtonContainedDisabled, ButtonContainedEnabled, ButtonContainedTextDisabled, ButtonContainedText, ButtonContainedHover, ButtonContainedActive, ButtonOutlinedDisabled, ButtonOutlinedHover, ButtonOutlinedActive, ButtonTextHover, ButtonTextActive, Warning, breakpoints, device });
                setLocalStorage('default-theme', newTheme);
                setTheme(newTheme);
                setThemeLoaded(true);
                appStateDispatch?.({
                    type: 'SET_PRELOGIN_SETTINGS',
                    payload: {
                        canCreateAccount: !portalView[PORTALVIEW_PERMISSION.ACCOUNT_CREATE]?.hidden,
                        isSAMLEnabled: portalView.saml_enabled,
                        samlCustomError: portalView.saml_custom_error_message,
                    },
                });
            })
            .catch((error) => {
                console.error(error.message);
            });
    }, [theme]);

    return { theme, themeLoaded };
};

function LightenDarkenColor(col, amt) {
    col = parseInt(col.split('#')[1], 16);
    return `#${(((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16)}`;
}