import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import { ORDER_REQUEST_STATUS } from "shared/constants/statuses";
import makeStore from "shared/hooks/store/makeStore";
import { clsAction } from "./StatusBarWizard";

const initialStatuses = {
  createOrder: 'active',
  addSamples: null,
  selectTests: null,
  reviewOrder: null,
  confirm: null,
};

const pair = (keys, value) => keys.reduce((acc, k) => ({ ...acc, [k]: value }), {});

function getStatusMap(order = {}, action) {
  let statuses = {};
  const actions = invert(clsAction);
  const steps = Object.keys(clsAction);
  const activeStep = actions[action];
  const activeStepIdx = steps.indexOf(activeStep);
  const completedSteps = steps.slice(0, activeStepIdx);

  const isEmptyOrder = !activeStep && isEmpty(order);
  const isIncompleteOrder = !activeStep && !isEmpty(order);
  const isRevisingOrder =
    order.order_request_status === ORDER_REQUEST_STATUS.pending_changes;

  if (isRevisingOrder) {
    statuses = {
      ...pair(steps, 'completed'),
      confirm: 'active',
    };
  } else if (isEmptyOrder) {
    statuses = initialStatuses;
  } else if (isIncompleteOrder) {
    return undefined;
  } else {
    statuses = {
      ...pair(steps, null),
      ...pair(completedSteps, 'completed'),
      [activeStep]: activeStep === 'confirm' ? 'completed' : 'active',
    };
  }

  return { statuses };
}

export const STORE_ACTIONS = {
  SET_FIELD: "SET_FIELD",
  ON_LOAD: "ON_LOAD",
  GOTO_ORDER_DETAILS: "GOTO_ORDER_DETAILS",
  GOTO_ADD_SAMPLES: "GOTO_ADD_SAMPLES",
  GOTO_SELECT_TESTS: "GOTO_SELECT_TESTS",
  GOTO_REVIEW_ORDER: "GOTO_REVIEW_ORDER",
  GOTO_ORDER_CONFIRMATION: "GOTO_ORDER_CONFIRMATION",
  ORDER_REQUEST_ENTER: "ORDER_REQUEST_ENTER",
  ORDER_REQUEST_LEAVE: "ORDER_REQUEST_LEAVE",
  ORDER_LOADED: "ORDER_LOADED",
  SAMPLES_LOADED: "SAMPLES_LOADED",
  UPDATE_SAMPLE: "UPDATE_SAMPLE",
  ADD_SAMPLE: "ADD_SAMPLE",
  SAMPLE_SAVED: "SAMPLE_SAVED",
  DELETE_SAMPLE: "DELETE_SAMPLE",
  SET_SAMPLE_TO_EDIT: "SET_SAMPLE_TO_EDIT",
  CANCEL_EDIT_SAMPLE: "CANCEL_EDIT_SAMPLE",
  ASSAYS_LOADED: "ASSAYS_LOADED",
  CANCEL_ADD_ASSAY: "CANCEL_ADD_ASSAY",
  ADD_ASSAY_TO_SAMPLES: "ADD_ASSAY_TO_SAMPLES",
  REMOVE_ASSAY_FROM_SAMPLES: "REMOVE_ASSAY_FROM_SAMPLES",
  ASSAY_SELECTED: "ASSAY_SELECTED",
  SELECT_TESTS_LOADED: "SELECT_TESTS_LOADED",
  SET_TESTS_TO_EDIT: "SET_TESTS_TO_EDIT",
  SET_TEST_CREATION_TEMPLATES: "SET_TEST_CREATION_TEMPLATES",
  SET_TEST_OPTIONS: 'SET_TEST_OPTIONS',
  SET_TEST_OPTIONS_DEFAULT: 'SET_TEST_OPTIONS_DEFAULT',
  SET_PRICES_LOADING: "SET_PRICES_LOADING",
  SET_QUOTATION_ID: 'SET_QUOTATION_ID',
};

const _reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_FIELD":
      return {
        ...state,
        [payload.name]: payload.value,
      };
    case "ON_LOAD": // Not used for now
      return {
        ...state,
      };
    case "GOTO_ORDER_DETAILS":
      return {
        ...state,
        btnTransitionDisabled: false,
        currentStep: 1,
        ...getStatusMap(state.order, type),
      };
    case "GOTO_ADD_SAMPLES":
      return {
        ...state,
        currentStep: 2,
        btnTransitionDisabled: false,
        order: payload ? payload : state.order,
        ...getStatusMap(state.order, type),
      };

    case "GOTO_SELECT_TESTS":
      return {
        ...state,
        currentStep: 3,
        btnTransitionDisabled: false,
        ...getStatusMap(state.order, type),
      };
    case "GOTO_REVIEW_ORDER":
      return {
        ...state,
        currentStep: 4,
        btnTransitionDisabled: false,
        ...getStatusMap(state.order, type),
      };
    case "GOTO_ORDER_CONFIRMATION":
      return {
        ...state,
        btnTransitionDisabled: false,
        currentStep: 5,
        ...getStatusMap(state.order, type),
      };
    case "ORDER_REQUEST_ENTER":
      return {
        ...state,
        order: {},
        orderLoaded: false,
        btnTransitionDisabled: false,
        orderId: payload,
      };
    case "ORDER_REQUEST_LEAVE":
      return { ...initialState };
    case "ORDER_LOADED":
      return {
        ...state,
        order: payload,
        orderLoaded: true,
        btnTransitionDisabled: false,
        ...getStatusMap(payload, type),
      };
    case "SAMPLES_LOADED":
      return {
        ...state,
        samples: payload ? payload : state.samples,
        samplesLoaded: true,
      };
    case "UPDATE_SAMPLE":
      return {
        ...state,
        samples: state.samples.map((el) =>
          el.id === payload.id ? { ...el, ...payload } : el
        ),
        sampleToEdit: null,
      };
    case "ADD_SAMPLE":
      return {
        ...state,
        samples: [...state.samples, payload],
        sampleToEdit: null,
      };
    case "SAMPLE_SAVED":
      return {
        ...state,
        sampleToEdit: null,
      };
    case "DELETE_SAMPLE":
      return {
        ...state,
        sampleToDelete: null,
        samples: state.samples.filter((el) => el.id !== payload.id),
      };
    case "SET_SAMPLE_TO_EDIT":
      return {
        ...state,
        sampleToEdit: payload,
      };
    case "CANCEL_EDIT_SAMPLE":
      return {
        ...state,
        sampleToEdit: null,
      };
    case "ASSAYS_LOADED":
      return {
        ...state,
        assays: payload ? payload : state.assays,
        assaysLoaded: true,
      };
    case "CANCEL_ADD_ASSAY":
      return {
        ...state,
        assayToAdd: null,
      };
    case "ADD_ASSAY_TO_SAMPLES":
      return {
        ...state,
        tests: [...state.tests, ...payload],
        showTestsAddedBanner: true,
      };
    case "REMOVE_ASSAY_FROM_SAMPLES":
      let testIds = payload.map((t) => t.id);
      return {
        ...state,
        tests: state.tests.filter((t) => !testIds.includes(t.id)),
        showTestsAddedBanner: true,
      };
    case "ASSAY_SELECTED":
      return {
        ...state,
        assayToAdd: payload,
      };
    case "SELECT_TESTS_LOADED":
      return {
        ...state,
        showTestsAddedBanner: false,
      };
    case "SET_TESTS_TO_EDIT":
      return {
        ...state,
        testsToEdit: payload
      };
    case "SET_TEST_CREATION_TEMPLATES":
      return {
        ...state,
        testCreationTemplates: payload
      };
    case STORE_ACTIONS.SET_TEST_OPTIONS:
      return {
        ...state,
        testOptions: { 
          ...state.testOptions, 
          values: payload,
        },
      };
    case STORE_ACTIONS.SET_TEST_OPTIONS_DEFAULT:
      return {
        ...state,
        testOptions: { 
          ...state.testOptions, 
          defaultValues: { ...state.testOptions.defaultValues, ...payload }
        },
      };
    case STORE_ACTIONS.SET_PRICES_LOADING:
      return {
        ...state,
        pricesLoading: payload
      };
    case STORE_ACTIONS.SET_QUOTATION_ID:
      return {
        ...state,
        quotationId: payload,
      };
    default:
      return state;
  }
};

const initialState = {
  statuses: initialStatuses,
  currentStep: 1,
  orderId: null,
  btnTransitionDisabled: false,
  order: {},
  orderLoaded: false,
  orderForm: {},
  samples: [],
  samplesLoaded: false,
  sampleToEdit: null,
  sampleToDelete: null,
  tests: [],
  testsToEdit: [],
  testCreationTemplates: [],
  panelAssayPriceTotals: {},
  assays: [],
  assaysLoaded: false,
  assayToAdd: null,
  assayIdMap: {},
  assaySearchTerm: "",
  assayClasses: [],
  panelClasses: [],
  total: 0,
  pricesLoading: false,
  quotationId: null,
  testOptions: {
    /**
     * @deprecated
     */
    values: {},
    defaultValues: {},
  },
};

const [
  OrderRequestProvider,
  useOrderRequestStore,
  useOrderRequestDispatch,
] = makeStore(
  _reducer,
  initialState,
  "OrderRequest",
  () => JSON.parse(sessionStorage.getItem("OrderRequest")) || initialState
);

export { OrderRequestProvider, useOrderRequestStore, useOrderRequestDispatch };
