import React from "react";
import jwt_decode from "jwt-decode";
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import { ValidFileTypes } from "../constants/fileUpload";
import submited from 'assets/images/svg/status/submited.svg';
import received from 'assets/images/svg/status/received.svg';
import approved from 'assets/images/svg/status/approved.svg';
import pending from 'assets/images/svg/status/pending.svg';
import cancelled from 'assets/images/svg/status/cancelled.svg';
import draft from 'assets/images/svg/status/draft.svg';
import complete from 'assets/images/svg/status/complete.svg';
import { color } from './../theme/theme';
import { getFieldConfig } from '../utils/fields';
import { ORDER_REQUEST_STATUS, ORDER_STATUS } from 'shared/constants/statuses';
import { EntityType } from "shared/constants/entityTypes";
import { COLOR_MAP } from "shared/constants/misc";

/**
 * @module  Utils
 * Misc utils used by various modules
 */

/**
 * @param  {Object} obj             - A json object
 * @param  {Array}  requiredFields  - An array of object fields that require a value
 * @param  {Array}  atLeastOneField - An array of object fields in which only one of them requires a value
 * @return {Boolean}
 */
export const validateObj = (obj, requiredFields, atLeastOneField) => {
  let hasAllRequiredFields = true;
  let atLeastOneFieldPassed = false;

  for (let i in requiredFields) {
    let key = requiredFields[i];
    if (!obj[key]) {
      hasAllRequiredFields = false;
      break;
    }
  }

  for (let i in atLeastOneField) {
    let key = atLeastOneField[i];
    if (obj[key]) {
      atLeastOneFieldPassed = true;
      break;
    }
  }

  return hasAllRequiredFields && atLeastOneFieldPassed;
};

export const validateFile = (file, types) => {
  for (let i = 0; i < types.length; i++) {
    let type = types[i];
    if (ValidFileTypes[type].indexOf(file.type) !== -1) {
      return true;
    }
  }
  return false;
};

export const chunkArray = (arr, chunkSize) => {
  let chunks = [];
  let current = [];

  for (let i = 0; i < arr.length; i++) {
    if (current.length === chunkSize) {
      chunks.push(current);
      current = [];
    }
    current.push(arr[i]);
  }
  if (current.length > 0) {
    chunks.push(current);
  }
  return chunks;
};

export const parseAwsErr = (err) => {
  console.error(`Error uploading to S3: ${err.message}`);
  return [
    `${err.name} (${err.statusCode})`,
    `Error uploading to S3: ${err.message}`,
  ];
};

export const objectToQueryParams = (params, keys = [], isArray = false) => {
  if (!params) return "";
  const p = Object.keys(params)
    .map((key) => {
      let val = params[key];
      if (val === undefined || val === null) return undefined;

      if (
        "[object Object]" === Object.prototype.toString.call(val) ||
        Array.isArray(val)
      ) {
        if (Array.isArray(params)) {
          keys.push("");
        } else {
          keys.push(key);
        }
        return objectToQueryParams(val, keys, Array.isArray(val));
      } else {
        let tKey = key;

        if (keys.length > 0) {
          const tKeys = isArray ? keys : [...keys, key];
          tKey = tKeys.reduce((str, k) => {
            return "" === str ? k : `${str}[${k}]`;
          }, "");
        }
        return `${tKey}=${val}`;
      }
    })
    .filter(k => k !== undefined)
    .join("&");

  keys.pop(); // for list url params. Ex: order_ids=123&order_ids=321
  return p;
};

export const millisecondsUntilJWTExpires = (jwt, buffer = 90000) => {
  const decoded = jwt_decode(jwt);
  let val = new Date(decoded.exp * 1000) - new Date() - buffer;
  return val;
};

export const openInNewTab = (url) => {
  const a = document.createElement("a");
  a.setAttribute("target", "_blank");
  a.href = url;
  a.click();
};

export const parseUser = (user) => {
  let customerId = parseInt(localStorage.getItem("customerId")),
    divisionId = parseInt(sessionStorage.getItem("divisionId")),
    customer = {},
    customers = [],
    customerIds = [],
    divisions = [],
    divisionIds = [];
  try {
    if (!customerId) {
      customerId =
        user && user.preferences ? user.preferences.current_customer_id : null;
    }
    if (!divisionId) {
      divisionId =
        user && user.preferences ? user.preferences.current_division_id : null;
    }
  } catch (err) {
    // pass
  }
  user.portal_customers.forEach((customer) => {
    if (!customerIds.includes(customer.id)) {
      customers.push(customer);
      customerIds.push(customer.id);
    }

    customer.divisions.forEach((division) => {
      if (!divisionIds.includes(division.id)) {
        divisions.push(division);
        divisionIds.push(division.id);
      }

      if (!divisionId) {
        divisionId = division.id;
      }
    });

    if (!customerId) {
      customerId = customer.id;
    }
  });
  customer = customers.find((c) => c.id === customerId);
  if (!customer && customers.length) {
    customer = customers[0];
    customerId = customer.id;
  }
  if (customer && !(customer.divisions || []).find((d) => d?.id === divisionId)) {
    divisionId = customer.divisions[0]?.id || null;
  }

  return {
    customerId,
    customerIds,
    customers,
    customer,
    divisionId,
    divisionIds,
    divisions,
  };
};

const naturalSortCollator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
});
export const naturalSort = naturalSortCollator.compare;

export const getClosestParent = function (elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

//the first letter is with a capital, the rest with a small
export function capitalizeFirstLetter(value) {
	try {
		const str = value.toString();
		return str[0].toUpperCase() + str.slice(1).toLowerCase();
	} catch (err) {
		return value;
	}
}

function getParentStatusByOrderStatus(status) {
  const { name: _, ...orderStatuses } = getFieldConfig(
    EntityType.STATUSES,
    EntityType.ORDER
  );
  const orderStatusMap = Object.entries(orderStatuses).reduce(
    (acc, [parent, { children }]) => ({
      ...acc,
      [parent]: [parent, ...children.map((c) => c.name)],
    }),
    {}
  );
  return Object.entries(orderStatusMap)
    .find(([parent, statuses]) => statuses.includes(status))
    ?.at(0);
}

/**
 * define status and icon for order depends on order fields(order_request_status, state)
 * @param order_request_status [string]: order status
 * @param state [string]: order general status
 * @returns array[string, img]
 */
export const defineOrderStatus = order => {
	if (!order) {
		return [null, null];
	}
	const status = order.order_request_status;
	const state = getParentStatusByOrderStatus(order.state);

  if (state) {
		if (state === 'COMPLETED') {
			return ['Complete', complete, color.palette.status.complete,
      'Order has been completed and results are ready to view.'];
		} else if (state === 'CANCELLED') {
			return ['Cancelled', cancelled, color.palette.status.cancelled,
      'This order has been cancelled.'];
		}
  }
	if (status || state) {
		switch (status?.toUpperCase()) {
			case ORDER_REQUEST_STATUS.submitted:
				return [
          'Awaiting Approval',
          submited,
          color.palette.status.awaitingApproval,
          'Order has been submitted and awaiting for lab approval.'
        ];
			case ORDER_REQUEST_STATUS.pending_changes:
				return [
          'Revision Required', 
          pending,
          color.palette.status.revision,
          'Changes are needed for this order.'
        ];
			case ORDER_REQUEST_STATUS.draft:
				return [
          'Draft', 
          draft,
          color.palette.status.draft,
          'An unfinished order that has not been submitted yet to the lab yet.'
        ];
			case ORDER_REQUEST_STATUS.approved:
				return [
          'Approved / Awaiting Samples',
          approved,
          color.palette.status.approved,
          'The lab has approved this order and is waiting to receive samples.'
        ];
			case ORDER_REQUEST_STATUS.received: {
				if (state === ORDER_STATUS.completed) {
					return [
            'Complete', 
            complete,
            color.palette.status.complete,
            'Order has been completed and results are ready to view.'
          ];
				} else if (state === ORDER_STATUS.cancelled) {
					return [
            'Cancelled',
            cancelled,
            color.palette.status.cancelled,
            'This order has been cancelled.'
          ];
				} else {
					return [
            'Received / In Progress', 
            received,
            color.palette.status.received,
            'The lab has received samples and is currently working on this order.'
          ];
				}
      }      
			default: {
				if (state === ORDER_STATUS.completed) {
					return [
            'Complete',
            complete,
            color.palette.status.complete,
            'Order has been completed and results are ready to view.'
          ];
				} else if (state === ORDER_STATUS.cancelled) {
					return [
            'Cancelled',
            cancelled,
            color.palette.status.cancelled,
            'This order has been cancelled.'
          ];
				} else {
					return [
            'Received / In Progress', 
            received,
            color.palette.status.received,
            'The lab has received samples and is currently working on this order.'
          ];
				}
      }
		}
	}
	return [null, null];
};

export const ApplyHighlighting = React.memo(({ value, search }) => {
	let style = {};
	if (value !== '' && search === value) {
		style = {
			backgroundColor: color.palette.highlight.yellow,
			padding: '1px 3px',
			borderRadius: '3px',
			marginLeft: '-3px',
			marginRight: '-3px',
		};
	}
	return <span style={style}>{value}</span>;
});

/**
 * //define styles for test status
 * @param {string} entityName - the name of the entity for which we are determining the status
 * @param {string} status - the name of the status we are looking for
 */
export const defineStatusesStyles = (entityName = '', status = '') => {
	const statuses = JSON.parse(
		sessionStorage.getItem('fieldConfigs')
	)?.STATUSES?.find(s => s.name?.toUpperCase() === entityName.toUpperCase());
	if (
		typeof statuses === 'object' &&
		statuses !== null &&
		typeof status === 'string'
	) {
		const styles = Object.entries(statuses).reduce((acc, item) => {
			if (item[0] === status.toUpperCase()) {
				return {
					...item[1].styles,
				};
			} else if(Array.isArray(item[1]?.children)) {
				const child = item[1].children.find(
					s => s.name?.toUpperCase() === status.toUpperCase()
				);
				if (child?.styles) {
					return { ...child.styles };
				}
			}
			return acc;
		}, {});
		return styles;
	}
	return null;
};

/**
 * @param {function} fetchData - callback to load data by entity id
 * @returns {array}
 */
export const useRedirectToUrl = (fetchData) => {
  const [entityId, setEntityId] = React.useState(null);

  const {data} = fetchData(entityId);

  const redirectToUrl = React.useCallback(() => {
      if(data && data.url) {
          const win = window.open(data.url, "_blank");
          win?.focus?.();
      }
  }, [data]);

  React.useEffect(() => {
      redirectToUrl();
  }, [data, redirectToUrl])   

  const onClick = React.useCallback(
		id => {
			if (entityId === id) {
				redirectToUrl();
			}
			setEntityId(id);
		},
		[entityId, redirectToUrl]
  );

  return [onClick]
}

export const transformDataToAttachTable = data => {
	if (data === undefined || data === null) return [];

  return data.map(attach => ({
		id: attach.id,
		asset_id: attach.asset_id || attach.asset?.id,
		file_name: attach.asset?.file_name,
		submitted_by: `${getAttachDisplayData(attach.asset)}`,
		date: attach.asset?.date_created,
		category: '',
		type: '',
	}));
};
const getAttachDisplayData = (asset) => {
  if (asset?.created_by) {
    return asset.created_by._display_fields_dict ? asset.created_by._display_fields_dict?.plain_text_value ?? '' : `${asset?.created_by?.first_name ?? ''} ${asset?.created_by?.last_name ?? ''}`
  } else if (asset.created_by_contact) {
    return asset.created_by_contact._display_fields_dict?.plain_text_value ?? ''
  } else if (asset.created_by_api_client) {
    return asset.created_by_api_client._display_fields_dict?.plain_text_value ?? ''
  } else {
    return '-'
  }
}

export const openPdfFile = pdfData => {
	const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
	const blobUrl = window.URL.createObjectURL(pdfBlob);
	window.open(blobUrl, '_blank');
};

export const downloadFile = (
	data,
	responceType,
	filename = `template_${new Date().getTime()}`,
	fileExtension = 'txt'
) => {
	var blob = new Blob([data], { type: responceType });
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = `${filename}.${fileExtension}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const noop = (...args) => {};

export const isPanelType = (assayOrPanel = {}) => assayOrPanel?._qbDataType === EntityType.PANEL;

export function stripHtml(html) {
  let tmp = document.createElement('div');
  tmp.innerHTML = html;
  return (tmp.textContent || tmp.innerText || '').replace('\u200B', '').trim();
}

export function mapKeysDeep(obj, fn) {
  if (!isPlainObject(obj)) return obj;
  return mapValues(mapKeys(obj, fn), (value) =>
    Array.isArray(value)
      ? value.map((v) => mapKeysDeep(v, fn))
      : isPlainObject(value)
      ? mapKeysDeep(value, fn)
      : value
  );
}

export function safeAtob(b64Encoded = '') {
  try {
    return atob(b64Encoded);
  } catch (e) {
    return null;
  }
}

export function createColorMap(colorObj) {
  if (!colorObj) return COLOR_MAP;
  return Object.entries(COLOR_MAP).reduce(
    (acc, [key, defaultColor]) => ({
      ...acc,
      [key]: colorObj[key] || defaultColor,
    }),
    {}
  );
}
