export const AddlFieldType = {
	NUM: 'NUM',
	STR: 'STR',
	TEXT: 'TEXT',
	DATE: 'DATE',
	TAG: 'TAG',
	DATETIME: 'DATETIME',
	BOOL: 'BOOL',
	SELECT: 'SELECT',
	SELECT_MULTI: 'SELECT_MULTI',
	HYPERLINK: 'HYPERLINK',
	OBJECT_RELATION: 'OBJECT_RELATION',
	OBJECT_RELATION_MULTI: 'OBJECT_RELATION_MULTI',
	DUMMY: 'DUMMY',
};
export const AddlSelectEntityReference = {
  CONTACT: 'CONTACT',
	USER: 'USER',
};

export const ColumnSort = {
	DESC: 'desc',
	ASC: 'asc',
};

export const NUMBER_OF_COLUMNS_FOR_SAMPLE = 6;
export const NUMBER_OF_COLUMNS_FOR_SAMPLE_MOBILE = 2;
export const NUMBER_OF_COLUMNS_FOR_TEST = 5;

export const NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO = 4;
export const NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO_MOBILE = 2;
export const NUMBER_OF_ORDERS_FIELDS = 8;

export const NUMBER_OF_CUSTOM_COLUMNS_SAMPLE_ADDITIONAL_INFO = 4;
export const NUMBER_OF_SAMPLES_FIELDS = 8;

export const ENABLED_MODULES = {
	BILLING: 'BILLING_ENABLED',
	TURNAROUNDS: 'TURNAROUNDS_ENABLED',
	EDD: 'EDD_ENABLED',
	SOURCES: 'SOURCES_ENABLED',
};

export const PORTALVIEW_PERMISSION = {
  COMMENT_VIEW: 'COMMENT_VIEW',
  COMMENT_WRITE: 'COMMENT_WRITE',
	SOURCE_VIEW: 'PORTAL_SHOW_ALL_SOURCES',
	SOURCE_CREATE: 'ORDER_REQUEST_SOURCE_CREATION',
	PRICING_VIEW: 'DISABLE_PORTAL_PRICING',
	PROJECT_VIEW: 'TRIAL',
	ACCOUNT_CREATE: 'ALLOW_CUSTOMER_SIGNUP',
	ORDER_REQUEST_CREATE: 'ORDER_REQUEST',
};
