import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledPortalMainMenu = styled.nav`
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
`;

const StyledTab = styled(Link)`
	display: flex;
	color: ${props => props.theme.color.palette.text.headerText};
	margin-right: 15px;
	text-decoration: none;

	& > .mobileViewMenu{
		border-bottom: 2px solid transparent;		
		&:hover {
			border-bottom: 2px solid ${props => props.theme.color.palette.text.linkText};
			border-radius: 0px;
		}
	}
	& > button {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
		height: fit-content;
		letter-spacing: 0.1px;
		text-align: center;
		white-space: nowrap;
		margin: 8px 24px 0px 24px;
		padding: 0px 0px 8px;
		&:hover {
			border-bottom: 2px solid ${props => props.theme.color.palette.text.headerText};
			border-radius: 0px;
		}
	}
	&:hover {
		text-decoration: none;
	}

	& > .iconContainer {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.6rem;
	}
`;

const StyledTabDummy = styled.div`
	display: flex;
	color: ${props => props.theme.color.palette.text.headerText};
	margin-right: 15px;

	& > .mobileViewMenu{
		border-bottom: 2px solid transparent;		
		&:hover {
			border-bottom: 2px solid ${props => props.theme.color.palette.text.linkText};
			border-radius: 0px;
		}
	}
	& > button {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
		height: fit-content;
		letter-spacing: 0.1px;
		text-align: center;
		white-space: nowrap;
		margin: 8px 24px 0px 24px;
		padding: 0px 0px 8px;
		&:hover {
			border-bottom: 2px solid ${props => props.theme.color.palette.text.headerText};
			border-radius: 0px;
		}
	}
	&:hover {
		text-decoration: none;
	}

	& > .iconContainer {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.6rem;
	}
`;

const StyledTabList = styled.menu`
	display: flex;
	margin: 0;
	padding: 0;
`;

export { StyledPortalMainMenu, StyledTab, StyledTabDummy, StyledTabList };
