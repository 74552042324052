import { useAppStore } from "App/store";
import React from 'react';
import { useEffect, useState } from "react";
import {
	AddlFieldType,
	NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO,
	NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO_MOBILE,
} from 'shared/constants/fields';
import useResponsive from './useResponsive';
import { EntityType } from "shared/constants/entityTypes";
import { safeJsonParse } from "shared/utils/functions";

export const useFieldConfigsByEntity = dataType => {
	const [sourceSingular] = useEntityDisplayName(EntityType.SOURCE);
	const [projectSingular] = useEntityDisplayName(EntityType.PROJECT);
	const replaceWithFieldConfig = [
		{ name: 'source', new_name: sourceSingular },
		{ name: 'project', new_name: projectSingular },
	];
	const { appState } = useAppStore();
	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (appState.fieldConfigs[dataType]) {
			appState.fieldConfigs[dataType].map((val, index) => replaceWithFieldConfig.map((item, i) => item.name === val.name ? appState.fieldConfigs[dataType][index].display_name = replaceWithFieldConfig[i].new_name : null));
			setFields(appState.fieldConfigs[dataType]);
		}
	}, [setFields, appState.fieldConfigs, dataType]);

	return [fields];
};

export const useDivisionsFieldConfigsByEntity = (divisionId, dataType) => {
	const [sourceSingular] = useEntityDisplayName(EntityType.SOURCE);
	const [projectSingular] = useEntityDisplayName(EntityType.PROJECT);
	const replaceWithFieldConfig = [
		{ name: 'source', new_name: sourceSingular },
		{ name: 'project', new_name: projectSingular },
	];
	const { appState } = useAppStore();
	const [fields, setFields] = useState([]);

	useEffect(() => {
		let divisionFieldConfigData = appState.divisionData.find(item => item.id === divisionId);
		if (!divisionFieldConfigData)
			divisionFieldConfigData = appState.divisionData.find(item => item.id === appState.divisionId);
		if (!divisionFieldConfigData)
			divisionFieldConfigData = appState.divisionData[0].id;
		if ((divisionId || appState.divisionId) && divisionFieldConfigData.field_config[dataType]) {
			divisionFieldConfigData.field_config[dataType].map((val, index) => replaceWithFieldConfig.map((item, i) => item.name === val.name ? divisionFieldConfigData.field_config[dataType][index].display_name = replaceWithFieldConfig[i].new_name : null));
			setFields(divisionFieldConfigData.field_config[dataType]);
		}
	}, [setFields, appState.divisionData, appState.fieldConfigs, divisionId, dataType]);

	return [fields];
};

export const useFieldsConfigs = configs => {
	return configs.map(conf => {
		const hidden =
			conf.portal_hidden ||
			conf.hidden ||
			conf.global_hidden ||
			conf.portal_global_hidden;

		let defaultValueLoaded = false;
		let optionsLoaded = false;

		function getDefaultValue(conf) {
			let def = conf.portal_default_value;

			if (def && Array.isArray(def)) {
				// TODO~!
			} else if (def && typeof def === 'object') {
				let id = Object.keys(def)[0];
				let value = def[Object.keys(def)[0]];
				if (typeof def === 'object') {
					if (value.startsWith(`${id} — `)) {
						value = value.substring(
							`${id} — `.length,
							value.length
						);
					} else if (value.startsWith(`${id} `)) {
						value = value.substring(`${id} `.length, value.length);
					}
					def = { id, value };
				} else {
					def = { label: def, value: def };
				}
			}
			defaultValueLoaded = true;
			return def;
		}

		function getOptions(conf) {
			let optionsConfig = conf.config ? conf.config.options : [];

			let options = [];
			if (optionsConfig && optionsConfig.length) {
				for (let i = 0; i < optionsConfig.length; i++) {
					let opt = optionsConfig[i];
					options.push({ label: opt, value: opt });
				}
			}
			optionsLoaded = true;
			return options;
		}

		const name = conf?.name;
		const displayName = conf?.display_name;
		const fieldType = conf?.field_type;
		const additional_attribute = conf?.additional_attribute;
		const required = conf?.portal_required && !hidden;
		const defaultValue = getDefaultValue(conf);
		const options = getOptions(conf);

		const isLoading = defaultValueLoaded && optionsLoaded;

		return {
			name,
			displayName,
			fieldType,
			hidden,
			additional_attribute,
			// defaultValue,
			// options,
			// required,
			// isLoading,
		};
	});
};

export const useFieldConfig = (fieldConfig, orderRequest=false) => {
	const [hidden] = useState(
    fieldConfig.portal_hidden ||
		fieldConfig.hidden ||
		fieldConfig.global_hidden ||
		fieldConfig.portal_global_hidden ||
		(orderRequest && !fieldConfig.order_request_field)
  );
	const [displayName] = useState(
		fieldConfig ? fieldConfig.display_name : null
	);
	const [required] = useState(fieldConfig.portal_required && !hidden);
	const [fieldType] = useState(fieldConfig ? fieldConfig.field_type : null);
	const [fieldTypeEntityReference] = useState(fieldConfig ? fieldConfig.field_type_entity_reference : null);
  	const [additionalAttribute] = useState(fieldConfig?.additional_attribute);
	const [defaultValue, setDefaultValue] = useState('');
	const [options, setOptions] = useState([]);

	const [defaultValueLoaded, setDefaultValueLoaded] = useState(false);
	const [optionsLoaded, setOptionsLoaded] = useState(false);

	const isLoading = defaultValueLoaded && optionsLoaded;

	useEffect(() => {
		let defaultValue = fieldConfig.portal_default_value;

		if (defaultValue && Array.isArray(defaultValue)) {
			// TODO~!
		} else if (defaultValue && typeof defaultValue === 'object') {
			let id = Object.keys(defaultValue)[0];
			let value = defaultValue[Object.keys(defaultValue)[0]];
			if (value && typeof defaultValue === 'object') {
				if (value.startsWith(`${id} — `)) {
					value = value.substring(`${id} — `.length, value.length);
				} else if (value.startsWith(`${id} `)) {
					value = value.substring(`${id} `.length, value.length);
				}
				defaultValue = { id, value };
			} else {
				defaultValue = { label: defaultValue, value: defaultValue };
			}
		}

		setDefaultValue(defaultValue);
		setDefaultValueLoaded(true);
	}, [fieldConfig.portal_default_value]);

	useEffect(() => {
		const optionsConfig = fieldConfig.config
			? fieldConfig.config.options
			: [];

		let options = [];
		if (optionsConfig && optionsConfig.length) {
			for (let i = 0; i < optionsConfig.length; i++) {
				let opt = optionsConfig[i];
				options.push({ label: opt, value: opt });
			}
		}

		setOptions(options);
		setOptionsLoaded(true);
	}, [fieldConfig.config]);

	return {
		hidden,
		displayName,
		required,
		fieldType,
		defaultValue,
		options,
		isLoading,
		fieldTypeEntityReference,
		additionalAttribute
	};
};

export const useEntityDisplayName = (entityType) => {
  let conf = sessionStorage.getItem("entityDisplayNames");
  if (conf && entityType) {
		conf = JSON.parse(conf)[entityType];
		return [conf.singular, conf.plural];
  }
	return ['', ''];
};

export const usePortalView = (config = '') => {
  const conf = localStorage.getItem('portalView');
	const res = safeJsonParse(conf);
	if (res) return config ? res[config] : res;
	return res;
};

export const useFilteredFieldsConfigs = (configs = []) => {
	const data = Object.assign(filterEntity, { configs })
		.filterByAdditionalAttribute()
		.filterByType()
		.sortByOrder()
		.filterByVisibility()
		.replaceSelectToMultiSelect();

	return data.configs;
};

const filterEntity = {
	filterByAdditionalAttribute: function () {
		this.configs = this.configs.filter(c => c.additional_attribute);
		return this;
	},
	filterByType: function () {
		this.configs = this.configs.filter(
			c =>
				c.field_type === AddlFieldType.NUM ||
				AddlFieldType.STR ||
				AddlFieldType.SELECT ||
				AddlFieldType.SELECT_MULTI
		);
		return this;
	},
	sortByOrder: function () {
		this.configs = this.configs.sort(
			(a, b) =>
				Number.parseInt(a.portal_order) -
				Number.parseInt(b.portal_order)
		);
		return this;
	},
	filterByVisibility: function () {
		this.configs = this.configs.filter(
			c => !(c.portal_hidden || c.portal_global_hidden || c.hidden)
		);
		return this;
	},
	replaceSelectToMultiSelect: function () {
		this.configs = this.configs.map(c => {
			if (c.field_type === AddlFieldType.SELECT) {
				return {
					...c,
					field_type: AddlFieldType.SELECT_MULTI,
				};
			}
			return c;
		});
		return this;
	},
};

export const useColsCount = () => {
	const { isMobile, isDesktop } = useResponsive();
	const [count, setCount] = React.useState(
		NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO
	);
	React.useEffect(() => {
		if (isMobile) {
			setCount(NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO_MOBILE);
			return;
		}
		if (isDesktop) {
			setCount(NUMBER_OF_CUSTOM_COLUMNS_ORDER_ADDITIONAL_INFO);
			return;
		}
	}, [isMobile, isDesktop]);

	return [count];
};


