import {
  get,
  omit,
  camelCase,
  merge,
  mapValues,
  groupBy,
  isEmpty,
} from 'lodash';
import { normalize as n } from 'normalizr';
import React, { useEffect, useState } from 'react';
import { EntityType } from 'shared/constants/entityTypes';
import schemas from 'shared/configs/normalizer/schemas';
import { useOrderRequestStore } from 'Order/store';
import { isPanelType } from 'shared/utils/misc';

const OrderRequestV2Context = React.createContext(undefined);
OrderRequestV2Context.displayName = 'OrderRequestV2Context';

export function addNormalizedEntities(payload, schema) {
  const normd = n(payload, schema);
  return [normd.entities, normd.result];
}

function pullEntityIdsByNames(payload = {}, names = []) {
  return names.reduce(
    (acc, name) => ({
      ...acc,
      [name]: Object.keys(payload[name] || {}).map(Number),
    }),
    {}
  );
}

function OrderRequestV2Provider({ children }) {
  const [wip, setWip] = useState({});
  const [entities, setEntities] = useState({});
  const v1 = useOrderRequestStore();

  useEffect(() => {
    if (!isEmpty(v1.order)) {
      const [newEntities, orderRequest] = addNormalizedEntities(
        v1.order,
        schemas.order
      );
      setEntities(merge(entities, newEntities));
      setWip({ ...wip, ...{ orderRequest } });
    }
  }, [v1.order]);

  useEffect(() => {
    if (!isEmpty(v1.samples)) {
      const [newEntities, samples] = addNormalizedEntities(
        v1.samples,
        schemas.samples
      );
      const idMap = pullEntityIdsByNames(newEntities, [
        'tests',
        'panels',
        'assays',
        'tags',
      ]);
      setEntities(merge(entities, newEntities));
      setWip({
        ...wip,
        ...idMap,
        ...{ samples },
      });
    }
  }, [v1.samples]);

  useEffect(() => {
    if (!isEmpty(v1.assayToAdd)) {
      const [newEntities, entityId] = addNormalizedEntities(
        v1.assayToAdd,
        schemas.assay
      );
      const assayOrPanel = getEntity(EntityType.ASSAY, entityId);
      const isPanel = isPanelType(assayOrPanel);
      const assayToAdd = isPanel
        ? {
            panelToAdd: entityId,
            panelAssays: (assayOrPanel?.panel_assays || []).map(
              (pa) => pa.assay_id
            ),
          }
        : {
            assayToAdd: entityId,
          };
      setEntities(merge(entities, newEntities));
      setWip({ ...wip, ...assayToAdd });
    } else {
      setWip(omit(wip, ['assayToAdd', 'panelToAdd', 'panelAssays']));
    }
  }, [v1.assayToAdd]);

  useEffect(() => {
    if (!isEmpty(v1.tests)) {
      const [newEntities, tests] = addNormalizedEntities(
        v1.tests,
        schemas.tests
      );
      const uniqueTests = [...new Set(tests)];
      setEntities(merge({}, entities, newEntities));
      setWip({ ...wip, ...{ tests: uniqueTests } });
    }
  }, [v1.tests]);

  /**
   * Getters
   */
  function getIds(entity) {
    const entityType = `${camelCase(entity)}s`;
    return wip[entityType] || [];
  }

  function getEntity(entity, id, defaultValue = {}, path = '') {
    const entityType = `${camelCase(entity)}s`;
    return get(entities, [entityType, id, path].filter(Boolean), defaultValue);
  }

  function getAssayOrPanelEntity(assayOrPanelId) {
    if (!assayOrPanelId || isEmpty(entities.assays)) return [];
    const assayOrPanel = getEntity(EntityType.ASSAY, assayOrPanelId);
    const isPanel = isPanelType(assayOrPanel);
    return [isPanel, assayOrPanel];
  }

  function getTestsByPanelId(panelId) {
    if (!panelId || isEmpty(wip.tests) || isEmpty(entities.tests)) return [];
    return Object.values(entities.tests).filter(
      (test) => test.panel === panelId && wip.tests.includes(test.id)
    );
  }

  function getTestsByAssayId(assayId) {
    if (!assayId || isEmpty(wip.tests) || isEmpty(entities.tests)) return [];
    return Object.values(entities.tests).filter(
      (test) => test.assay === assayId && !test.panel && wip.tests.includes(test.id)
    );
  }

  function getTestsByAssayOrPanelId(assayOrPanelId) {
    if (!assayOrPanelId || isEmpty(wip.tests) || isEmpty(entities.tests))
      return [];
    const [isPanel, { id }] = getAssayOrPanelEntity(assayOrPanelId);
    return isPanel ? getTestsByPanelId(id) : getTestsByAssayId(id);
  }

  function groupTestsBySampleId(testArr = [], idOnly = true) {
    return idOnly
      ? mapValues(groupBy(testArr, 'sample_id'), (v) => v.map((v) => v.id))
      : groupBy(testArr, 'sample');
  }

  function groupTestsBySampleIdAssayId(testArr = []) {
    return mapValues(groupBy(testArr, 'sample_id'), (x) =>
      mapValues(groupBy(x, 'assay_id'), (y) => y.map((z) => z.id))
    );
  }

  function getTurnaroundOptions(id) {
    if (isEmpty(entities.turnarounds)) return [];
    const initialOption = { value: 0, label: 'Standard' };
    const options = [
      initialOption, 
      ...Object.values(entities.turnarounds || {}).map((t) => ({
        value: t.id,
        label: t.name,
      })),
    ];
    return !!id ? options.find((o) => o.value === parseInt(id)) : options;
  }

  /**
   * Setters
   */
  function normalizeAssays(payload) {
    const [newEntities, assays] = addNormalizedEntities(
      payload,
      schemas.assays
    );
    setEntities(merge(entities, newEntities));
    setWip({ ...wip, ...{ assays } });
  }

  function normalizeTurnarounds(payload) {
    const [newEntities] = addNormalizedEntities(payload, schemas.turnarounds);
    setEntities(merge(entities, newEntities));
  }

  return (
    <OrderRequestV2Context.Provider
      value={{
        wip,
        entities,
        selectors: {
          getIds,
          getEntity,
          getAssayOrPanelEntity,
          getTestsByAssayId,
          getTestsByPanelId,
          getTestsByAssayOrPanelId,
          groupTestsBySampleId,
          groupTestsBySampleIdAssayId,
          getTurnaroundOptions,
        },
        normalizers: {
          assays: normalizeAssays,
          turnarounds: normalizeTurnarounds,
        },
      }}
    >
      {children}
    </OrderRequestV2Context.Provider>
  );
}

function useOrderRequest() {
  const context = React.useContext(OrderRequestV2Context);
  if (context === undefined) {
    throw new Error(
      'useOrderRequest must be used within a OrderRequestV2Provider'
    );
  }
  return context;
}

export { OrderRequestV2Provider, useOrderRequest };
