import { EntityType } from './entityTypes';

const l = (str = '') => str.toLowerCase();
const ResourceActions = {
	CREATE: 'CREATE',
	READ: 'READ',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
};

const actions = [ResourceActions.READ];  // Not all actions are available for now.
const resources = [EntityType.INVOICE, EntityType.QUOTATION, EntityType.REPORT];

function createPermissions() {
  return Object.fromEntries(
    actions.map((action) => [
      l(action),
      Object.fromEntries(
        resources.map((resource) => [l(resource), `${l(resource)}:${l(action)}`])
      ),
    ])
  );
}

export default createPermissions();
