import isEmpty from 'lodash/isEmpty';
import { useFieldConfigsByEntity } from './fields';
import usePermissionCheck from './usePermissionCheck';
import { EntityType } from 'shared/constants/entityTypes';
import { ENABLED_MODULES, PORTALVIEW_PERMISSION } from 'shared/constants/fields';

export default function useSourceParameters() {
  const moduleEnabled = usePermissionCheck(ENABLED_MODULES.SOURCES);
  const [sampleFieldConfigs] = useFieldConfigsByEntity(EntityType.SAMPLE);
  const hasCreatePermission = usePermissionCheck(PORTALVIEW_PERMISSION.SOURCE_CREATE);

  const sourceFieldConfig = (sampleFieldConfigs || []).filter(
    (f) => f.name.toUpperCase() === EntityType.SOURCE
  );
  const fieldShown = {
    globally: !isEmpty(sourceFieldConfig),
    onOrderRequest:
      !isEmpty(sourceFieldConfig) &&
      sourceFieldConfig.at(0) &&
      sourceFieldConfig.at(0)?.order_request_field,
  };
  const canCreate = hasCreatePermission && fieldShown.onOrderRequest;

  return {
    canCreate,
    isEnabled: moduleEnabled && fieldShown.globally,
  };
}
