export const FileType = {
	IMAGE: 'IMAGE',
	XLSX: 'XLSX',
	PDF: 'PDF',
	ZIP: 'ZIP',
};

export const ValidFileTypes = {
	[FileType.IMAGE]: [
		'image/jpeg',
		'image/jpg',
		'image/png',
		'image/gif',
		'image/x-icon',
	],
	[FileType.XLSX]: [
		'.csv',
		'.xls',
		'.xlsx',
		'text/csv',
		'application/csv',
		'text/comma-separated-values',
		'application/csv',
		'application/excel',
		'application/vnd.msexcel',
		'text/anytext',
		'application/vnd. ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	],
	[FileType.PDF]: [
		'application/pdf'
	],
};
