import React from 'react'
import ReactDom from 'react-dom';
import styled from 'styled-components'
import close from 'assets/images/svg/close.svg';
import {device} from 'shared/theme/theme';

const StyledModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1099;
    opacity: ${props => props.open ? '1' : '0'};
    transition: 0.2s;
    
    @media ${device.mobile} {
        z-index: 1040;
    }

    & .content {
        position: relative;
        border-radius: 16px;
        background-color: ${props => props.theme.color.palette.background.paper.defaultLight};
        z-index: 1099;

        @media ${device.mobile} {
            width: 100%;
            height: ${props => props.viewHeight ? 'fit-content' : '100%'};
            border-radius: 0px;
        }

    }

    & .closeIcon {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
`

export const Modal = ({open, onToggle, children, modalProps, contentProps, closeIcon, ...props}) => {
    if(!open) return null;

    return ReactDom.createPortal(
        <StyledModal
            onClick={onToggle}
            open={open}
            {...props}
        >
            <div
                className='content' 
                onClick={e => e.stopPropagation()}
                {...contentProps}
            >
                {children}
                {closeIcon ? 
                    <span 
                        className='closeIcon'
                        onClick={onToggle}
                    >
                        <img src={close} alt="close" />
                    </span> : 
                    null}
            </div>
        </StyledModal>,
        document.getElementById('portal')
    )
}
