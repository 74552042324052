export const ORDER_REQUEST_STATUS = {
	draft: 'DRAFT',
	submitted: 'SUBMITTED',
	pending_changes: 'PENDING CHANGES',
	approved: 'APPROVED',
	received: 'RECEIVED',
};

export const ORDER_STATUS = {
	completed: 'COMPLETED',
	cancelled: 'CANCELLED',
};

export const ORDERS_FILTER_BY_STATUS = [
	{
		name: 'COMPLETED',
		title: 'Completed',
	},
	{
		name: 'RECEIVED',
		title: 'Received (In Progress)',
	},
	{
		name: 'APPROVED',
		title: 'Approved (Awaiting Samples)',
	},
	{
		name: 'SUBMITTED',
		title: 'Awaiting Approval',
	},
	{
		name: 'PENDING CHANGES',
		title: 'Revision Required',
	},
	{
		name: 'CANCELLED',
		title: 'Cancelled',
	},
];

export const ORDERS_STATE = [
  {
    name: 'all',
    title: 'All Submitted',
    params: {
      statuses: Object.values(ORDER_STATUS),
      exclude_order_request_statuses: [
        ORDER_REQUEST_STATUS.draft,
        ORDER_REQUEST_STATUS.submitted,
      ],
    },
  },
  {
    name: 'unread',
    title: 'unread',
    params: {
      portal_read: false,
      exclude_statuses: [ORDER_STATUS.cancelled],
      exclude_order_request_statuses: [ORDER_REQUEST_STATUS.draft],
    },
  },
  {
    name: 'draft',
    title: 'draft',
    params: {
      order_request_statuses: [ORDER_REQUEST_STATUS.draft],
    },
  },
  {
    name: 'submitted',
    title: 'Awaiting Approval',
    params: {
      exclude_statuses: [ORDER_STATUS.completed, ORDER_STATUS.cancelled],
      exclude_order_request_statuses: Object.values(
        ORDER_REQUEST_STATUS
      ).filter((s) => s !== ORDER_REQUEST_STATUS.submitted),
    },
  },
  {
    name: 'pending changes',
    title: 'Revision Required',
    params: {
      order_request_statuses: [ORDER_REQUEST_STATUS.pending_changes],
    },
  },
  {
    name: 'cancelled',
    title: 'Cancelled',
    params: {
      statuses: [ORDER_STATUS.cancelled],
    },
  },
];
