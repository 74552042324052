import { useMutation, useQuery } from 'react-query';
import { safeJsonParse } from 'shared/utils/functions';
import { axiosinstance } from 'shared/utils/api';
import { queryClient } from 'index';

const ME_QUERY = 'me';
const baseUrl = '/v1/contact';

const refetchMe = () => queryClient.refetchQueries(ME_QUERY, { active: true });

export function useMeQuery() {
  const { data = {}, ...queryObject } = useQuery(ME_QUERY, () =>
    axiosinstance.get(baseUrl).then(({ data: res }) => res.data)
  );
  return [
    {
      ...data,
      preferences: {
        ...safeJsonParse(data?.preferences),
        ...data?.contact_customer_preferences,
      },
    },
    queryObject,
  ];
}

export function useUpdateContactPreferences() {
  const { mutate, ...mutationObject } = useMutation(
    (data) => axiosinstance.patch(baseUrl, data).then((res) => res.data),
    {
      onSuccess: refetchMe
    }
  );
  return [mutate, mutationObject];
}

export default refetchMe;
