import React from 'react';
import styled from 'styled-components';
import { Modal } from 'shared/components/common/Modal';
import { hamburger } from 'assets/images/svg/colorChangingSvg';
import { StyledTab, StyledTabDummy } from '../../PortalMainMenu/styles';
import { menu } from '../../PortalMainMenu/PortalMainMenu';
import UserMenu from '../UserMenu';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useEntityDisplayName } from 'shared/hooks/misc/fields';
import { EntityType } from 'shared/constants/entityTypes';
import { useAppStore } from 'App/store';
import { useNavigate, useLocation } from 'react-router-dom';
import OutstandingBalance from 'shared/layout/PortalMainMenu/OutstandingBalance';

const modal = {
	alignItems: 'flex-start',
  justifyContent: 'flex-end'
};

const content = {
	height: '100vh',
	maxWidth: '100vw',
  width: 'fit-content',
  padding: '60px 20px 20px',
  borderRadius: 0
};

const StyledBurgerMenu = styled.div`
  & .menuIcon {
    margin-left: 10px;
    & > img {
      width: 26px;
    }
  }
`

const StyledTabs = styled.div`
  margin-bottom: 20px;
  & > a {
    padding: 6px 10px;
    margin: 0;
    font-weight: 500;
  }
`

const BurgerMenu = ({children}) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const theme = useContext(ThemeContext);
	const selectedTab = useLocation().pathname?.split('/')[2];
  const { appState: { billingEnabled } } = useAppStore();

  const handleToggleModal = React.useCallback(e => {
    setOpenMenu(prev => !prev);
  }, [setOpenMenu])

  return (
    <StyledBurgerMenu>
      <div className='menuIcon'>
        <span style={{cursor: 'pointer'}} onClick={handleToggleModal}>{hamburger(theme.color.palette.text.link)}</span>
      </div>
      <Modal
          open={openMenu}
          onToggle={handleToggleModal}
          style={modal}
          closeIcon
          contentProps={{
              style: content
          }}
      >
        <OutstandingBalance toggleModal={handleToggleModal} />
        <div style={{marginBottom: '20px'}}><UserMenu /></div>
        <StyledTabs>
          {menu.filter(item => item.name === 'invoices' && !billingEnabled ? false : true).map(tab => (
            <Tab
              key={tab.name}
              tab={tab}
              onClick={handleToggleModal}
              selected={tab.name === selectedTab}
            />
          ))}
        </StyledTabs>
        {/* <MessagesMenu /> */}
      </Modal>
    </StyledBurgerMenu>
  )
};

const Tab = ({ tab: {to, entityType, icon, title, name}, selected, onClick }) => {
	const history = useNavigate();
	const {pathname} = useLocation();
	const { appStateDispatch } = useAppStore();
  const buttonStyle = {
    padding: '6px 10px',
    margin: '0',
    fontWeight: '500',
    justifyContent: 'center',
    cursor: 'pointer'
  }
	
	const [entitySingular, entityPlural] = useEntityDisplayName(EntityType[entityType?.toUpperCase()]);

	const entityTitle = title(entitySingular, entityPlural);

	const createOrderButtonClick = () => {
		if (pathname === "/portal/createorder") {
			appStateDispatch({ type: 'RESET_CREATE_ORDER', payload: true });
		}
    onClick();
    history({ pathname: "/portal/createorder" })
	};

  const clearOrderId = () => {
    localStorage.removeItem("orderId");
    onClick();
  }

  return (
    <>
      {name === 'createorder' ? <StyledTabDummy selected={selected} onClick={createOrderButtonClick} style={buttonStyle}>
          <CommonButtonCode
            selected={selected}
            icon={icon}
            entityTitle={entityTitle}
          />
        </StyledTabDummy> :
        <StyledTab to={to} selected={selected} onClick={clearOrderId} style={{ justifyContent: 'center' }}>
          <CommonButtonCode
            selected={selected}
            icon={icon}
            entityTitle={entityTitle}
          />
        </StyledTab>}
    </>
  );
};

const CommonButtonCode = ({ selected, icon, entityTitle }) => {
  const theme = useContext(ThemeContext);
  const activeButtonStyle = {
    color: `${theme.color.palette.text.linkText}`,
    borderBottom: `2px solid ${theme.color.palette.text.linkText}`,
  }

  const buttonMobileView = {
    color: `${theme.color.palette.text.linkText}`,
  }

  return (
    <span className='mobileViewMenu' style={selected ? activeButtonStyle : buttonMobileView}>
      {icon ? <span className='iconContainer'> <img src={icon} alt={entityTitle} /> </span> : null}
      {entityTitle}
    </span>
  );
}

export default BurgerMenu;