import { PORTALVIEW_PERMISSION } from 'shared/constants/fields';
import { safeJsonParse } from './functions';

export function getViewPermissions() {
  const portalView = safeJsonParse(localStorage.getItem('portalView')) ?? {};
  const userPermissions = Object.values(PORTALVIEW_PERMISSION).reduce(
    (acc, key) => ({
      ...acc,
      [key]: !Boolean(portalView[key]?.hidden),
    }),
    {}
  );

  return userPermissions;
}
