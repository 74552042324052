const RETURN_STUB = process.env.REACT_APP_STUBS_ENABLED === "true";

export const AttachmentStub = {
    save: (dataType, attachment) =>
      RETURN_STUB && {
          data: {}
      },
    get: (dataType, params) =>
      RETURN_STUB && {
          data: []
      }
}