import 'animate.css';
import { lazy, useEffect } from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-notifications/lib/notifications.css';
import Loading from 'shared/layout/Loading';
import MainPage from '../shared/layout/MainPage';
import PortalTopNav from '../shared/layout/PortalTopNav';
import Dashboard from '../Dashboard';
import { useAppStore } from './store';
import { StyledLoggedInApp } from './styles';
import { DashboardProvider } from 'Dashboard/state';
import { ThemeProvider } from 'styled-components';
import { useTheme } from 'shared/theme/useTheme';

const PortalSignInPage = lazy(() => import('../Login'));
const NetworkError = lazy(() => import('shared/layout/NetworkError'));

function LoggedInApp() {
	return (
		<StyledLoggedInApp>
			<DashboardProvider>
				<PortalTopNav />
				<Dashboard />
			</DashboardProvider>
		</StyledLoggedInApp>
	);
}

function App() {
	const { theme } = useTheme();
	const { appState, setPortalLogo } = useAppStore();

	useEffect(() => setPortalLogo(), []);

	if (appState.isLoading) return <Loading dim />;

	if (appState.isInitializing) {
		return <ThemeProvider theme={theme}><Loading dim /></ThemeProvider>;
	}

	return (
		<MainPage>
			{appState.isNetworkError && <NetworkError />}
			{!appState.isNetworkError && appState.isAuth && (
				<LoggedInApp />
			)}
			{!appState.isNetworkError && !appState.isAuth && (
				<PortalSignInPage />
			)}
		</MainPage>
	);
}

export default App;
