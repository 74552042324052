import React from 'react';
import styled from 'styled-components';

const StyledButtton = styled.button `
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.color.palette.text.defaultLight};
    background: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: ${props => props.width ? props.width : undefined};
	outline: 0;

    &:focus {
		outline: 0;
	}
    box-sizing: border-box;

    padding: ${props => {
        switch(props.type) {
            case 'text':
            case 'headerText':
            case 'outlined':
                return '8px 24px;';
            case 'contained':
            default:
                return '8px 24px;';
        }
    }}
    border: 1px solid ${({theme}) => theme.ButtonContainedEnabled};
    color: ${props => {
        if(props.disabled) {
            return props.theme.color.palette.grey[600]}
        if(props.color) {
            return props.color;
        }
        switch(props.type) {
            case 'text':
            case 'outlined':
                return props.linkButton ? props.theme.color.palette.text.link : props.theme.color.palette.text.linkText;
            case 'headerText':
                return props.theme.color.palette.text.headerText;
            case 'contained':
            default: 
                return  props.theme.ButtonContainedText
        }
    }};
    background: ${props => {
        if(props.disabled) {
            return props.theme.color.palette.grey[300]}
        if(props.bgColor) {
            return props.bgColor;
        }
        switch(props.type) {
            case 'text':
            case 'headerText':
            case 'outlined':
                return 'none';
            case 'contained':
            default:
                return  props.theme.ButtonContainedEnabled
        }
    }};
    border: ${props => {
        if(props.disabled) {
            return props.theme.color.palette.grey[300]}
        switch(props.type) {
            case 'text':
            case 'headerText':
                return  '1px solid transparent';
            case 'outlined':
            case 'contained':
            default:
                return `1px solid ${props.theme.ButtonContainedEnabled}`
        }
    }} 
`

//three kinds of types can be used: contained, outlined, text
const Button = ({
    children,
    ...props
}) => (
    <StyledButtton {...props}>
        {children}
    </StyledButtton>
)
export default Button;