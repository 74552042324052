import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { EntityType } from 'shared/constants/entityTypes';
import { useEntityDisplayName } from 'shared/hooks/misc/fields';
import usePermissionCheck from 'shared/hooks/misc/usePermissionCheck';
import useSourceParameters from 'shared/hooks/misc/useSourceParameters';
import { ENABLED_MODULES, PORTALVIEW_PERMISSION } from 'shared/constants/fields';
import OutstandingBalance from './PortalMainMenu/OutstandingBalance';
import useAccessControl from 'shared/hooks/misc/useAccessControl';
import policies from 'shared/configs/rbac/accessPolicies';
import Button from 'shared/components/common/Button';
import { pluralize } from 'shared/utils/string';
import UserMenu from './PortalTopNav/UserMenu';
import { useAppStore } from 'App/store';

const CREATE_ORDER_PATH = 'createorder';
const menu = [
  {
    entity: EntityType.ORDER_REQUEST,
    accessPolicy: {
      portalView: PORTALVIEW_PERMISSION.ORDER_REQUEST_CREATE,
    },
  },
  {
    entity: EntityType.ORDER,
  },
  {
    entity: EntityType.INVOICE,
    accessPolicy: {
      module: ENABLED_MODULES.BILLING,
    },
  },
  {
    entity: EntityType.QUOTATION,
    accessPolicy: {
      module: ENABLED_MODULES.BILLING,
    },
  },
  {
    entity: EntityType.SOURCE,
  },
];

const Menu = styled(Link)`
  color: ${(p) => p.theme.color.palette.text.headerText};

  & > button {
    height: fit-content;
		white-space: nowrap;

    &:hover {
      border-bottom: 2px solid ${(p) => p.theme.color.palette.text.headerText};
    }

    ${(p) => p.$isActive && `
      border-bottom: 2px solid ${p.theme.color.palette.text.headerText};
    `}
  }
`;

function MenuItem({ entity, accessPolicy = {} }) {
  const { pathname } = useLocation();
  const { appStateDispatch } = useAppStore();
  
  // Order Request Creation
  const isCreateOrder = entity === EntityType.ORDER_REQUEST;
  const [labelSingular, labelPlural] = useEntityDisplayName(
    isCreateOrder ? EntityType.ORDER : entity
  );
  const btnLabel = isCreateOrder ? `+ Create ${labelSingular}` : labelPlural;

  // Other menu items...
  const name = isCreateOrder ? CREATE_ORDER_PATH : pluralize(entity);
  const activePath = pathname.split('/').filter(Boolean).at(-1);
  const { module, portalView, ...policy } = accessPolicy;

  // Route access control
  const routePolicy = isEmpty(policy) ? policies.route[name] : policy;
  const hasSufficientRoutePrivilege = useAccessControl(routePolicy);
  const hasSufficientModuleOrPortalview = usePermissionCheck(
    module || portalView
  );

  // Source requires different treatment
  const isSourceEntity = entity === EntityType.SOURCE;
  const { isEnabled: isSourceEnabled } = useSourceParameters();

  function handlePreClick() {
    if (isCreateOrder)
      return appStateDispatch({ type: 'RESET_CREATE_ORDER', payload: true });
    return localStorage.removeItem('orderId');
  }

  const menuBtn = (
    <Menu
      key={name}
      to={`/portal/${name}`}
      className="me-3 text-decoration-none"
      $isActive={name === activePath}
      onClick={handlePreClick}
    >
      <Button
        type="headerText"
        className="m-2 mx-4 mb-0 p-0 pb-2 fs-7 rounded-0"
      >
        {btnLabel}
      </Button>
    </Menu>
  );

  if (isSourceEntity) {
    return !isSourceEnabled ? null : menuBtn;
  } else {
    return !hasSufficientModuleOrPortalview || !hasSufficientRoutePrivilege
      ? null
      : menuBtn;
  }
}

export default function MainMenu() {
  const menuItems = useMemo(() => menu, []);
  return (
    <nav className="d-flex ms-auto">
      {menuItems.map(MenuItem)}
      <OutstandingBalance />
      <UserMenu />
    </nav>
  );
}
