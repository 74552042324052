import { AddlFieldType, ColumnSort } from "shared/constants/fields";
import { DateTime } from "luxon";
import { isEmpty, isPlainObject } from 'lodash';
import { EntityType } from "shared/constants/entityTypes";
import styled from "styled-components";
import { useAppStore } from "App/store";
import { axiosinstance } from "./api";
import { safeJsonParse } from "./functions";

export const getSortedFields = (fieldConfigs) => {
  return Object.keys(fieldConfigs).sort((a, b) => {
    return fieldConfigs[a].order - fieldConfigs[b].order;
  });
};

export const preprocessPayload = (payload, fields) => {
  fields.forEach((fieldConfig) => {
    const { field_type: fieldType, name } = fieldConfig;
    if (payload.hasOwnProperty(`_${name}`)) {
      // Private attributes, not meant for server payload
      delete payload[`_${name}`];
    }

    if (!payload[name]) return;

    if (name === "date_created") {
      delete payload.date_created;
    } else if (fieldType === AddlFieldType.SELECT) {
      payload[name] = payload[name].value;
    } else if (fieldType === AddlFieldType.SELECT_MULTI) {
      payload[name] = payload[name].map((e) => e.value);
    } else if (fieldType === AddlFieldType.OBJECT_RELATION) {
      payload[name] = payload[name].id;
    } else if (fieldType === AddlFieldType.OBJECT_RELATION_MULTI) {
      payload[name] = payload[name].map((e) => e.id).join(",");
    } else if (fieldType === AddlFieldType.DATETIME) {
      payload[name] = convertDateToServer(payload[name]);
    } else if (
      Array.isArray(payload[name]) &&
      fieldType === AddlFieldType.BOOL
    ) {
      payload[name] = payload[name].some((e) => e === "on");
    }
  });
  return payload;
};

export const postProcessPayload = (payload, fields) => {
  fields.forEach((fieldConfig) => {
    const { field_type: fieldType, name } = fieldConfig;
    if (!payload[name]) return;

    if (
      typeof payload[name] !== "object" &&
      fieldType === AddlFieldType.SELECT
    ) {
      payload[name] = { label: payload[name], value: payload[name] };
    } else if (fieldType === AddlFieldType.SELECT_MULTI) {
      payload[name] = payload[name].map((e) => ({ label: e, value: e }));
    } else if (fieldType === AddlFieldType.DATETIME) {
      payload[name] = convertDateFromServer(payload[name]);
    } else if (name === "additional_customers") {
      payload[name] = payload[name].map((e) => e.customer_account);
    }
  });
  return payload;
};

export const relationshipToId = (name, payload) => {
  if (!payload) return payload;

  if (typeof payload[name] === "object") {
    if (Array.isArray(payload[name])) {
      payload[`${name.substring(0, name.length - 1)}_ids`] = payload[name].map(
        (e) => e.id
      );
    } else if (payload[name]) {
      payload[`${name}_id`] = payload[name].id;
    } else {
      payload[`${name}_id`] = null;
    }
  }
  delete payload[name];
  return payload;
};

export const getFieldConfig = (dataType, attribute = null) => {
  const res = safeJsonParse(sessionStorage.getItem('fieldConfigs'));
  if (res) {
    const fieldConfig = res[dataType];
    return attribute 
      ? fieldConfig.find((item) => item.name === attribute)
      : fieldConfig
  }
  return;
};

export const convertDateFromServer = (date) => {
  return DateTime.fromFormat(date, "MM/dd/yyyy hh:mm a", { zone: "UTC" })
    .toLocal()
    .toFormat("MM/dd/yyyy hh:mm a");
};

export const convertDateToServer = (date) => {
  return DateTime.fromFormat(date, "MM/dd/yyyy hh:mm a")
    .toUTC()
    .toFormat("MM/dd/yyyy hh:mm a");
};

/* ~~~~START Async Select ~~~~*/
const asyncLoadOptions = async (url, search, prevOptions, page) => {
  try {
    const { data, total_pages, page_number } = await axiosinstance
      .get(`${url}?search=${search}&page=${page}`)
      .then((res) => res.data);
    return {
      options: data,
      hasMore: page_number < total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (err) {
    console.error(err);
  }
};
export const AddlFieldSelect = (options) => {
  const loadOptions = async (search, prevOptions, { page }) => {
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const getOptionLabel = (obj) => obj.label;
  const getOptionValue = (obj) => obj.value;
  const getDefaultValue = (obj) => {
    if (obj) {
      if (typeof obj === "string") {
        return { label: obj, value: obj };
      } else if (Array.isArray(obj)) {
        return obj.map((e) => ({ label: e, value: e }));
      }
    }
    return null;
  };

  return { loadOptions, getOptionLabel, getOptionValue, getDefaultValue };
};
export const TurnaroundSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions("/v1/turnarounds", search, prevOptions, page),
  getOptionLabel: (obj) => obj.name,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) => (obj ? { id: obj.id, name: obj.name } : null),
};
export const ProjectSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions("/v1/projects", search, prevOptions, page),
  getOptionLabel: (obj) => obj.title,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) => (obj ? { id: obj.id, title: obj.value } : null),
};
export const AccessioningTypeSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions("/v1/accessioningtypes", search, prevOptions, page),
  getOptionLabel: (obj) => obj.value,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) => (obj ? { id: obj.id, value: obj.value } : null),
};
export const SourceSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions("/v1/sources", search, prevOptions, page),
  getOptionLabel: (obj) => obj.display_name,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) =>
    obj ? { id: obj.id, value: obj.display_name } : null,
};
export const CustomerSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions("/v1/customers", search, prevOptions, page),
  getOptionLabel: (obj) => obj.customer_name,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) => {
    if (Array.isArray(obj)) {
      let val = [];
      for (let i = 0; i < obj.length; i++) {
        val.push({ id: obj.id, value: obj.customer_name });
      }
      return val;
    } else if (obj) {
      return { id: obj.id, value: obj.customer_name };
    }
    return null;
  },
};

export const ContactSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions("/v1/contacts", search, prevOptions, page),
  getOptionLabel: (obj) => obj._display_fields_dict?.plain_text_value,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) => {
    if (Array.isArray(obj)) {
      let val = [];
      for (let i = 0; i < obj.length; i++) {
        val.push({
          id: obj.id,
          value: obj._display_fields_dict?.plain_text_value,
        });
      }
      return val;
    } else if (obj) {
      return { id: obj.id, value: obj._display_fields_dict?.plain_text_value };
    }
    return null;
  },
};

export const UserSelect = {
  loadOptions: async (search, prevOptions, { page }) =>
    asyncLoadOptions('/v1/users', search, prevOptions, page),
  getOptionLabel: (obj) => obj._display_fields_dict?.plain_text_value,
  getOptionValue: (obj) => obj.id,
  getDefaultValue: (obj) => (obj ? { id: obj.id, value: obj._display_fields_dict?.plain_text_value } : null),
};

export const TestStatuses = () => {
  const { appState } = useAppStore();
  const { name: _, ...testStatuses } = appState.fieldConfigs?.STATUSES.find(
    (s) => s.name === EntityType.TEST
  );
  const subStatusIndent = Array(4).join('\u00A0');
  const options = Object.entries(testStatuses)
    .map(([name, values]) => ({ name, ...values }))
    .sort((a, b) => a.sort_order - b.sort_order)
    .reduce((statuses, status) => {
      const subStatuses = status.children.map((c) => ({
        value: c.name,
        label: `${subStatusIndent}${c.name}`,
      }));
      return [
        ...statuses, 
        { value: status.name, label: status.name },
        ...subStatuses
      ];
    }, []);
  return {
    loadOptions: async () => ({ options }),
    getOptionLabel: (obj) => obj.label,
    getOptionValue: (obj) => obj.value,
    getDefaultValue: (obj) => (obj ? { value: obj.value, label: obj.label } : null),
  };
};

/* ~~~~END Async Select ~~~~*/

export const Meta = {
  [EntityType.ORDER]: {
    relationshipFields: {
      turnaround: TurnaroundSelect,
      project: ProjectSelect,
      additional_customers: CustomerSelect,
    },
    getFieldConfig: () => getFieldConfig(EntityType.ORDER),
  },
  [EntityType.SAMPLE]: {
    relationshipFields: {
      accessioning_type: AccessioningTypeSelect,
      project: ProjectSelect,
      source: SourceSelect,
    },
    getFieldConfig: () => getFieldConfig(EntityType.SAMPLE),
  },
  [EntityType.TEST]: {
    relationshipFields: {
      assay: {}, // TODO
      sample: {}, // TODO
      turnaround: TurnaroundSelect,
    },
    getFieldConfig: () => getFieldConfig(EntityType.TEST),
  },
  [EntityType.SOURCE]: {
    relationshipFields: {
      project: ProjectSelect,
    },
    getFieldConfig: () => getFieldConfig(EntityType.SOURCE),
  },
};

export const mutateEnt = ({ entityType, url, method, payload }) => {
  
  let fields = null;
  if (entityType){
    const entityMeta = Meta[entityType];
    const fields = entityMeta.getFieldConfig();
    const relationshipFields = Object.keys(entityMeta.relationshipFields);

    // Core Field Check
    for (let i = 0; i < relationshipFields.length; i++) {
      let field = relationshipFields[i];
      payload = relationshipToId(field, payload);
    }

    // Additional Field Check
    payload = preprocessPayload(payload, fields);

  }
  
  return axiosinstance[method](url, payload).then(({ data }) =>

    fields ? postProcessPayload(data, fields) : data
  );
};
export const mutateEnts = ({ entityType, url, method, payload }) => {
  const entityMeta = Meta[entityType];
  const fields = entityMeta.getFieldConfig();
  const relationshipFields = Object.keys(entityMeta.relationshipFields);

  for (let j = 0; j < payload.length; j++) {
    // Core Field Check
    for (let i = 0; i < relationshipFields.length; i++) {
      let field = relationshipFields[i];
      payload[j] = relationshipToId(field, payload[j]);
    }

    // Additional Field Check
    payload[j] = preprocessPayload(payload[j], fields);
  }

  return axiosinstance[method](url, payload).then(({ data }) => {
    for (let i = 0; i < data.length; i++) {
      data[i] = postProcessPayload(data[i], fields);
    }
    return data;
  });
};

export const parseFieldConfig = (data) => {
  let fields = {};
  for (let key in data) {
    if (
      ["ENABLED_MODULES", "ENTITY_DISPLAY_NAMES", "PORTAL_VIEW"].includes(key)
    ) {
      fields[key] = data[key];
      continue;
    }
    let config = data[key];
    if (config) {
      fields[key] = Object.keys(config)
        .sort((x, y) => {
          let first = parseInt(config[x].portal_order),
            second = parseInt(config[y].portal_order);
          if (first === second) {
            return 0;
          } else if (isNaN(first)) {
            return 1;
          } else if (isNaN(second)) {
            return -1;
          } else {
            return first > second ? 1 : -1;
          }
        })
        .map((key) => {
          return { name: key, ...config[key] };
        })
        .filter(
          (config) =>
            !(
              config.global_hidden ||
              config.hidden ||
              config.portal_global_hidden ||
              config.portal_hidden
            )
        );
    }
  }
  return fields;
};

export const sortEntityBy = (
  data,
  sortProp,
  direction,
  dataType = AddlFieldType.TEXT
) => {
  try {
    switch (dataType) {
      case AddlFieldType.TEXT:
        return sortByText(data, sortProp, direction);
      case AddlFieldType.DATETIME:
        return sortByDateTime(data, sortProp, direction);
      default:
        return data;
    }
  } catch (err) {
    return data;
  }
};

const sortByText = (data, sortProp, direction) => {
  if (direction === ColumnSort.ASC) {
    return data.sort(function (a, b) {
      return a[sortProp].localeCompare(b[sortProp]);
    });
  }
  if (direction === ColumnSort.DESC) {
    return data.sort(function (a, b) {
      return b[sortProp].localeCompare(a[sortProp]);
    });
  }
  return data;
};

const sortByDateTime = (data, sortProp, direction) => {
  if (direction === ColumnSort.ASC) {
    return data.sort((a, b) => {
      return new Date(a[sortProp]) - new Date(b[sortProp]);
    });
  }
  if (direction === ColumnSort.DESC) {
    return data.sort((a, b) => {
      return new Date(b[sortProp]) - new Date(a[sortProp]);
    });
  }
  return data;
};

export const cutIgnoredItems = (
  list,
  config = [],
  numberOfColumns = undefined
) => {
  return list
    .filter((item) => {
      return !config.some((c) => item[c.field] === c.rule);
    })
    .filter((_, idx) => (numberOfColumns ? idx < numberOfColumns : true));
};

const StyledTabAnchorTag = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const renderDisplayField = (entity, fieldConfig, options = {}) => {
  const { as: Wrapper } = options;

  let value = entity[fieldConfig.name];
  if (fieldConfig.name === "status") {
    value = entity.status ?? entity.state;
  }
  if (fieldConfig.field_type === AddlFieldType.BOOL) {
    value = value === true ? "YES" : "NO";
  } else if (!value || (Array.isArray(value) && !value.length)) return "-";

  if (fieldConfig.field_type === AddlFieldType.HYPERLINK) {
    value = (
      <StyledTabAnchorTag href={value} target="_blank">
        {value}
      </StyledTabAnchorTag>
    );
  } else if (
    fieldConfig.field_type === AddlFieldType.TAG &&
    Array.isArray(value)
  ) {
    value = value.map((tag) => tag.value).join(", ");
  } else if (
    typeof value === "object" &&
    value.hasOwnProperty("_display_fields_dict")
  ) {
    value = value._display_fields_dict.plain_text_value;
  } else if (value && value.constructor === Array) {
    value = value
      .map((item) => item?._display_fields_dict?.plain_text_value ?? item)
      .join(", ");
  } else if (
    fieldConfig.field_type === AddlFieldType.OBJECT_RELATION ||
    fieldConfig.field_type === AddlFieldType.OBJECT_RELATION_MULTI
  ) {
    value = entity[`${fieldConfig.name}_to_display_text`];
  } else if (fieldConfig.field_type === AddlFieldType.DATETIME) {
    value = convertDateFromServer(value);
  }

  return Wrapper ? <Wrapper>{value}</Wrapper> : <strong>{value}</strong>;
};

export const testResultsRender = (value, row) => {
  const returnString = (row, data, showString) => {
    let dataType = data
      ? data.constructor === Array
        ? "array"
        : data.constructor === Object
        ? "obj"
        : data.constructor === String
        ? "string"
        : ""
      : "";
    switch (dataType) {
      case "string":
        return data;
      case "array":
        return data.length ? data.map((item) => item.value).join(", ") : "";
      case "obj":
        return data.value
          ? data.value
          : data._display_fields_dict
          ? data._display_fields_dict.plain_text_value
          : "";
      default:
        return row["showString"];
    }
  };

  if (row.release_results) {
    if (row.assay.cp_result_field && row.assay.cp_result_field_type) {
      switch (row.assay.cp_result_field_type) {
        case "TEST_FIELD":
          const fieldConfig = getFieldConfig("TEST", row.assay.cp_result_field);
          if (fieldConfig) {
            return renderDisplayField(row, fieldConfig);
          }

          return returnString(row, row?.[row.assay.cp_result_field], "results");

        case "WORKSHEET_FIELD": {
          if (
            row.worksheet_data &&
            row.worksheet_data.hasOwnProperty(row.assay.cp_result_field)
          ) {
            return returnString(
              row,
              row.worksheet_data?.[row.assay.cp_result_field]?.value,
              "results"
            );
          } else {
            return row.state;
          }
        }
        default:
          return row.results;
      }
    } else {
      const portalView = JSON.parse(
        localStorage.getItem("portalView")
      )?.test_results_field;

      switch (portalView?.cp_result_field_type) {
        case "TEST_FIELD":
          const fieldConfig = getFieldConfig(
            "TEST",
            portalView?.cp_result_field
          );
          if (fieldConfig) {
            return renderDisplayField(row, fieldConfig);
          }

          return returnString(
            row,
            row?.[
              portalView?.cp_result_field === "status"
                ? "state"
                : portalView?.cp_result_field
            ],
            "results"
          );
        case "WORKSHEET_FIELD": {
          if (
            row.worksheet_data &&
            row.worksheet_data.hasOwnProperty(portalView?.cp_result_field)
          ) {
            return returnString(
              row,
              row.worksheet_data?.[portalView?.cp_result_field]?.value,
              "results"
            );
          } else {
            return row.state;
          }
        }
        default:
          return row.results;
      }
    }
  }
  return row.results;
};

export const CreateOrderCommonContentToString = (
  hidden,
  sampleFieldName,
  fieldName,
  sampleMeta,
  fieldConfig
) => {
  if (!hidden && sampleFieldName) {
    let val;
    if (Object.keys(sampleMeta.relationshipFields).includes(fieldName)) {
      val =
        sampleMeta.relationshipFields[fieldName].getOptionLabel(
          sampleFieldName
        );
    } else if (fieldConfig.field_type === "SELECT") {
      val = showSelectedValue(sampleFieldName);
    } else if (fieldConfig.field_type === "SELECT_MULTI") {
      val = sampleFieldName.map((ele) => showSelectedValue(ele)).join(",");
    } else {
      val = sampleFieldName;
    }
    return val;
  }
};

const showSelectedValue = (data) => {
  if (typeof data === "object") {
    return data.label;
  } else {
    return data;
  }
};

export function getDefaultValues(fields = [], { emptyValue = '' }) {
  /**
   * Default values set in LIMS for `estimated_start_date` 
   * and `estimated_complete_date` don’t match their `DATE` field types. 
   * Ignore these defaults temporarily until this is fixed in LIMS.
   */
  const testFieldsToIgnore = [
    'estimated_start_date',
    'estimated_complete_date',
  ];

  function toSelectOptions(value) {
    return Array.isArray(value)
      ? value.map(toSelectOptions)
      : { label: value, value };
  }

  return fields.reduce((acc, field) => {
    let currentValue;
    const {
      name,
      field_type: type,
      portal_default_value: defaultValue,
      additional_attribute: isAdditional,
    } = field;

    if (testFieldsToIgnore.includes(name)) currentValue = null;
    else if (isEmpty(defaultValue) || !isPlainObject(defaultValue)) {
      switch (type) {
        case AddlFieldType.SELECT:
        case AddlFieldType.SELECT_MULTI:
          currentValue = isAdditional
            ? toSelectOptions(defaultValue)
            : defaultValue;
          break;
        default:
          currentValue = defaultValue;
      }
    } else {
      const [key, val] = Object.entries(defaultValue).at(0) || [];
      currentValue = !val
        ? { label: defaultValue, value: defaultValue }
        : {
            value: key,
            // Note: Can also do: val.replace(`${key} `, '').replace(`— `, ''),
            label: val.split('—').at(-1).trim(),
          };
    }

    return { ...acc, [name]: currentValue || emptyValue };
  }, {});
}

export function getRequiredFields(fields = []) {
  return fields
    .filter(
      (field) =>
        field.portal_required &&
        field.order_request_field &&
        !(
          field.portal_hidden ||
          field.hidden ||
          field.global_hidden ||
          field.portal_global_hidden
        )
    )
    .map((field) => field.name);
}
