import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ButtonTooltip = styled(Tooltip)`
  transition: none;
  opacity: 0.95 !important;
  ${(props) =>
    props.$placement === 'top' ? 'bottom' : 'top'}: 5px !important;

  .tooltip-arrow::before {
    ${(props) => (props.$placement === 'top' ? 'top' : 'bottom')}: 5px;
    border-top-color: ${(props) => props.theme.color.palette.grey[900]};
  }

  .tooltip-inner {
    padding: 0.45rem 0.5rem 0.6rem;
    border-radius: 0.4rem;
    color: ${(props) => props.theme.color.palette.text.defaultLight};
    background-color: ${(props) => props.theme.color.palette.grey[900]};
  }
`;

export const ButtonWrapper = styled(Button)`
  &:focus {
    box-shadow: none;
  }
  ${({marginRight}) => marginRight ? `margin-right: ${marginRight}` : ''}
`;

export default function IconButton({
  icon,
  color = '',
  tooltip = '',
  placement = 'top',
  children,
  ...props
}) {
  const baseButton = (
    <ButtonWrapper variant="link" {...props}>
      <FontAwesomeIcon icon={icon} color={color || '#666'} />
      {children && children}
    </ButtonWrapper>
  );

  if (!tooltip) return baseButton;
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 0, hide: 0 }}
      overlay={
        <ButtonTooltip
          id={tooltip}
          className="p-0 shadow"
          $placement={placement}
        >
          {tooltip}
        </ButtonTooltip>
      }
    >
      {baseButton}
    </OverlayTrigger>
  );
}
