const RETURN_STUB = process.env.REACT_APP_STUBS_ENABLED === "true";

export const AuthStub = {
    current: () => {
        const LOGGED_OUT_STUB = process.env.REACT_APP_LOGGED_OUT_STUB === "true";

        if (RETURN_STUB) {

            if (LOGGED_OUT_STUB) {
                throw new Error('Logged Out')

            } else {
                return {
                    data: {
                        address: null,
                        admin: false,
                        contact_customers: null,
                        contact_suppliers: null,
                        customers: null,
                        email_address: "melvin@junctionconcepts.com",
                        entities_followed: null,
                        fax: null,
                        first_name: "Foo",
                        id: 3,
                        is_doctor: null,
                        last_name: "Bar",
                        last_updated: "12/16/2020",
                        mobile: null,
                        phone: null,
                        preferences: {
                            selected_customer_id: 1
                        },
                        suppliers: null,
                        tags: null,
                      }
                }
            }
        }
    },
    login: () => RETURN_STUB && {data: {message: "OK"}},
    logout: () => RETURN_STUB && {data: {message: "OK"}},
    forgotPwd: () => RETURN_STUB && {data: {message: "OK"}},
    forgotPwdValidate: () => RETURN_STUB && {data: {validated: true}},
    resetPwd: () => RETURN_STUB && {data: {message: "OK"}},
    signUp: () => RETURN_STUB && {data: {message: "OK"}},
    validateMFA: () => RETURN_STUB && {data: {message: "OK"}}
}
