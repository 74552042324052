import { useQuery } from 'react-query';
import { axiosinstance } from 'shared/utils/api';

const baseUrl = '/v1/billing';

export function useCurrency() {
 const options = {
   url: `${baseUrl}/settings/currency`,
   queryKey: ['billing/settings/currency']
 };

 const fetchData = () =>
   axiosinstance.get(`${options.url}`).then((res) => res.data);

 const { data, ...queryResult } = useQuery(options.queryKey, fetchData);

 return {
   data: data?.data || {},
   ...queryResult,
 };
}