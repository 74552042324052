import { useAppStore } from 'App/store';
import React from 'react';
import Logo from './Logo/Logo';
import useResponsive from 'shared/hooks/misc/useResponsive';
import Search from 'shared/components/Search/Search';
import MainMenu from '../MainMenu';
import { DesktopMenu, MobileMenu, StyledNavBar, NavBarWrapper } from './Styles';
import BurgerMenu from 'shared/layout/PortalTopNav/BurgerMenu/BurgerMenu';

const PortalTopNav = props => {
	const {
		appState: { isAuth, customPortalLogo },
	} = useAppStore();

	const { isMobile } = useResponsive();

	return (
		<StyledNavBar>
			<NavBarWrapper isAuth={isAuth}>
				{isAuth && (
					<React.Fragment>
						{isMobile ? (
							<MobileMenu>
								<Logo isMobile customPortalLogo={customPortalLogo}/>
								<Search />
								<BurgerMenu />
							</MobileMenu>
						) : (
							<DesktopMenu>
								<Logo customPortalLogo={customPortalLogo}/>
								<MainMenu />
							</DesktopMenu>
						)}
					</React.Fragment>
				)}
			</NavBarWrapper>
		</StyledNavBar>
	);
};

export default PortalTopNav;


