import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOrderRequestDispatch } from "Order/store";
import React, { useEffect, useState } from "react";
import { EntityType } from "shared/constants/entityTypes";
import { useEntityDisplayName } from 'shared/hooks/misc/fields';
import useResponsive from "shared/hooks/misc/useResponsive";

import { StatusBarCircleStyle, StatusBarWizardStyle } from "./Styles";

export const clsAction = {
  createOrder: "GOTO_ORDER_DETAILS",
  addSamples: "GOTO_ADD_SAMPLES",
  selectTests: "GOTO_SELECT_TESTS",
  reviewOrder: "GOTO_REVIEW_ORDER",
  confirm: "GOTO_ORDER_CONFIRMATION",
};

const StatusBarCircle = (props) => {
  const status = props.status ? props.status.toLowerCase() : "";
  const dispatch = useOrderRequestDispatch();
  const [seen, setSeen] = useState(false);

  const onClick = () => {
    if (status === "completed" || seen) {
      dispatch({ type: clsAction[props.step] });
    }
  };

  useEffect(() => {
    if (status === "completed" || status === "active") {
      setSeen(true);
    }
  }, [status]);
  return (
    <StatusBarCircleStyle className={status} status={status}>
      <span>
        {status === "completed" && (
          <FontAwesomeIcon className="check-icon" icon={faCheck} />
        )}
      </span>
      <div className="content" onClick={onClick}>
        {props.children}
      </div>
    </StatusBarCircleStyle>
  );
};
const StatusBarWizard = (props) => {
  const statuses = props.statuses;
  const [orderRequestSingular] = useEntityDisplayName(EntityType.ORDER_REQUEST);
  const [, samplePlural] = useEntityDisplayName(EntityType.SAMPLE);
  const [, testPlural] = useEntityDisplayName(EntityType.TEST);
  const deviceType = useResponsive();

  return (
		<StatusBarWizardStyle>
			{deviceType.isMobile || deviceType.isMobileOnly ? '' : <p className='title'>{`New ${orderRequestSingular}`}</p>}
			<ul>
				<StatusBarCircle
					step='createOrder'
					status={statuses.createOrder}
				>
					Create {orderRequestSingular}
				</StatusBarCircle>
				<StatusBarCircle step='addSamples' status={statuses.addSamples}>
					Add {samplePlural}
				</StatusBarCircle>
				<StatusBarCircle
					step='selectTests'
					status={statuses.selectTests}
				>
					Select {testPlural}
				</StatusBarCircle>
				<StatusBarCircle
					step='reviewOrder'
					status={statuses.reviewOrder}
				>
					Review {orderRequestSingular}
				</StatusBarCircle>
				<StatusBarCircle step='confirm' status={statuses.confirm}>
          Confirmation
				</StatusBarCircle>
			</ul>
		</StatusBarWizardStyle>
  );
};

export default StatusBarWizard;
