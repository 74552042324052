import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import jstz from "jstz";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { AssayStub } from "shared/stub/assays";
import { AttachmentStub } from "shared/stub/attachments";
import { AuthStub } from "shared/stub/auth";
import { OrderStub } from "shared/stub/orders";
import { SampleStub } from "shared/stub/samples";
import { TestStub } from "shared/stub/tests";
import { objectToQueryParams } from "./misc";

const baseURL = process.env.REACT_APP_BASE_URL || "/portal/api";

export const axiosinstance = axios.create({
  baseURL,
  timeout: 60000,
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    "X-Local-Timezone": jstz.determine().name(),
  },
});

loadProgressBar(NProgress, axiosinstance);

axiosinstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let errorString = 'Unknown Error';

    if (error.response && error.response.status === 404) {
      errorString = 'Resource Not Found.';
    } else if (error.response && error.response.status === 403) {
      errorString = 'Forbidden: Insufficient permissions.';
    } else if (error.response) {
      errorString =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Unknown Error';
    } else if (error.code === 'ECONNABORTED') {
      errorString = 'Timeout exceeded ' + error.request.timeout + 'ms';
    } else if (error.isAxiosError && !error.response) {
      errorString = 'Network error';
    }

    if (
      error.response &&
      error.response.status !== 401 &&
      error.response.status !== 404
    ) {
      // If we receive a `MirageValidationError` when logging out or refreshing 
      // access token, chances are it's a tokenless request.
      const shouldNotify = !['logout', 'refresh_token'].some(path => path === (error.config?.url || '').split('/').at(-1));
      if (shouldNotify) NotificationManager.error(errorString, 'Error', 3000);
    }

    return Promise.reject(error.response);
  }
);
axiosinstance.interceptors.request.use((config) => {
  config.headers["X-Customer-ID"] = localStorage.getItem("customerId");
  config.headers["X-Division-ID"] = sessionStorage.getItem("divisionId");
  return config;
});

export const useApi = (method, url) => {
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancelled = false;
    setLoading(true);

    axiosinstance[method.toLowerCase()](url).then((r) => {
      if (!cancelled) {
        setResult(r.data);
        setLoading(false);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [method, url]);

  return [result, loading];
};

export const Fields = {
  all: () => axiosinstance.get("/v1/fieldconfig"),
};

export const Auth = {
  current: () => AuthStub.current() || axiosinstance.get(`/v1/userinfo`),
  login: (email, password) =>
    AuthStub.login() ||
    axiosinstance.post(`/v1/login`, JSON.stringify({ email, password })),
  samlLogin: (authCode) =>
    axiosinstance.post(`/v1/login/sso/saml`, { qb_auth: authCode }),
  validateMFA: (email, password) => 
    AuthStub.validateMFA() || 
    axiosinstance.post(`/v1/mfa/validate`, JSON.stringify({ email, password })),
  logout: () =>
    AuthStub.logout() ||
    axiosinstance.post(`/v1/logout`, {
      csrf_token: localStorage.getItem("csrfToken"),
    }),
  forgotPwd: (email) =>
    AuthStub.forgotPwd() ||
    axiosinstance.post(`/v1/forgotpwd`, JSON.stringify({ email })),
  forgotPwdValidate: (email, code) =>
    AuthStub.forgotPwdValidate() ||
    axiosinstance.post(
      `/v1/forgotpwd/validate`,
      JSON.stringify({ email, code })
    ),
  resetPwd: (data) =>
    AuthStub.resetPwd() ||
    axiosinstance.post(`/v1/password/reset`, JSON.stringify(data)),
  refreshToken: () =>
    axiosinstance.post(`/v1/refresh_token`, {
      csrf_token: localStorage.getItem("csrfToken"),
    }),
  signUp: (signUpData) => 
    AuthStub.signUp() || axiosinstance.post(`/v1/signup`, JSON.stringify(signUpData)),
  divisionData: () => axiosinstance.get(`/v1/divisions`),
};

export const Orders = {
  create: (order) =>
    OrderStub.create(order) || axiosinstance.post(`/v1/order`, order),
  get: (id) => OrderStub.get(id) || axiosinstance.get(`/v1/order/${id}`),
  update: (order) =>
    OrderStub.update(order) ||
    axiosinstance.patch(`/v1/order/${order.id}`, order),
  delete: (id) =>
    OrderStub.delete(id) || axiosinstance.delete(`/v1/order/${id}`),
  all: (page = 1, pageSize = 20) =>
    OrderStub.all(page, pageSize) ||
    axiosinstance.get(`/v1/orders?page_num=${page}&page_size=${pageSize}`),
};
export const Samples = {
  create: (sample) =>
    SampleStub.create(sample) || axiosinstance.post(`/v1/sample`, sample),
  get: (id) => SampleStub.get(id) || axiosinstance.get(`/v1/sample/${id}`),
  getByOrderId: (orderId) =>
    SampleStub.getByOrderId(orderId) ||
    axiosinstance.get(`/v1/order/${orderId}/samples`),
  update: (sample) =>
    SampleStub.update(sample) ||
    axiosinstance.patch(`/v1/sample/${sample.id}`, sample),
  delete: (id) =>
    SampleStub.delete(id) || axiosinstance.delete(`/v1/sample/${id}`),
  all: (page = 1, pageSize = 20) =>
    SampleStub.all(page, pageSize) ||
    axiosinstance.get(`/v1/samples?page_num=${page}&page_size=${pageSize}`),
  clone: (id) =>
    SampleStub.clone(id) || axiosinstance.post(`/v1/sample/${id}/clone`),
};

export const Tests = {
  createMulti: (tests) =>
    TestStub.createMulti(tests) || axiosinstance.post(`/v1/tests`, tests),
};

export const Attachments = {
  save: (dataType, attachment) =>
    AttachmentStub.save(dataType, attachment) ||
    axiosinstance.post(`/v1/${dataType}/attachment/save`, attachment),
  get: (dataType, params) =>
    AttachmentStub.get(dataType, params) ||
    axiosinstance.get(
      `/v1/${dataType}/attachments?${objectToQueryParams(params)}`
    ),
  delete: (entType, attachmentId) =>
    axiosinstance.delete(`/v1/${entType}/attachment/${attachmentId}`),
};

export const Assets = {
  checkUpload: (assetId) => axiosinstance.get(`/v1/asset/${assetId}/uploaded`),
  download: (assetId) => axiosinstance.get(`/v1/asset/${assetId}/download`),
};

export const Assays = {
  get: (params) =>
    AssayStub.get(params) ||
    axiosinstance.get(`/v1/assays?${objectToQueryParams(params)}`),
};

export const LabelTemplates = {
  getByType: (level) =>
    axiosinstance.get(`/v1/template/labels/${level.toLowerCase()}`),
  get: () => axiosinstance.get(`/v1/template/labels`),
};
export const Labels = {
  generate: (dataType, label_id, entity_dicts) =>
    axiosinstance.post(`/v1/template/labels/${dataType}/print`, {
      label_id,
      entity_dicts,
    }),
};

export const PrintDocTemplates = {
  getByType: (level) =>
    axiosinstance.get(`/v1/template/printdocs/${level.toLowerCase()}`),
  get: () => axiosinstance.get(`/v1/template/labels`),
};

export const PrintDocs = {
  get: (id) => axiosinstance.get(`/v1/printdoc/${id}`),
  view: (id) => axiosinstance.get(`/v1/printdoc/${id}/view`),
  generate: (level, ent_id, template_id) =>
    axiosinstance.post(`/v1/printdoc/${level.toLowerCase()}/generate`, {
      ent_id,
      template_id,
    }),
};

export const Invoices = {
  billing: () => axiosinstance.get(`/v1/billing`),
};

export const useMetrics = {
  get: () => axiosinstance.get(`/v1/dashboard/metrics`),
};