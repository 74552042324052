import { Link } from "react-router-dom";
import { useAppStore } from "App/store";
import { queryClient } from "index";
import { useState } from 'react';
import { Button } from "shared/components/Button";
import DropdownMenu from "shared/components/DropdownMenu";
import Modal from "shared/components/Modal";
import useResponsive from 'shared/hooks/misc/useResponsive';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useNavigate } from "react-router";

const UserMenu = () => {
  const {
    appState: { customers, customerId, user },
    appStateDispatch,
    logout,
  } = useAppStore();
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);

  const customer = customers.find((c) => c.id === customerId);

  const switchAccount = (customer) => {
    appStateDispatch({ type: 'SET_CUSTOMER_ID', payload: customer.id });
    setShowSwitchAccount(false);
	window.location.reload();
    queryClient.invalidateQueries("orders");
  };

  const { isMobile } = useResponsive();
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
		<DropdownMenu
			modalOpen={showSwitchAccount}
			btn={
				<>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.06999 16.2801C5.49999 15.3801 8.11999 14.5001 9.99999 14.5001C11.88 14.5001 14.51 15.3801 14.93 16.2801C13.57 17.3601 11.86 18.0001 9.99999 18.0001C8.13999 18.0001 6.42999 17.3601 5.06999 16.2801ZM9.99978 12.5001C11.4598 12.5001 14.9298 13.0901 16.3598 14.8301C17.3798 13.4901 17.9998 11.8201 17.9998 10.0001C17.9998 5.59005 14.4098 2.00005 9.99978 2.00005C5.58978 2.00005 1.99978 5.59005 1.99978 10.0001C1.99978 11.8201 2.61978 13.4901 3.63978 14.8301C5.06978 13.0901 8.53978 12.5001 9.99978 12.5001ZM9.99957 3.99997C8.05957 3.99997 6.49957 5.55997 6.49957 7.49997C6.49957 9.43997 8.05957 11 9.99957 11C11.9396 11 13.4996 9.43997 13.4996 7.49997C13.4996 5.55997 11.9396 3.99997 9.99957 3.99997ZM8.50043 7.50003C8.50043 8.33003 9.17043 9.00003 10.0004 9.00003C10.8304 9.00003 11.5004 8.33003 11.5004 7.50003C11.5004 6.67003 10.8304 6.00003 10.0004 6.00003C9.17043 6.00003 8.50043 6.67003 8.50043 7.50003Z" fill={theme.color.palette.text.headerText} />
					</svg>

					{isMobile && <span>User Account</span>}
				</>
			}
		>
			<ul>
				<li>
					<div>
						Welcome,{' '}
						<b>
							{user.first_name} {user.last_name}!
						</b>
						<br />
						<small>
							Selected Customer:{' '}
							{customer ? customer.customer_name : 'none'}
						</small>
					</div>
				</li>
				<li onClick={() => setShowSwitchAccount(true)}>
					<a href='#!'>Switch Account</a>
				</li>
				<li onClick={() => {navigate({
                	pathname: `/portal/settings`
            		})}}>
					<a href='#!'>Settings</a>
				</li>
				<li>
					<Link to="/portal/helpcenter">Help Center</Link>
				</li>
				<li onClick={logout}>
					<a href='#!'>Logout</a>
				</li>
			</ul>
			<Modal
				show={showSwitchAccount}
				title='Switch Account'
				handleClose={() => setShowSwitchAccount(false)}
			>
				{customers.map((customer, idx) => (
					<Button
						block
						contained
						disabled={customer.id === customerId}
						key={idx}
						onClick={() => switchAccount(customer)}
						style={{ margin: 8 }}
					>
						{customer.customer_name}{' '}
						{customer.id === customerId ? '(active)' : ''}
					</Button>
				))}
			</Modal>
		</DropdownMenu>
  );
};

export default UserMenu;
