import classNames from 'classnames';
import styled from 'styled-components';
import { Dropdown as BSDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import policies from 'shared/configs/rbac/accessPolicies';
import AccessControl from './AccessControl';

const Dropdown = styled(BSDropdown)`
  position: static;

  .dropdown-menu > a {
    background-color: unset;
  }
  .dropdown-toggle {
    &::after {
      display: none; /* Remove the down arrow set by Bootstrap */
    }
  }
`;

const DropdownMenu = styled(BSDropdown.Menu)`
  padding-top: 1px;
  overflow: hidden;
  border-radius: 0.4rem;
  max-width: ${(props) => props.$maxWidth}rem;
`;

export const MenuItem = styled(BSDropdown.Item)`
  padding: 0.75rem 1rem;
  font-size: 0.8125rem;

  &:hover {
    background-color: ${(props) =>
      props.theme.color.palette.grey[100]} !important;
  }

  &.disabled {
    opacity: 0.4;
  }

  .action-title {
    line-height: 1;
    font-weight: 500;
    margin-bottom: 3px;
    color: ${(props) => props.theme.color.palette.grey[800]};
  }

  .action-description {
    margin-bottom: 0;
    word-break: break-word;
    white-space: normal;
    line-height: 1.3;
    color: ${(props) => props.theme.color.palette.text.light};
  }
`;

const GroupSeparator = styled.div`
  color: #888;
  background: #f8f8f8;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.6875rem;
  text-transform: uppercase;
  padding: 0.375rem 1rem;
  margin-top: -1px;
`;

export default function ActionMenu({
  maxWidth = 10,
  actions = [],
  handleMenuItemClick,
  children,
  ...props
}) {
  function renderMenuItem(item, i) {
    const {
      title,
      icon = null,
      disabled = false,
      description = '',
      isGroupSeparator = false,
      accessPolicy = policies.none,
      ...props
    } = item;

    if (isGroupSeparator)
      return (
        <AccessControl key={i} {...accessPolicy}>
          <GroupSeparator key={i}>{title}</GroupSeparator>
        </AccessControl>
      );

    return (
      <AccessControl key={i} {...accessPolicy}>
        <>
          <MenuItem
            className="hstack gap-3 align-items-start"
            onClick={() => handleMenuItemClick(i + 1)}
            disabled={disabled}
            {...props}
          >
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                color="#aaa"
                className={classNames(description && 'ms-1 mt-2')}
              />
            )}
            <div>
              <div className="action-title">{title}</div>
              {description && (
                <div className="action-description">{description}</div>
              )}
            </div>
          </MenuItem>
          {i + 1 !== actions.length && <BSDropdown.Divider className="m-0" />}
        </>
      </AccessControl>
    );
  }

  return (
    <Dropdown {...props}>
      <BSDropdown.Toggle as="div" className="p-0">
        {children}
      </BSDropdown.Toggle>
      <DropdownMenu $maxWidth={maxWidth} align="end">
        {actions.map(renderMenuItem)}
      </DropdownMenu>
    </Dropdown>
  );
}
