import { useMediaQuery } from "react-responsive";

/**
 * Hook for detecting mobile
 */
const useIsMobile = () => {
  return useMediaQuery({ query: `${({ theme }) => theme.device.desktop}` });
};

export default useIsMobile;
