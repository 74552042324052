import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppStore } from 'App/store';
import { useMeQuery } from 'shared/hooks/api/contacts.js';

const Home = lazy(() => import('../Dashboard/Pages/Home/Home.jsx'));
const CreateOrder = lazy(() => import('../Order/index'));
const Orders = lazy(() => import('../Dashboard/Pages/Orders/Orders'));
const OrderDetails = lazy(() =>
	import('../Dashboard/Pages/Orders/OrderDetails/OrderDetails')
);
const Invoices = lazy(() => import('../Dashboard/Pages/Invoices/Invoices'));
const InvoiceDetails = lazy(() => import('../Dashboard/Pages/Invoices/InvoiceDetails/InvoiceDetails'));
const Quotations = lazy(() => import('../Dashboard/Pages/Quotations/QuotationList'));
const QuotationDetails = lazy(() => import('../Dashboard/Pages/Quotations/QuotationDetails'));
const QuotationPreview = lazy(() => import('../Dashboard/Pages/Quotations/Preview'));
const SourceList = lazy(() => import('../Dashboard/Pages/Sources/SourceList/SourceList.js'));
const SourceDetails = lazy(() => import('../Dashboard/Pages/Sources/SourceDetails/SourceDetails.js'));
const TokenizedSignUp = lazy(() => import('../SignUp/TokenizedSignUp'));
const HelpCenter = lazy(() => import('../Dashboard/Pages/HelpCenter/DocumentList'));
const Settings = lazy(() => import('../Dashboard/Pages/Settings/UserSettings.js'));
const NotFound = lazy(() => import('shared/layout/NotFound'));

export const publicRoutes = [
  {
    path: '/portal/quotations/preview',
    element: <QuotationPreview />,
  },
  {
    path: '/portal/quotations/preview/:uuid',
    element: <QuotationPreview />,
  },
  {
    path: '/portal/onboard',
    element: <TokenizedSignUp />,
  },
];

const Content = () => {
	const { appState: { billingEnabled } } = useAppStore();
	const [{enforce_mfa_for_portal, two_fa_enabled}] = useMeQuery();
	return (
		<Routes>
			<Route path='/portal' exact element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : <Home/>} />
			<Route path='/portal/home' element={<Navigate to='/portal' />} />
			<Route path='/portal/createorder' element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : <CreateOrder/>} />
			<Route path="/portal/createorder/:id" element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : <CreateOrder/>} />
			<Route path='/portal/orders' exact element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : <Orders/>} />
			<Route path='/portal/orders/:id' element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : <OrderDetails/>} />
			<Route path='/portal/invoices' exact element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : (billingEnabled ? <Invoices/> : <NotFound/>)} />
			<Route path='/portal/invoices/:id' element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : (billingEnabled ? <InvoiceDetails/> : <NotFound/>)} />
			<Route path='/portal/quotations' exact element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : (billingEnabled ? <Quotations/> : <NotFound />)} />
			<Route path='/portal/quotations/:id' element={enforce_mfa_for_portal && !two_fa_enabled ?<Settings/> : (billingEnabled ? <QuotationDetails/> : <NotFound />)} />
			<Route path='/portal/sources' exact element={enforce_mfa_for_portal && !two_fa_enabled ? <Settings /> : <SourceList />} />
			<Route path='/portal/sources/:id' element={enforce_mfa_for_portal && !two_fa_enabled ? <Settings /> : <SourceDetails />} />
			<Route path='/portal/settings' exact element={<Settings/>} />
			<Route path='/portal/helpcenter' exact element={<HelpCenter />} />
			<Route exact path='/' element={<Navigate to='/portal' />} />
			<Route path='*' element={<NotFound/>} />
		</Routes>
	);
};

export default Content;
