import { StyledPortalMainMenu, StyledTab, StyledTabDummy, StyledTabList } from './styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'shared/components/common/Button';
import UserMenu from '../PortalTopNav/UserMenu';
import { useEntityDisplayName } from 'shared/hooks/misc/fields';
import { EntityType } from 'shared/constants/entityTypes';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useAppStore } from 'App/store';
import OutstandingBalance from './OutstandingBalance';
import { ENABLED_MODULES, PORTALVIEW_PERMISSION } from 'shared/constants/fields';
import usePermissionCheck from 'shared/hooks/misc/usePermissionCheck';
import useSourceParameters from 'shared/hooks/misc/useSourceParameters';

export const menu = [
	{
		name: 'createorder',
		entityType: 'order',
		title: (entitySingular, entityPlural) => {
			return `+ Create ${entitySingular}`
		},
		icon: null,
		to: '/portal/createorder',
		permission: PORTALVIEW_PERMISSION.ORDER_REQUEST_CREATE,
	},
	{
		name: 'orders',
		entityType: 'order',
		title: (entitySingular, entityPlural) => {
			return `${entityPlural}`
		},
		icon: null,
		to: '/portal/orders',
	},
	{
		name: 'invoices',
		entityType: 'invoice',
		title: (entitySingular, entityPlural) => {
			return `${entityPlural}`
		},
		icon: null,
		to: '/portal/invoices',
		permission: ENABLED_MODULES.BILLING,
	},
	{
		name: 'quotations',
		entityType: 'quotation',
		title: (_, entityPlural) => entityPlural,
		icon: null,
		to: '/portal/quotations',
		permission: ENABLED_MODULES.BILLING,
	},
	{
		name: 'sources',
		entityType: EntityType.SOURCE,
		title: (_, entityPlural) => entityPlural,
		icon: null,
		to: '/portal/sources',
	},
];

function PortalMainMenu() {
	const selectedTab = useLocation().pathname?.split('/')[2];
	return (
		<StyledPortalMainMenu>
			<TabList selectedTab={selectedTab} />
			<OutstandingBalance />
			<UserMenu />
		</StyledPortalMainMenu>
	);
}

const TabList = ({ selectedTab }) => {
	return (
		<StyledTabList>
			{menu.map(tab => (
				<Tab
					key={tab.name}
					tab={tab}
					selected={tab.name === selectedTab}
				/>
			)
			)}
		</StyledTabList>
	)
};

const Tab = ({
  tab: { to, entityType, title, name, permission },
  selected,
}) => {
	const theme = useContext(ThemeContext);
	const { appStateDispatch } = useAppStore();
	const { pathname } = useLocation();
	const history = useNavigate();
	const isSufficient = usePermissionCheck(permission);
	const { isEnabled: isSourceEnabled } = useSourceParameters();

	const activeButtonStyle = {
		borderBottom: `2px solid ${theme.color.palette.text.headerText}`,
		borderRadius: '0px'
	}
	const [entitySingular, entityPlural] = useEntityDisplayName(EntityType[entityType?.toUpperCase()]);

	const entityTitle = title(entitySingular, entityPlural);

	const onClick = () => {
		if (pathname === "/portal/createorder") {
			appStateDispatch({ type: 'RESET_CREATE_ORDER', payload: true });
		}
		history({ pathname: "/portal/createorder" })
	};

	if (!isSufficient || (entityType === EntityType.SOURCE && !isSourceEnabled)) {
    return null;
  }

	return (
		<>
			{name === 'createorder' ? <StyledTabDummy selected={selected} onClick={() => onClick(true)}>
				<Button type='headerText' style={selected ? activeButtonStyle : {}}>
					{entityTitle}
				</Button>
			</StyledTabDummy> : <StyledTab to={to} selected={selected} onClick={() => localStorage.removeItem("orderId")}>
					<Button type='headerText' style={selected ? activeButtonStyle : {}}>
						{entityTitle}
					</Button>
				</StyledTab>}
		</>
	);
};

export default PortalMainMenu;
