const reducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case 'SET_FIELD':
			return {
				...state,
				[payload.name]: payload.value,
			};
		case 'ORDERS_LOADED':
			return {
				...state,
				orders: payload,
				ordersWidgetLoaded: true,
			};
		case 'LOG':
			return {
				...state,
				logged: true,
			};
		default:
			return state;
	}
};

export default reducer;
