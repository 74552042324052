import permissions from 'shared/constants/permissions';

const { read } = permissions;
const bypass = {
  privilege: null,  // `null` bypasses access policy checking
};

/**
 * @type {Object.<string, import('shared/hocs/withAccessControl').AccessControlProps>}
 */
const routePolicies = {
  'createorder': {
    privilege: null,
  },
  'orders': bypass,
  'orders/:id': bypass,
  'invoices': {
    privilege: [read.invoice],
  },
  'invoices/:id': {
    all: true,
    privilege: [read.invoice],
  },
  'quotations': {
    privilege: [read.quotation],
  },
  'quotations/:id': {
    all: true,
    privilege: [read.quotation],
    // onDenied: (navigate) => navigate('/portal/'),
  },
  'sources': bypass,
  'sources/:id': bypass,
  'reports': {
    privilege: [read.report],
  },
};

/**
 * @type {Object.<string, import('shared/hocs/withAccessControl').AccessControlProps>}
 */
const uiPolicies = {
  orderBulkActionMenu: routePolicies.orders,
  quoteAcceptDeclineBtn: routePolicies.quotations,
  reportDownloadBtn: routePolicies.reports,
  reportBulkDownloadMenuItem: routePolicies.reports,
};

const policies = {
  none: bypass,
  ui: uiPolicies,
  route: routePolicies,
};

export default policies;
