import styled from "styled-components";

export const DropdownMenuStyle = styled.div`
	.menu-container {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		& img {
			margin-right: 5px;
		}
	}

	.menu-trigger {
		outline: none;
		background-color: transparent;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 4px;
		margin: 4px;
		vertical-align: middle;
		transition: box-shadow 0.4s ease;
		// margin-left: auto; /* Strictly for positioning */
	}
	// .menu-trigger.active {
	// 	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	// }

	// .menu-trigger:hover {
	// 	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	// }

	// .menu-trigger span {
	//   font-weight: 700;
	//   vertical-align: middle;
	//   font-size: 14px;
	//   margin: 0 10px;
	// }

	// .menu-trigger img {
	//   border-radius: 90px;
	// }

	.menu {
		background: #ffffff;
		border-radius: 8px;
		position: absolute;
		top: 60px;
		right: 0;
		width: 300px;
		box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
		opacity: 0;
		visibility: hidden;
		transform: translateY(-20px);
		transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	}

	.menu.active {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		z-index: 1;
	}

	.menu ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.menu li {
		border-bottom: 1px solid #dddddd;
	}

	.menu li a {
		text-decoration: none;
		color: #333333;
		padding: 16px 24px;
		display: block;
	}

	.menu div {
		padding: 16px 24px;
	}
`;
