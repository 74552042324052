import styled from "styled-components";

export const StyledNavBar = styled.header`
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 1020;
	width: 100%;
	height: 56px;
	box-shadow: 1px 1px 5px 1px rgb(34 60 80 / 10%);
	background-color: ${({ theme }) =>
		theme.color.palette.background.defaultLight};
	padding: 10px 5px 10px 25px;

	@media ${({ theme }) => theme.device.mobile} {
		padding: 10px;
	}
`;

export const NavBarWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

export const MobileMenu = styled.div`
	display: flex;
	width: 100%;
	align-items: center;

	& > .searchWrapper {
		flex-grow: 1;
		margin-right: 20px;

		@media ${({ theme }) => theme.device.mobile} {
			margin-right: 5px;
		}
	}
`;

export const DesktopMenu = styled.div`
	display: flex;
	width: 100%;
	align-items: center;

	& .topnav-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& > div:not(:last-child) {
			margin-right: 10px;
		}
	}
`;
