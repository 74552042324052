import { useEffect } from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import ActionMenu, { MenuItem } from 'shared/components/ActionMenu';
import useActionMenu from 'shared/hooks/misc/useActionMenu';
import { safeJsonParse } from 'shared/utils/functions';
import IconButton from 'shared/components/IconButton';
import { useAppStore } from 'App/store';

export const STORAGE_KEY = 'displaySettings';

const Wrapper = styled(MenuItem)`
  &:hover {
    background-color: transparent !important;
  }
`;

function DensitySetting({
  title,
  description,
  checked,
  onChange,
}) {
  return (
    <Wrapper as="div" className="hstack justify-content-between">
      <div style={{ cursor: 'default' }}>
        <div className="action-title">{title}</div>
        <div className="action-description mt-1">{description}</div>
      </div>
      <Form.Switch checked={checked} onChange={onChange} />
    </Wrapper>
  );
}

function OrderDisplaySettings() {
  const { appState, appStateDispatch } = useAppStore();
  const { collapse } = appState.displaySettings;
  const actions = [
    { title: 'Density', isGroupSeparator: true },
    {
      title: 'Collapse By Samples',
      as: () => (
        <DensitySetting
          checked={collapse.allSamples}
          title="Collapse By Samples"
          description="Hide all Test information"
          onChange={() => collapseAll({ isOrders: false })}
        />
      ),
    },
    {
      title: 'Collapse By Orders',
      as: () => (
        <DensitySetting
          checked={collapse.allOrders}
          title="Collapse By Orders"
          description="Hide all Sample and Test information"
          onChange={() => collapseAll({ isOrders: true })}
        />
      ),
    },
  ];
  const { menuProps } = useActionMenu(actions);

  useEffect(() => {
    dispatch(safeJsonParse(localStorage.getItem(STORAGE_KEY)) ?? {});
  }, []);

  useEffect(() => {
    const { byOrders: _, bySamples: __, ...payload } = collapse;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(payload));
  }, [collapse]);

  function dispatch(payload = {}) {
    appStateDispatch({
      type: 'SET_DISPLAY_SETTINGS',
      payload: {
        collapse: { ...payload },
      },
    });
  }

  function collapseAll({ isOrders = true }) {
    dispatch({
      [isOrders ? 'allOrders' : 'allSamples']: !(isOrders
        ? collapse.allOrders
        : collapse.allSamples),
    });
  }

  return (
    <ActionMenu maxWidth={14} {...menuProps} autoClose="outside">
      <IconButton icon={faCog} color="#888" tooltip="View Settings" />
    </ActionMenu>
  );
}

export default OrderDisplaySettings;
