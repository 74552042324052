import { useState, useMemo } from 'react';

export default function useActionMenu(actions = []) {
  const visibleActions = useMemo(() => actions.filter(a => !a.hidden), [actions]);
  const [activeIndex, setActiveIndex] = useState(0);
  const onToggle = () => setActiveIndex(0);

  function renderActionModals() {
    return visibleActions.map((action, i) => {
      const C = action.modal;
      if (i + 1 !== activeIndex) return null;
      return (
        <C
          key={i}
          visible={i + 1 === activeIndex}
          onDismiss={onToggle}
          {...action.modalProps}
        />
      );
    });
  }

  return {
    menuProps: {
      actions: visibleActions,
      handleMenuItemClick: setActiveIndex,
    },
    renderActionModals,
  };
}
