import { omit } from 'lodash';
import { EntityType } from 'shared/constants/entityTypes';

const strategies = {
  merge: {},
  process: {
    [EntityType.TEST]: (entity) =>
      omit(
        {
          ...entity,
          status: {
            value: entity.state || null,
            label: entity.state || '',
          },
        },
        ['state']
      ),
  },
  fallback: {},
};

export default strategies;
