import isNil from 'lodash/isNil';
import negate from 'lodash/negate';
import isEmpty from 'lodash/isEmpty';

export function isOdd(num) {
	return num % 2;
}

export function safeJsonParse(obj) {
  if (obj && typeof obj === 'string') {
    try {
      return JSON.parse(obj);
    } catch (e) {
      // Do nothing here
    }
  }
  return null;
}

export function isObject(val) {
  return val !== null && typeof val === 'object';
}

export function isNonArrayObject(val) {
  return isObject(val) && !Array.isArray(val);
}

export const isNotEmpty = negate(isEmpty);

export function navigateOutApp(url = '') {
  return url && window.open(url, '_blank', 'noreferrer');
}

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function isNilOrEmptyIterables(val) {
  return (
    isNil(val) ||
    (typeof val === 'string' && !val.length) ||
    (Array.isArray(val) && !val.length) ||
    (isNonArrayObject(val) && isEmpty(val))
  );
}
