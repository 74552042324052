import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setLocalStorage } from 'shared/theme/storage';
import * as theme from 'shared/theme/theme';
import App from './App';
import './fonts.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { publicRoutes } from 'App/routes';
import { AppProvider } from 'App/store';
import AppShell from 'App/AppShell';

setLocalStorage('default-theme', theme);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity,
    },
  },
});

function ProtectedRoutes() {
  return (
    <AppProvider>
      <App />
    </AppProvider>
  );
}

function Root() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppShell>
          <Routes>
            {publicRoutes.map((props) => (
              <Route key={props.path} {...props} />
            ))}
            <Route path="/*" element={<ProtectedRoutes />} />
          </Routes>
        </AppShell>
      </BrowserRouter>
    </React.StrictMode>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
