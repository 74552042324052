import { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';
import { NotificationContainer } from 'react-notifications';
import MainWrapper from 'shared/layout/MainWrapper';
import { useTheme } from 'shared/theme/useTheme';
import Loading from 'shared/layout/Loading';
import { queryClient } from 'index';

export default function AppShell({ children }) {
  const { theme } = useTheme();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <NotificationContainer />
        <Suspense fallback={<Loading />}>
          <MainWrapper>{children}</MainWrapper>
        </Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
