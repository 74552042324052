import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/theme/theme';

const useResponsive = () => {
	//mobile
	const Mobile = ({ children }) => {
		const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });
		return isMobile ? children : null;
	};
	//table
	const Tablet = ({ children }) => {
		const isTablet = useMediaQuery({
			maxWidth: breakpoints.table - 1,
		});
		return isTablet ? children : null;
	};
	//desktop
	const Desktop = ({ children }) => {
		const isDesktop = useMediaQuery({ minWidth: breakpoints.tablet });
		return isDesktop ? children : null;
	};
	//wide screen
	const WideScreen = ({ children }) => {
		const isWideScreen = useMediaQuery({ minWidth: breakpoints.wideScreen });
		return isWideScreen ? children : null;
	};

	//mobile only
	const useMobileOnly = () => {
		const useMobileOnly = useMediaQuery({
			maxWidth: breakpoints.mobileOnly - 1,
		});
		return useMobileOnly ? true : false;
	};

	//mobile
	const useMobile = () => {
		const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });
		return isMobile ? true : false;
	};
	//table
	const useTablet = () => {
		const isTablet = useMediaQuery({
			maxWidth: breakpoints.tablet - 1,
		});
		return isTablet ? true : false;
	};
	//desktop
	const useDesktop = () => {
		const isMobile = useMediaQuery({ minWidth: breakpoints.tablet });
		return isMobile ? true : false;
	};
	//wide screen
	const useWideScreen = () => {
		const isWideScreen = useMediaQuery({ minWidth: breakpoints.wideScreen });
		return isWideScreen ? true : false;
	};

	return {
		Mobile,
		Tablet,
		Desktop,
		WideScreen,
		isMobileOnly: useMobileOnly(),
		isMobile: useMobile(),
		isTablet: useTablet(),
		isDesktop: useDesktop(),
		isWideScreen: useWideScreen()
	};
};

export default useResponsive;
