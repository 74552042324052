import React from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

const CardContainerStyle = styled(Card)`
	border: none;
	padding: 24px 40px;
	background: ${({ theme }) => theme.color.palette.background.paper.portalbg};

	@media ${({ theme }) => theme.device.mobile} {
		margin-top: 0 !important;
		height: calc(100vh - 56px);
		padding: 0;
		overflow-x: hidden;
		border-radius: 0;
	}

	& > .header {
		font-weight: 600;
		border-bottom: 1px solid #878787;
	}
`;

const CardContainer = (props) => {
  return (
		<CardContainerStyle className={props.className} style={props.style}>
				{props.title ? (
					<Card.Title
						style={props.noMargin ? {} : { marginBottom: '30px' }}
					>
						{props.title}
					</Card.Title>
				) : null}
				{props.children}
		</CardContainerStyle>
  );
};

export default CardContainer;
