import React from 'react';
import logoMobile from 'assets/images/png/logoMobile.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLogo = styled(Link) `
	display: flex;
	justify-content: center;
	align-items: center;
    margin-right: 20px;
	& > img {
		max-height: 44px;
		@media ${({theme}) => theme.device.mobile} {
			max-height: 36px;
		}
	}

	@media ${({theme}) => theme.device.mobile} {
		margin-right: 10px;
	}
`;

const Logo = React.memo(({isMobile, customPortalLogo}) => {
	if(!customPortalLogo) return null;
	return (
		<StyledLogo to='/portal'>
			{isMobile
            	? <img src={customPortalLogo ? URL.createObjectURL(customPortalLogo) : logoMobile} alt='logo' />
				: <img src={URL.createObjectURL(customPortalLogo)} alt='logo' />
			}
		</StyledLogo>
	);
});

export default Logo;

