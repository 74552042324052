import { queryClient } from "index";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useSearchParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { parseFieldConfig } from "shared/utils/fields";
import { STORAGE_KEY } from "Dashboard/Pages/Orders/OrderList/OrderDisplaySettings";
import { millisecondsUntilJWTExpires, parseUser } from "shared/utils/misc";
import { Auth, axiosinstance } from "../shared/utils/api";
import { ENABLED_MODULES } from "shared/constants/fields";
import { getViewPermissions } from "shared/utils/permissions";
import { extractUIPrivilegeFromToken } from "shared/utils/rbac";

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
		case 'LOGGING_IN':
			return {
				...state,
				loggingIn: true,
				loginErrorMessage: '',
				validatingMFA: false
			};
		case 'VALIDATING_MFA':
			return {
				...state,
				validatingMFA: true,
			};
		case 'VALIDATING_MFA_FAILED':
			return {
				...state,
				validatingMFA: false,
				validatingMFAErrorMessage: payload

			};
		case 'VALIDATING_EMAIL_MFA':
			return {
				...state,
				validatingEmailMFA: true,
				emailMFAId: payload
			};
		case 'VALIDATING_AUTHENTICATOR_APP_MFA':
			return {
				...state,
				validatingAuthenticatorAppMFA: true,
			};	
		case 'LOGIN_SUCCESS':
			if (!localStorage.getItem('customerId')) {
				localStorage.setItem('customerId', payload.customerId);
			}
			return {
				...state,
				user: payload,
				isAuth: true,
				isInitializing: false,
				loggingIn: false,
				validatingEmailMFA: false,
				validatingAuthenticatorAppMFA: false,
				...payload,
			};
		case 'LOGIN_FAILED':
			return {
				...state,
				isAuth: false,
				isInitializing: false,
				loggingIn: false,
				loginErrorMessage: payload,
			};
		case 'LOGIN_PAGE_RESET':
			return {
				...state,
				loggingIn: false,
				loginErrorMessage: '',
			};
		case 'LOGOUT':
			localStorage.removeItem('customerId');
			return {
				...initialState,
				isInitializing: false,
				customPortalLogo: payload,
				preLoginSettings: state.preLoginSettings,
			};
		case 'SET_CUSTOMER_ID':
			localStorage.setItem('customerId', payload);
			const customer = state.customers.find(c => c.id === payload);
			let fieldConfigs = state.fieldConfigs;
			let division, divisionId;
			division = customer
				? customer.divisions.find(
						d =>
							d.id ===
							parseInt(sessionStorage.getItem('divisionId'))
				  )
				: null;
			if (!division && customer && customer.divisions) {
				division = customer.divisions[0];
			}
			if (division) {
				fieldConfigs = parseFieldConfig(division.field_config);
				sessionStorage.setItem('divisionId', division.id);
				sessionStorage.setItem(
					'fieldConfigs',
					JSON.stringify(fieldConfigs)
				);
				divisionId = division.id;
			}
			return {
				...state,
				customer,
				divisionId,
				fieldConfigs,
				customerId: payload,
			};
		case 'SET_DIVISION': {
			sessionStorage.setItem('divisionId', payload.id);
			return {
				...state,
				divisionId: payload.id,
			};
		}
		case 'SET_DIVISION_CREATE_ORDER': {
			sessionStorage.setItem('divisionId', payload);
			let divisionData = JSON.parse(sessionStorage.getItem('divisionData'));
			let fieldConfigs;
			divisionData.map(item => {
				if (item.id === payload) {
					let newFieldConfig = parseFieldConfig(item.field_config);
					sessionStorage.setItem('fieldConfigs', JSON.stringify(newFieldConfig));
					fieldConfigs = newFieldConfig;
				}
			})
			return {
				...state,
				fieldConfigs,
				divisionId: payload,
			};
		}
		case 'SET_DIVISION_ID': {
			let fieldConfigs;
			if (state.user) {
				for (let i = 0; i < state.user.portal_customers.length; i++) {
					let customer = state.user.portal_customers[i];
					for (let ii = 0; ii < customer.divisions.length; ii++) {
						let customerDivision = customer.divisions[ii];
						if (payload === customerDivision.id) {
							fieldConfigs = parseFieldConfig(
								customerDivision.field_config
							);
							sessionStorage.setItem(
								'fieldConfigs',
								JSON.stringify(fieldConfigs)
							);
							break;
						}
					}
				}
			}
			sessionStorage.setItem('divisionId', payload);

			return {
				...state,
				fieldConfigs,
				divisionId: payload,
			};
		}
		case 'SET_DIVISION_DATA': {
			return {
				...state,
				divisionData: payload
			}
		}
		case 'FIELD_CONFIG_LOADED':
			return {
				...state,
				fieldConfigs: payload,
				fieldConfigLoaded: true,
			};
		case 'ORDERS_LOADED':
			return {
				...state,
				orders: payload,
				ordersWidgetLoaded: true,
				isLoading: false,
			};
		case 'DASHBOARD_ORDERS_FETCH':
			return {
				...state,
				ordersWidgetLoaded: false,
				isLoading: true,
			};
		case 'DASHBOARD_ORDER_CLICKED':
			return {
				...state,
				selectedOrder: payload,
			};
		case 'START_LOADING':
			return {
				...state,
				isLoadingCreateOrder: true,
			};
		case 'END_LOADING':
			return {
				...state,
				isLoadingCreateOrder: false,
			};
		case 'ORDER_REQUEST_ENTER':
			return {
				...state,
				showFooter: false,
			};
		case 'ORDER_REQUEST_LEAVE':
			return {
				...state,
				showFooter: true,
			};
		case 'RESET_CREATE_ORDER':
			return {
				...state,
				createNewOrder: payload,
			};
		case 'NETWORK_ERROR':
			return {
				...state,
				isNetworkError: true,
				isInitializing: false,
			};
		case 'SET_CUSTOM_PORTAL_LOGO':
			return {
				...state,
				customPortalLogo: payload,
			};
		case 'BILLING_ENABLED':
			return {
				...state,
				billingEnabled: payload, // For backwards compatibility
				permissions: {
					...state.permissions,
					[ENABLED_MODULES.BILLING]: payload,
				},
			};
		case 'TURNAROUNDS_ENABLED':
			return {
				...state,
				turnaroundsEnabled: payload, // For backwards compatibility
				permissions: {
					...state.permissions,
					[ENABLED_MODULES.TURNAROUNDS]: payload,
				},
			};
		case 'SET_PRELOGIN_SETTINGS':
			return {
				...state,
				preLoginSettings: payload,
			};
		case 'SET_DISPLAY_SETTINGS':
			return {
				...state,
				displaySettings: {
					collapse: {
						...state.displaySettings.collapse,
						...payload.collapse,
					}
				},
			};
		case 'SET_PERMISSIONS':
			return {
				...state,
				permissions: {
					...state.permissions,
					...payload,
					...getViewPermissions(),
				}
			};
		case 'SET_UI_PRIVILEGES':
			// Privileges grouped by each Contact-Customer ID.
			return {
				...state,
				privileges: payload
			};
		default:
			return state;
  }
};

const initialState = {
	isAuth: false,
	user: {},
	loggingIn: false,
	loginErrorMessage: '',
	validatingMFAErrorMessage: '',
	validatingMFA: false,
	validatingEmailMFA: false,
	validatingAuthenticatorAppMFA: false,
	isInitializing: true,
	selectedOrder: {},
	isLoading: false,
	isLoadingCreateOrder: false,
	showFooter: true,
	isNetworkError: false,
	fieldConfigLoaded: false,
	customer: {},
	customerId: null,
	customerIds: [],
	customers: [],
	divisionId: null,
	divisionIds: [],
	divisions: [],
	divisionData: [],
	fieldConfigs: {},
	customPortalLogo: null,
	billingEnabled: true,
	createNewOrder: false,
	preLoginSettings: {},
	permissions: {},
	privileges: {},
	displaySettings: {
		collapse: {
			allOrders: false,
			allSamples: false,
			byOrders: [],
			bySamples: [],
		},
	},
};

const AppContext = createContext(initialState);
AppContext.displayName = 'AppContext';
export const useAppStore = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [appState, appStateDispatch] = useReducer(reducer, initialState);
  const { user, isAuth } = appState;

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const redirectTo = searchParams.get('redirect');

  const validateMFA = async (email, password) => {
	try{
		appStateDispatch({ type: "VALIDATING_MFA" });
		const {data:{mfa_type = null, email_mfa_id = null}} = await Auth.validateMFA(email, password);
		
		switch(mfa_type){
			case 'EMAIL':
				appStateDispatch({ type: "VALIDATING_EMAIL_MFA", payload: email_mfa_id });
				break;
			case 'AUTHENTICATOR_APP':
				appStateDispatch({ type: "VALIDATING_AUTHENTICATOR_APP_MFA" });
				break;
			default:
				login({ email, password });
				break;
		}

	} catch (err) {
		console.error(err);
		appStateDispatch({
			type: "VALIDATING_MFA_FAILED",
			payload: err?.data.message|| "Something went wrong when validating MFA.",
		});
	}
  }
  const login = async (options = {}) => {
		if (isEmpty(options)) throw new Error('Invalid login credentials');
		appStateDispatch({ type: "LOGGING_IN" });

		const { email, password, authCode } = options;
		const authFn = () =>
      authCode ? Auth.samlLogin(authCode) : Auth.login(email, password);

    try {
      const {
        data: { access_token, csrf_token, user },
      } = await authFn();
      localStorage.setItem("csrfToken", csrf_token);
      axiosinstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;

			setUIPrivileges(access_token);
      setUser(user);

			NotificationManager.listNotify.forEach((n) =>
        NotificationManager.remove({ id: n.id })
      );

      setTimeout(() => {
        refreshToken();
      }, millisecondsUntilJWTExpires(access_token));
    } catch (err) {
      console.error(err);
      appStateDispatch({
        type: "LOGIN_FAILED",
        payload: err?.data?.message || "Invalid email or password",
      });
    }
  };

  const logout = async () => {
    try {
      await Auth.logout();
    } catch (err) {
      // pass
    }

		const displaySettings = localStorage.getItem(STORAGE_KEY);	
    localStorage.clear();
		localStorage.setItem(STORAGE_KEY, displaySettings);

    appStateDispatch({ type: "LOGOUT" });
    queryClient.invalidateQueries();
	setPortalLogo();
  };

  const setDivision = useCallback(async () => {
    appStateDispatch({ type: "SET_DIVISION_ID", payload: 3 });
  }, []);

  const setFields = useCallback(() => {
		const { BILLING, TURNAROUNDS } = ENABLED_MODULES;
		return axiosinstance.get(`/v1/fieldconfig`).then(({ data }) => {
			let fields = parseFieldConfig(data);
			sessionStorage.setItem('fieldConfigs', JSON.stringify(fields));
			localStorage.setItem(
				'portalView',
				JSON.stringify(fields.PORTAL_VIEW)
			);
			sessionStorage.setItem(
				'entityDisplayNames',
				JSON.stringify(fields.ENTITY_DISPLAY_NAMES)
			);

			appStateDispatch({ type: 'SET_PERMISSIONS', payload: fields.ENABLED_MODULES });
			appStateDispatch({ type: 'FIELD_CONFIG_LOADED', payload: fields });

			// These dispatches are kept for backwards compatibility reasons
			appStateDispatch({ type: BILLING, payload: fields.ENABLED_MODULES[BILLING] });
			appStateDispatch({ type: TURNAROUNDS, payload: fields.ENABLED_MODULES[TURNAROUNDS] });
		});
  }, [appState.divisionId]);

  const setUser = useCallback(
    async (user) => {
			try {
				const payload = parseUser(user);
				await setDivisionData();
				sessionStorage.setItem('divisionId', payload.divisionId);
				await setFields();
				appStateDispatch({
					type: 'LOGIN_SUCCESS',
					payload: { user, ...payload },
				});
			} catch (e) {
				appStateDispatch({
					type: 'LOGIN_FAILED',
					payload: 'Error parsing user data. Please try again.'
				});	
			}
    },
    [setFields]
  );

  const setPortalLogo = useCallback(async () => {
		await axiosinstance
			.get('/v1/logo', { responseType: 'blob' })
			.then(res => {
				appStateDispatch({
					type: 'SET_CUSTOM_PORTAL_LOGO',
					payload: res.data,
				});
			})
			.catch(() => {});
  }, [])

  const refreshToken = useCallback(async () => {
    try {
      if (localStorage.getItem("csrfToken")) {
        const {
          data: { access_token, user },
        } = await Auth.refreshToken();
        axiosinstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;

				setUIPrivileges(access_token);
				setUser(user);

        setTimeout(() => {
          refreshToken();
        }, millisecondsUntilJWTExpires(access_token));
      } else {
        logout();
      }
    } catch (err) {
      if (err) {
        if (appState.isAuth) {
          logout();
        }
        appStateDispatch({ type: "LOGOUT" });
      } else {
        appStateDispatch({ type: "NETWORK_ERROR" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser]);

	const setDivisionData =  useCallback(async () => {
		await Auth.divisionData().then(res => {
			let response = res.data.data;
			response.map(item => item.field_config = parseFieldConfig(item.field_config));
			appStateDispatch({type: "SET_DIVISION_DATA", payload: response});
			sessionStorage.setItem("divisionData", JSON.stringify(response));
		});
	});

	const setUIPrivileges = (jwt) =>
    appStateDispatch({
      type: 'SET_UI_PRIVILEGES',
      payload: extractUIPrivilegeFromToken(jwt),
    });

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
		if (isAuth && redirectTo && redirectTo.includes('/portal/')) {
			return navigate(redirectTo);
		}
  }, [isAuth, redirectTo]);

  return (
    <AppContext.Provider
      value={{ appState, appStateDispatch, login, logout, user, refreshToken, setPortalLogo, validateMFA }}
    >
      {children}
    </AppContext.Provider>
  );
};
