import { Modal, Spinner } from "react-bootstrap";
import { Button } from "../Button";

const QBModal = ({ show, handleClose, title, loading, children }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading && (
            <Spinner
              animation="border"
              style={{ width: "10rem", height: "10rem" }}
            />
          )}
          {!loading && children}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
          <Button outlined onClick={handleClose}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default QBModal;
