import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { axiosinstance } from 'shared/utils/api';
import { useOrderRequest } from 'context/orderRequest';
import { DEFAULT_PAGE_SIZE } from 'shared/constants/misc';
import { EntityType, QueryKeys } from 'shared/constants/entityTypes';
import useAccessControl from '../misc/useAccessControl';
import policies from 'shared/configs/rbac/accessPolicies';

export function fetchPortalLogo() {
	return axiosinstance
		.get(`/v1/logo`, { responseType: 'blob' })
		.then(res => res.data);
}

export const useMetrics = () => {
	const fetchData = () =>
		axiosinstance.get(`/v1/dashboard/metrics`).then(res => res.data);
	const { data, isLoading, error, refetch, isFetching } = useQuery(
		'metrics',
		fetchData
	);

	return [data, { isLoading, error, refetch, isFetching }];
};

export const useBillingMetrics = () => {
	const fetchData = () =>
		axiosinstance.get(`/v1/billing`).then(res => res.data);
	const { data, ...queryResult } = useQuery(
		['metrics', 'billing'],
		fetchData
	);

	return {
		data: data?.data || {},
		...queryResult,
	};
};

export function useRecentReports({ daysDelta }) {
  const baseKey = 'recentReports';
  const baseUrl = '/v1/reports/recent';
  const url =
    typeof daysDelta === 'boolean'
      ? baseUrl
      : `${baseUrl}/${parseInt(daysDelta)}`;

  function mapAttachmentsToReports(data = {}) {
    const { reports = [], attachments = {} } = data;
    return reports.map(({ order, sample, test, ...report }) => ({
      entity: order || sample || test || {},
      entityType: !!order
        ? EntityType.ORDER
        : !!sample
        ? EntityType.SAMPLE
        : EntityType.TEST,
      attachments: attachments[report.id],
      report,
    }));
  }

  const canViewReports = useAccessControl(policies.route.reports);
  const { data = [], ...query } = useQuery(
    [baseKey],
    async () => {
      const res = await axiosinstance.get(url);
      return mapAttachmentsToReports(res.data?.data);
    },
    { enabled: canViewReports }
  );

  return { data, ...query };
}

export function useTurnarounds(pageSize = 100) {
	const baseKey = QueryKeys.TURNAROUND; 
  const url = `/v1/${baseKey}`;
	const { normalizers } = useOrderRequest();

	const fetchTurnarounds = async () => {
    const { data: res } = await axiosinstance.get(`${url}?page_size=${pageSize}`);
    return res?.data || [];
  };

  const { data = [], ...queryResult } = useQuery([baseKey], fetchTurnarounds, {
		onSuccess: normalizers.turnarounds
	});
  return { data, ...queryResult };
}

export const useReportPDFbyId = (type, id) => {
	const fetchData = () =>
		id
			? axiosinstance
					.get(`/v1/${type}/${id}/report/pdf`, {
						responseType: 'arraybuffer',
					})
					.then(res => res.data)
			: null;

	const canViewReports = useAccessControl(policies.ui.reportDownloadBtn);
	const { data, isLoading, error, refetch, isFetching } = useQuery(
		['reportPdf', type, parseInt(id)],
		fetchData,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		}
	);

	return [
    data,
    {
      error,
      isLoading,
      isFetching,
      refetch: () => canViewReports && refetch(),
    },
  ];
};

export function useRequestParams({
  page = 1,
  search = '',
  pageSize: page_size = DEFAULT_PAGE_SIZE,
}) {
  const params = useMemo(
    () => ({
      page,
			page_size,
      ...(Boolean(search) && { search }),
    }),
    [page, search, page_size]
  );
  return params;
}

export function usePortalLogo() {
	const { data } = useQuery(
		['logo'],
		fetchPortalLogo
	);
	return data;
}