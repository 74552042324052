import React, { useReducer, createContext, useContext } from "react";
import reducer from './reducers/reducer.js';
import filters from './reducers/filters.js';
import { ORDERS_STATE } from 'shared/constants/statuses.js';

function useReducerWithThunk(reducer, initialState) {
	const [state, dispatch] = useReducer(reducer, initialState);
	let customDispatch = React.useCallback(
		action => {
			if (typeof action === 'function') {
				action(customDispatch, state);
			} else {
				dispatch(action);
			}
		},
		[dispatch, state]
	);
	return [state, customDispatch];
}

const combineReducers = (...reducers) => 
  (state = initialState, action) => {
    for(let i=0;i<reducers.length;i++) 
      state = reducers[i](state, action)
    return state;
}

export const initialState = {
	orders: [],
	filters: {
		orders: {
			statuses: [],
			date: {
				start: '',
				end: '',
			},
			custom: [],
		},
		samples: {
			accessioningTypes: [],
			sourceIds: [],
			searchById: '',
			custom: [],
		},
		tests: {
			statuses: [],
			assays: [],
			custom: {},
		},
		conditions: [ORDERS_STATE[0].name],
		mainSearch: '',
	},
    invoiceFilter: {
        status: [],
        search: {
            searchText: '',
            betweenSearchText: '',
            searchLogic: ''
        },
		orderId: []
    }
};

const rootReducer = combineReducers(reducer, filters);

export const DashboardContext = createContext(initialState);
export const useDashboardStore = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(rootReducer, initialState);

  const store = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
		<DashboardContext.Provider
			value={{ state: store.state, dispatch: store.dispatch }}
		>
      {children}
    </DashboardContext.Provider>
  );
};



