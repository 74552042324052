export const DEFAULT_PAGE_SIZE = 20;

export const PRODUCT_TOURS = {
  SAMPLES_BULK_UPLOAD: 'SAMPLES_BULK_UPLOAD',
};

export const QUOTATION_STATE = {
  SENT: 'SENT',
  VIEWED: 'VIEWED',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
};

export const QUOTATION_PENDING_STATE = [
  QUOTATION_STATE.SENT,
  QUOTATION_STATE.VIEWED,
];

export const QUOTATION_DISPLAY_FILTERS = [
  {
    name: 'Accepted & Active',
    params: {
      statuses: QUOTATION_STATE.ACCEPTED,
      expired: false,
    },
  },
  {
    name: 'Pending Acceptance',
    params: {
      statuses: QUOTATION_PENDING_STATE,
    },
  },
  {
    name: 'Accepted & Expired',
    params: {
      statuses: QUOTATION_STATE.ACCEPTED,
      expired: true,
    },
  },
];

export const JOB_STATE = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const EXPORT_STATE = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  DOWNLOADED: 'DOWNLOADED',
  SUCCESS: 'EXPORT_SUCCESS',
  FAILED: 'EXPORT_FAILED',
};

export const HANDSONTABLE_LICENSE_KEY = 'OTA3MjctMzEyMDUtNjAxODEtZWQ1MmItYTQ2NDc=';

export const JSPREADSHEET_LICENSE_KEY = 'TmpkalpUaG1PRGt3TURWaE1ERTFZamN4WkRCa04yVmtNelV4WVdWa1pEQXdNbVppTlRBd1l6Y3pNelZoTWpnd09HVmtOR0l5TmprMFpXSTFOVGMyTkdWaE9HVXdaak15WVdKaU1XSmlZV00xWVdJeU1tSmhPVGczTnpOa09XSTBNMk5pTlRka09UTTBZMlprWlRObU1UQmlPREF4T0RJeFpqVXhZVGMxTVRJc1pYbEtkVmxYTVd4SmFtOXBWVlZLYkdKdFRtOUpSV3gxV1hrMGFVeERTbXRaV0ZKc1NXcHZlRTU2U1RWT2Vra3dUa1JCZDB4RFNtdGlNakZvWVZjMGFVOXNjMmxqVjBwc1ltMU9iMHh0Tld4a1EwbHpTV3BGZVU1NU5IZE1ha0YxVFZOSmMwbHVaR3haYVVselNXMTRkbGt5Um5OaFJ6bDZaRU5LWkV4RFNuZGlSMFoxU1dwdmFVMTZUV2xNUTBwNldUSTVkMXBUU1RaWGVVb3lUbmxKYzBsdVdUUkphWGRwWkdwcmFVeERTakpOVkVGcFRFTktiV0l6U25Sa1YzaG9TV2wzYVZwdE9YbGlXRTFwVEVOS2VWcFhOV3RhV0VscFRFTktkMWxZU25wYVdFbHBURU5LY0dKWVFuWmpibEpzWTJsSmMwbHVXbWhpUjJ4cldWaFNjR0l5TlhwSmFYZHBXVEk1ZEdKWFZuVmtTRTFwVEVOS2VscFhSbmxaTW1kcFRFTkthbUZIUm5sa1NFMXBXRmd3UFE9PQ==';

export const COLOR_MAP = {
  header_bg_color: '#f9f9f9',
  header_font_color: '#337ab7',
  card_accent_color: '#24324c',
  card_accent_font_1_color: '#d4edff',
  card_accent_font_2_color: '#92d1ff99',
  link_color: '#337ab7',
  body_bg_color: '#f0f8ff',
  test_name_bg_color: '#f7fbff',
  gradient_bg_color_1: '#2a86c7',
  gradient_bg_color_2: '#2f6ab4',
  gradient_font_color: '#ffffff',
  button_bg_color: '#337ab7',
  button_font_color: '#f9f9f9',
};
