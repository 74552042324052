import styled from 'styled-components';
const { Spinner } = require("react-bootstrap");

const StyledSpinner = styled(Spinner) `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & .spinner-border {
    border-top-color: ${props => props.theme.color.palette.text.main};
    border-top-style: solid;
    border-top-width: 0.15em;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 0.15em;
    border-bottom-color: ${props => props.theme.color.palette.text.main};
    border-bottom-style: solid;
    border-bottom-width: 0.15em;
    border-left-color: ${props => props.theme.color.palette.text.main};
    border-left-style: solid;
    border-left-width: 0.15em;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
  }
`

const Loading = ({
	fullScreen,
	size = 'lg',
	dim = false,
	style = {},
}) => {
	let width, height;
	switch (size) {
		case 'lg':
			width = '10rem';
			height = '10rem';
			break;
		case 'md':
			width = '5rem';
			height = '5rem';
			break;
		case 'sm':
			width = '3rem';
			height = '3rem';
			break;
		case 'xs':
			width = '1.5rem';
			height = '1.5rem';
			break;
		default:
			width = '1rem';
			height = '1rem';
	}

	return (
		<div
			style={{
				height: fullScreen ? '100vh' : '100%',
				width: fullScreen ? '100vw' : '100%',
				position: fullScreen ? 'fixed' : 'absolute',
				zIndex: 1021,
				left: 0,
				top: 0,
				borderRadius: 5,
				background: dim ? 'rgb(0, 0, 0, 0.05)' : 'none',
				...style,
			}}
		>
			<StyledSpinner>
				<Spinner animation='border' style={{ width, height }} />
			</StyledSpinner>
		</div>
	);
};

export default Loading;
