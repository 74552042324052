import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useResponsive from 'shared/hooks/misc/useResponsive';
import { useBillingMetrics } from 'shared/hooks/api/misc';
import { noop } from 'shared/utils/misc';
import { useAppStore } from 'App/store';
import QBenchCurrency from 'shared/components/QBenchCurrency';

const Wrapper = styled(Link)`
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 2px;
  border-color: ${props => props.theme.color.palette.text.headerText}; 
  &, &:hover {
    ${props => props.$isMobile
      ? `color: ${props.theme.color.palette.grey[600]}`
      : `color: ${props.theme.color.palette.text.headerText}`
    }
  }
`;

export default function OutstandingBalance({ toggleModal = noop }) {
  const { isMobile } = useResponsive();
  const { appState: { billingEnabled } } = useAppStore();
  const { data: { unpaid_balance } } = useBillingMetrics();

  const toPath = {
    pathname: '/portal/invoices',
    search: '?statuses=UNPAID'
  };

  if (!billingEnabled) return null;

  if (isMobile) return (
    <Wrapper $isMobile={isMobile} className="d-block lh-1 my-3 mb-4 text-center text-decoration-none" to={toPath} onClick={toggleModal}> 
      <span>Balance: <QBenchCurrency quantity={unpaid_balance ?? 0} /></span>
    </Wrapper>
  );


  return (
    <Wrapper $isMobile={isMobile} className="d-none px-4 lh-1 border-start d-lg-flex align-items-center text-decoration-none" to={toPath}>
      <span className="d-none d-xl-inline">Balance:&nbsp;</span>
      <span><QBenchCurrency quantity={unpaid_balance ?? 0} /></span>
    </Wrapper>
  );
}
