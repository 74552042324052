import { isNil } from 'lodash';
import { useAppStore } from 'App/store';

export default function usePermissionCheck(requiredPermission) {
  const { appState: { permissions } } = useAppStore();

  let isSufficient = false;

  if (isNil(requiredPermission)) {
    isSufficient = true;
  } else if (requiredPermission in permissions) {
    isSufficient = permissions[requiredPermission];
  }

  return isSufficient;
}
