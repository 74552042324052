import camelCase from 'lodash/camelCase';
import { mapKeysDeep } from './misc';

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toCamelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');
}

export function toTitleCase(str) {
  return (str || '').replace(/\w\S*/g, ucfirst);
}

export function camelToSentenceCase(str) {
  return (str || '')
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1');
}

/**
 * @param {Object.<string, any>} obj
 */
export function camelize(obj) {
  const fn = (_, k) => camelCase(k);
  return Array.isArray(obj)
    ? obj.map((v) => mapKeysDeep(v, fn))
    : mapKeysDeep(obj, fn);
}

export function pluralize(str) {
  const word = str.toLowerCase();
  const rules = {
    x: (w) => w + 'es',
    s: (w) => w + 'es',
    ch: (w) => w + 'es',
    sh: (w) => w + 'es',
    o: (w) => w.slice(0, -1) + 'ves',
    y: (w) => w.slice(0, -1) + 'ies',
  };
  const lastChar = word.at(-1);
  return rules[lastChar]?.(word) || `${word}s`;
}

export function isValidUUID4(uuid) {
  const uuid4Pattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuid4Pattern.test(uuid);
}
