export const print = (color) =>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 8H5.5C3.84 8 2.5 9.34 2.5 11V17H6.5V21H18.5V17H22.5V11C22.5 9.34 21.16 8 19.5 8ZM16.5 19H8.5V14H16.5V19ZM19.5 12C18.95 12 18.5 11.55 18.5 11C18.5 10.45 18.95 10 19.5 10C20.05 10 20.5 10.45 20.5 11C20.5 11.55 20.05 12 19.5 12ZM18.5 3H6.5V7H18.5V3Z" fill={color} />
    </svg>

export const invoiceIcon = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 17H6V15H18V17ZM18 13H6V11H18V13ZM18 9H6V7H18V9ZM3 22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22Z" fill={color} />
    </svg>

export const chain_of_custody_icon = (color) =>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.1654 4H13.9167C11.4875 4 9.5 6.025 9.5 8.5V44.5C9.5 46.975 11.4654 49 13.8946 49H40.4167C42.8458 49 44.8333 46.975 44.8333 44.5V15.8881L33.1654 4ZM41.9988 44.1119C41.9988 45.2165 41.1033 46.1119 39.9988 46.1119H14.3346C13.23 46.1119 12.3346 45.2165 12.3346 44.1119V8.88806C12.3346 7.78349 13.23 6.88806 14.3346 6.88806H32.5392V16.5261H41.9988V44.1119Z" fill={color} />
        <rect x="24.6665" y="16.082" width="6.51726" height="9.71124" rx="2" stroke={color} strokeWidth="2" />
        <rect x="24.6665" y="29.9226" width="6.51726" height="9.71124" rx="2" stroke={color} strokeWidth="2" />
        <rect x="26.8604" y="21.47" width="2.12932" height="11.7112" rx="1.06466" fill={color} stroke="white" strokeWidth="0.5" />
    </svg>


export const labels_icon = (color) =>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.1654 4H13.9167C11.4875 4 9.5 6.025 9.5 8.5V44.5C9.5 46.975 11.4654 49 13.8946 49H40.4167C42.8458 49 44.8333 46.975 44.8333 44.5V15.8881L33.1654 4ZM41.9988 44.1119C41.9988 45.2165 41.1033 46.1119 39.9988 46.1119H14.3346C13.23 46.1119 12.3346 45.2165 12.3346 44.1119V8.88806C12.3346 7.78349 13.23 6.88806 14.3346 6.88806H32.5392V16.5261H41.9988V44.1119Z" fill={color} />
        <rect x="16.5" y="19" width="1.04762" height="15.931" fill={color} />
        <rect x="23.8333" y="19" width="1.04762" height="15.931" fill={color} />
        <rect x="37.4524" y="19" width="1.04762" height="15.931" fill={color} />
        <rect x="28.0237" y="19" width="1.04762" height="15.931" fill={color} />
        <rect x="33.262" y="19" width="1.04762" height="15.931" fill={color} />
        <rect x="19.6431" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="33.262" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="18.5952" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="32.2144" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="22.7856" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="36.4048" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="25.9287" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="31.1667" y="19" width="0.523809" height="15.931" fill={color} />
        <rect x="20.6907" y="19" width="0.261905" height="15.931" fill={color} />
        <rect x="34.3093" y="19" width="0.261905" height="15.931" fill={color} />
        <rect x="28.0237" y="19" width="0.261905" height="15.931" fill={color} />
        <rect x="33.262" y="19" width="0.261905" height="15.931" fill={color} />
        <rect x="26.9763" y="19" width="0.261905" height="15.931" fill={color} />
        <rect x="32.2144" y="19" width="0.261905" height="15.931" fill={color} />
    </svg>


export const shipping_manifest_icon = (color) =>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.1654 4H13.9167C11.4875 4 9.5 6.025 9.5 8.5V44.5C9.5 46.975 11.4654 49 13.8946 49H40.4167C42.8458 49 44.8333 46.975 44.8333 44.5V15.8881L33.1654 4ZM41.9988 44.1119C41.9988 45.2165 41.1033 46.1119 39.9988 46.1119H14.3346C13.23 46.1119 12.3346 45.2165 12.3346 44.1119V8.88806C12.3346 7.78349 13.23 6.88806 14.3346 6.88806H32.5392V16.5261H41.9988V44.1119Z" fill={color} />
        <path d="M34.8332 23.3334H32.0832V19.6667H19.2498C18.2415 19.6667 17.4165 20.4917 17.4165 21.5001V31.5834H19.2498C19.2498 33.1051 20.4782 34.3334 21.9998 34.3334C23.5215 34.3334 24.7498 33.1051 24.7498 31.5834H30.2498C30.2498 33.1051 31.4782 34.3334 32.9998 34.3334C34.5215 34.3334 35.7498 33.1051 35.7498 31.5834H37.5832V27.0001L34.8332 23.3334ZM34.3748 24.7084L36.1715 27.0001H32.0832V24.7084H34.3748ZM21.9998 32.5001C21.4957 32.5001 21.0832 32.0876 21.0832 31.5834C21.0832 31.0792 21.4957 30.6667 21.9998 30.6667C22.504 30.6667 22.9165 31.0792 22.9165 31.5834C22.9165 32.0876 22.504 32.5001 21.9998 32.5001ZM24.0348 29.7501C23.5307 29.1909 22.8157 28.8334 21.9998 28.8334C21.184 28.8334 20.469 29.1909 19.9648 29.7501H19.2498V21.5001H30.2498V29.7501H24.0348ZM32.9998 32.5001C32.4957 32.5001 32.0832 32.0876 32.0832 31.5834C32.0832 31.0792 32.4957 30.6667 32.9998 30.6667C33.504 30.6667 33.9165 31.0792 33.9165 31.5834C33.9165 32.0876 33.504 32.5001 32.9998 32.5001Z" fill={color} />
    </svg>

export const document = (color) =>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.1654 4H13.9167C11.4875 4 9.5 6.025 9.5 8.5V44.5C9.5 46.975 11.4654 49 13.8946 49H40.4167C42.8458 49 44.8333 46.975 44.8333 44.5V15.8881L33.1654 4ZM41.9988 44.1119C41.9988 45.2165 41.1033 46.1119 39.9988 46.1119H14.3346C13.23 46.1119 12.3346 45.2165 12.3346 44.1119V8.88806C12.3346 7.78349 13.23 6.88806 14.3346 6.88806H32.5392V16.5261H41.9988V44.1119Z" fill={color} />
        <path d="M18.3926 27.4648H18.9902C19.5488 27.4648 19.9668 27.3555 20.2441 27.1367C20.5215 26.9141 20.6602 26.5918 20.6602 26.1699C20.6602 25.7441 20.543 25.4297 20.3086 25.2266C20.0781 25.0234 19.7148 24.9219 19.2188 24.9219H18.3926V27.4648ZM22.4941 26.1055C22.4941 27.0273 22.2051 27.7324 21.627 28.2207C21.0527 28.709 20.2344 28.9531 19.1719 28.9531H18.3926V32H16.5762V23.4336H19.3125C20.3516 23.4336 21.1406 23.6582 21.6797 24.1074C22.2227 24.5527 22.4941 25.2188 22.4941 26.1055ZM31.2188 27.6348C31.2188 29.0449 30.8164 30.125 30.0117 30.875C29.2109 31.625 28.0527 32 26.5371 32H24.1113V23.4336H26.8008C28.1992 23.4336 29.2852 23.8027 30.0586 24.541C30.832 25.2793 31.2188 26.3105 31.2188 27.6348ZM29.332 27.6816C29.332 25.8418 28.5195 24.9219 26.8945 24.9219H25.9277V30.5H26.707C28.457 30.5 29.332 29.5605 29.332 27.6816ZM34.7812 32H32.9941V23.4336H37.9043V24.9219H34.7812V27.1309H37.6875V28.6133H34.7812V32Z" fill={color} />
    </svg>

export const upload = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM14 13V17H10V13H7L12 8L17 13H14Z" fill={color} />
    </svg>

export const plus = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={color} />
    </svg>

export const copy = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.3157 4H6.47368C5.66316 4 5 4.66316 5 5.47368V15.7894H6.47368V5.47368H15.3157V4ZM17.5264 6.94735H9.42121C8.61069 6.94735 7.94753 7.6105 7.94753 8.42102V18.7368C7.94753 19.5473 8.61069 20.2104 9.42121 20.2104H17.5264C18.337 20.2104 19.0001 19.5473 19.0001 18.7368V8.42102C19.0001 7.6105 18.337 6.94735 17.5264 6.94735ZM17.5263 18.7368H9.42109V8.42106H17.5263V18.7368Z" fill={color} />
    </svg>

export const pencil = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.6602 4.25774L19.7399 6.33745C20.0865 6.68407 20.0865 7.24399 19.7399 7.59061L18.1135 9.21705L14.7806 5.88418L16.4071 4.25774C16.5759 4.08888 16.7981 4 17.0292 4C17.2603 4 17.4825 4.07999 17.6602 4.25774ZM4 16.6649V19.9978H7.33287L17.1626 10.168L13.8297 6.83516L4 16.6649ZM6.5952 18.2203H5.77753V17.4026L13.8297 9.35039L14.6474 10.168L6.5952 18.2203Z" fill={color} />
    </svg>

export const deleteIcon = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4286 9.14286V17.7143H8.57143V9.14286H15.4286ZM14.1429 4H9.85714L9 4.85714H6V6.57143H18V4.85714H15L14.1429 4ZM17.1429 7.42857H6.85714V17.7143C6.85714 18.6571 7.62857 19.4286 8.57143 19.4286H15.4286C16.3714 19.4286 17.1429 18.6571 17.1429 17.7143V7.42857Z" fill={color} />
    </svg>

export const download = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM17 13L12 18L7 13H10V9H14V13H17Z" fill={color} />
    </svg>

export const report = (color) =>
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 2.52981H11.82C11.4 1.43805 10.3 0.647461 9 0.647461C7.7 0.647461 6.6 1.43805 6.18 2.52981H2C0.9 2.52981 0 3.37687 0 4.41217V19.471C0 20.5063 0.9 21.3533 2 21.3533H16C17.1 21.3533 18 20.5063 18 19.471V4.41217C18 3.37687 17.1 2.52981 16 2.52981ZM9 2.52981C9.55 2.52981 10 2.95334 10 3.47099C10 3.98864 9.55 4.41217 9 4.41217C8.45 4.41217 8 3.98864 8 3.47099C8 2.95334 8.45 2.52981 9 2.52981ZM2 4.41217V19.471H16V4.41217H14V7.2357H4V4.41217H2Z" fill={color} />
    </svg>

export const hamburger = (color) =>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="8" width="40" height="4" fill={color} fillOpacity="0.8" />
        <rect x="2" y="20" width="40" height="4" fill={color} fillOpacity="0.8" />
        <rect x="2" y="32" width="40" height="4" fill={color} fillOpacity="0.8" />
    </svg>

export const attach = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 6V17.5C16.5 19.71 14.71 21.5 12.5 21.5C10.29 21.5 8.5 19.71 8.5 17.5V5C8.5 3.62 9.62 2.5 11 2.5C12.38 2.5 13.5 3.62 13.5 5V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6H10V15.5C10 16.88 11.12 18 12.5 18C13.88 18 15 16.88 15 15.5V5C15 2.79 13.21 1 11 1C8.79 1 7 2.79 7 5V17.5C7 20.54 9.46 23 12.5 23C15.54 23 18 20.54 18 17.5V6H16.5Z" fill={color} />
    </svg>
export const arrowForward = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 20.03L7.77 21.8L17.67 11.9L7.77 2L6 3.77L14.13 11.9L6 20.03H6Z" fill={color} />
    </svg>

export const arrowBack = (color) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 3.97L16.23 2.2L6.33 12.1L16.23 22L18 20.23L9.87 12.1L18 3.97H18Z" fill={color} />
    </svg>

export const warningMsgSvg = (color) =>
    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.94531 14.4449C3.94531 15.4025 3.44531 15.8813 2.44532 15.8813C1.44531 15.8813 0.945312 15.4025 0.945312 14.4449C0.945312 13.9886 1.07183 13.6319 1.32484 13.3748C1.57182 13.1242 1.94531 12.9989 2.44532 12.9989C3.44531 12.9989 3.94531 13.4809 3.94531 14.4449ZM1.07183 0.881256H3.82784V11.6589H1.07183V0.881256Z" fill={color}/>
    </svg>