import { useAppStore } from 'App/store';
import { verifyPolicy } from 'shared/utils/rbac';

/**
 * @typedef AccessControl
 * @prop {string[]} privilege
 * List of permissions to check based on the comparison method `all`, `any`, `not`.
 * @prop {boolean} [all] All `permissions` must exist as user privilege.
 * @prop {boolean} [any] At least one `permissions` must exist in user privilege.
 * @prop {boolean} [not] Inversion of `all` - User privileges must not contain all `permissions` listed.
 */

/**
 * Validates user privilege according to the access policy
 * specified in `policy`.
 *
 * The comparison method - `all`, `any`, or `not` - only affects permissions.
 *
 * ```js
 *
 * // Checks for either A or B.
 * const isAllowed = useAccessControl({
 *   privilege: ['A', 'B']
 * });
 *
 * // Checks for both A or B.
 * const isAllowed = useAccessControl({
 *   all: true,
 *   privilege: ['A', 'B']
 * });
 * ```
 * @param {AccessControl} policy
 */
export default function useAccessControl(policy) {
  const { appState: {
    customerId, privileges
  }} = useAppStore();
  const userPrivilege = privileges[customerId];
  const { privilege = [] } = policy || {};

  if (privilege === null) return true;

  if (!privilege.length)
    throw new Error(
      'Missing or invalid `policy` property in useAccessControl() hook or <AccessControl> component.'
    );

  return verifyPolicy(userPrivilege, policy);
}
