import styled from "styled-components";

const MainWrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	color: ${props => props.theme.color.palette.text.main};
	background-color: ${({ theme }) =>
		theme.color.palette.background.paper.pagebg};
	@media ${({ theme }) => theme.device.desktop} {
		align-items: center;
	}
	@media ${({ theme }) => theme.device.tablet} {
		align-items: center;
	}
`;

export default MainWrapper;
