import {createContext, useContext, useReducer} from "react";

const makeStore = (userReducer, initialState, key, init) => {
  const dispatchContext = createContext(undefined);
  dispatchContext.displayName = `${key}Dispatch`;
  const storeContext = createContext(undefined);
  storeContext.displayName = `${key}Context`;

  try {
    initialState = JSON.parse(sessionStorage.getItem(key)) || initialState
  } catch {};

  const reducer = (state, action) => {
    const newState = userReducer(state, action);
    sessionStorage.setItem(key, JSON.stringify(newState));
    return newState;
  }

  const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState, init);

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>
          {children}
        </storeContext.Provider>
      </dispatchContext.Provider>
    )
  }

  const useDispatch = () => {
    return useContext(dispatchContext);
  }
  const useStore = () => {
    return useContext(storeContext);
  }

  return [StoreProvider, useStore, useDispatch];
}

export default makeStore;