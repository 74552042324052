import React from 'react'
import styled from 'styled-components';

const StyledInput = styled.div `
    display: flex;
    border-radius: 4px;
    height: ${({height}) => height ? `${height}px` : '100%'};
    box-shadow: 0px 0px 1px 1px rgba(176, 176, 176, 0.7) inset;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: 400;
    align-items: center;
    border-radius: 4px;
    padding: ${props => props.clearIcon ? '6px 4px 6px 15px' : '6px 15px'};
    width: ${({width}) => width ? `${width}px` : '100%'};

    & > input{
        border: none;
        border-radius: 4px;
        width: 100%;
        color: ${props => props.theme.color.palette.text.dark};
        &:focus {
            outline: none;
        }
    }

    & .icon {
        &:hover {
            cursor: pointer;
            background: #dfdfdf;
            border-radius: 4px;
        }
    }
`
export const Input = ({
    value = '',
    onChange = () => {},
    placeholder,
    iconComponent,
    icon,
    clearIcon,
    style,
    onEnter = () => {},
    onIconClick = () => {},
    onClearIconClick = () => {},
    ...props
}) => {
    return (
        <StyledInput style={style} clearIcon={clearIcon}>
            <input
                value={value}
                onChange={onChange}
                onKeyDown={onEnter}
                placeholder={placeholder}
                {...props}
            />
            {(clearIcon && value) &&
                <div className='icon' id='inputSubmit' onClick={value = "", onClearIconClick}>
                    <img src={clearIcon} alt="search"/>
                </div>
            }
            {(iconComponent || icon) &&
                <div className='icon' id='inputSubmit' onClick={onIconClick}>
                    {iconComponent && iconComponent}
                    {!iconComponent && icon && <img src={icon} alt="search"/>}
                </div>
            }
        </StyledInput>
    )
}
