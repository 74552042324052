import styled from 'styled-components';

export const StyledDashboard = styled.div`
	height: calc(100vh - 56px);
	background: ${({ theme }) => theme.color.palette.background.paper.portalbg};
	overflow-y: auto;
	position: relative;

	& .wideScreen {
		height: 100%;

		@media ${({ theme }) => theme.device.wideScreen} {
			max-width: 1480px;
			margin: 0 auto;
		}
	}
`;
