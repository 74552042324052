import { schema } from 'normalizr';
import strategies from './strategies';
import { EntityType } from 'shared/constants/entityTypes';

const tag = new schema.Entity('tags');
const source = new schema.Entity('sources');
const project = new schema.Entity('projects');
const contact = new schema.Entity('contacts');
const customer = new schema.Entity('customers');
const turnaround = new schema.Entity('turnarounds');
const assayCategory = new schema.Entity('assayCategories');
const accessioningType = new schema.Entity('accessioningTypes');
const team = new schema.Entity('teams', {});
const panel = new schema.Entity('panels', {});

const assay = new schema.Entity('assays', {
  tags: [tag],
  team,
  category: assayCategory,
});

const order = new schema.Entity('orders', {
  project,
  turnaround,
  tags: [tag],
  last_updated_by: contact,
  customer_account: customer,
});

const test = new schema.Entity(
  'tests',
  {
    assay,
    panel,
    turnaround,
  },
  {
    processStrategy: strategies.process[EntityType.TEST],
  }
);

const sample = new schema.Entity('samples', {
  project,
  tags: [tag],
  tests: [test],
  order,
  source,
  accessioning_type: accessioningType,
});

// Adds sample-test circular references.
test.define({ sample });

const schemas = {
  order,
  orders: [order],
  sample,
  samples: [sample],
  tests: [test],
  assay,
  assays: [assay],
  turnarounds: [turnaround],
};

export default schemas;