const filters = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_FILTER_CONDITION: {
			return {
				...state,
				filters: {
					...state.filters,
					conditions: [payload],
				},
			};
		}
		case UPDATE_FILTER_ORDERS_STATUSES: {
			return {
				...state,
				filters: {
					...state.filters,
					orders: {
						...state.filters.orders,
						statuses: payload,
					},
				},
			};
		}
		case UPDATE_FILTERS_BY_ADVANCED_FILTERS: {
			return {
				...state,
				filters: {
					...state.filters,
					...payload,
				},
			};
		}
		case UPDATE_MAIN_SEARCH: {
			return {
				...state,
				filters: {
					...state.filters,
					mainSearch: payload,
				},
			};
		}
		case UPDATE_INVOICE_FILTER: {
			return {
				...state,
				invoiceFilter: {
					...state.invoiceFilter,
					...payload,
				},
			};
		}
		default:
			return state;
	}
};

export default filters;

const UPDATE_FILTER_CONDITION = 'UPDATE_FILTER_CONDITION';
const UPDATE_FILTER_ORDERS_STATUSES = 'UPDATE_FILTER_ORDERS_STATUSES';
const UPDATE_FILTERS_BY_ADVANCED_FILTERS = 'UPDATE_FILTERS_BY_ADVANCED_FILTERS';
const UPDATE_MAIN_SEARCH = 'UPDATE_MAIN_SEARCH';
const UPDATE_INVOICE_FILTER = 'UPDATE_INVOICE_FILTER';

export const updateFilterCondition = name => {
	return {
		type: UPDATE_FILTER_CONDITION,
		payload: name,
	};
};

export const updateFilterOrdersStatuses = status => async (dispatch, state) => {
	const self = state.filters.orders.statuses;

	const statuses = self.includes(status)
		? self.filter(o => o !== status)
		: [...self, status];

	await dispatch({
		type: UPDATE_FILTER_ORDERS_STATUSES,
		payload: statuses,
	});
};

export const updateByAdvancedFilter = filters => async dispatch => {
	await dispatch({
		type: UPDATE_FILTERS_BY_ADVANCED_FILTERS,
		payload: filters,
	});
};

export const updateMainSearch = value => {
	return {
		type: UPDATE_MAIN_SEARCH,
		payload: value,
	};
};

export const updateInvoiceFilter = value =>  async dispatch => {
	await dispatch({
		type: UPDATE_INVOICE_FILTER,
		payload: value,
	});
};