import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import useIsMobile from "shared/hooks/misc/useIsMobile";
import styled from "styled-components";

const ButtonStyle = styled.button`
  border: 2px solid ${({ theme }) => theme.ButtonContainedEnabled};
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	text-align: center;
	transition: background-color 0.5s, color 0.5s;
	font-size: 0.8em;
	height: fit-context;
	${({ noMargin }) => (noMargin ? 'margin: 0;' : 'margin: 4px 8px;')}
	min-width: 80px;
  text-decoration: none;

	&:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	}

	@media ${({ theme }) => theme.device.mobile} {
		padding: 4px 8px;
		font-size: 0.9em;
	}
	@media ${({ theme }) => theme.device.tablet} {
		padding: 8px 16px;
    width: 100%;
	}
	@media ${({ theme }) => theme.device.desktop} {
		padding: 4px 32px;
		line-height: 27px;
		${({ noMargin }) =>
			noMargin === 'true' ? 'margin: 0;' : 'margin: 0px 24px;'}
	}

	${({ size }) => {
		switch (size) {
			case 'small':
				return `
          font-size: 0.8em;
          padding: 4px 16px;
        `;
			case 'large':
				return `
          font-size: 1.2em;
        `;
			default:
				return;
		}
	}}

	${({ block }) =>
		block ? 'display: block; width: 100%;' : 'display: inline-block;'}

  ${({ contained, theme, disabled }) =>
		contained
			? `
    background: ${
		disabled ? theme.ButtonContainedDisabled : theme.ButtonContainedEnabled
	};
    border-color: ${
		disabled ? theme.ButtonContainedDisabled : theme.ButtonContainedEnabled
	};
    color: ${
		disabled ? theme.ButtonContainedTextDisabled : theme.ButtonContainedText
	};

    &:hover:enabled {
      background: ${theme.ButtonContainedHover};
      border-color: ${theme.ButtonContainedHover};
    }
    &:active:enabled {
        background: ${theme.ButtonContainedActive};
        border-color: ${theme.ButtonContainedActive};
    }
  `
			: ''}

  ${({ outlined, theme, disabled }) =>
		outlined
			? `
    background: ${
		disabled ? theme.ButtonOutlinedDisabled : theme.ButtonContainedText
	};
    border-color: ${
		disabled ? theme.ButtonOutlinedDisabled : theme.ButtonContainedEnabled
	};
    color: ${
		disabled
			? theme.ButtonContainedTextDisabled
			: theme.ButtonContainedEnabled
	};
    border: 2px solid ${theme.ButtonContainedEnabled};

    &:hover:enabled {
      background: ${theme.ButtonOutlinedHover};
    }
    &:active:enabled {
        background: ${theme.ButtonOutlinedActive};
    }
  `
			: ''}
`;

// `outlined` leaks to the DOM, causing this warning:
// "Received `true` for a non-boolean attribute `outlined`."
const ButtonTextStyle = styled(({ outlined, ...props }) => <Link {...props} />)`
  border: 2px solid transparent;
	border-radius: 4px;
	border: none;
	padding: 8px;
	font-style: normal;
	font-weight: 500;
	line-height: 27px;
	text-align: center;

	color: ${({ disabled, theme }) =>
		disabled
			? theme.ButtonContainedTextDisabled
			: theme.color.palette.text.link};

	&:hover {
		background: ${({ theme }) => theme.ButtonTextHover};
		border-color: ${({ theme }) => theme.ButtonTextHover};
		cursor: pointer;
	  text-decoration: none;

	}
	&:active:enabled {
		background: ${({ theme }) => theme.ButtonTextActive};
    border-color: ${({ theme }) => theme.ButtonTextActive};
	}
`;

export const Button = (props) => {
  if (props.text) {
    return <ButtonTextStyle {...props} text="true" />;
  } else {
    return <ButtonStyle {...props} />;
  }
};

export const ButtonWizardNext = ({
  children,
  onClick,
  style,
  size,
  disabled,
  ...props
}) => {
  const isMobile = useIsMobile();
  return (
    <Button
      contained
      disabled={disabled}
      size={size}
      block={isMobile ? 1 : 0}
      onClick={onClick}
      style={style}
      {...props}
    >
      {children}{" "}
      <FontAwesomeIcon
        icon={faAngleRight}
        style={{ marginLeft: 4, verticalAlign: "middle" }}
      />
    </Button>
  );
};
export const ButtonWizardPrevious = ({
  children,
  onClick,
  style,
  size,
  disabled,
  ...props
}) => {
  const isMobile = useIsMobile();
  return (
    <Button
      outlined
      disabled={disabled}
      size={size}
      block={isMobile ? 1 : 0}
      onClick={onClick}
      style={style}
      to="#"
      {...props}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        style={{ marginRight: 4, verticalAlign: "middle" }}
      />{" "}
      {children}
    </Button>
  );
};
