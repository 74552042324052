import { useEffect, useRef } from "react";
import { useDetectOutsideClick } from "shared/hooks/misc/useDetectOutsideClick";
import { DropdownMenuStyle } from "./Styles";

const DropdownMenu = ({ btn, children, modalOpen }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive((prev) => !prev);

  useEffect(() => {
    if (modalOpen) {
      setIsActive(false);
    }
  }, [modalOpen, setIsActive]);
  return (
    <DropdownMenuStyle>
      <div className="menu-container">
        <button
          onClick={onClick}
          className={`menu-trigger ${isActive ? "active" : "inactive"}`}
        >
          {btn}
        </button>
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? "active" : "inactive"}`}
        >
          {children}
        </nav>
      </div>
    </DropdownMenuStyle>
  );
};

export default DropdownMenu;
