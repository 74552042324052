import useAccessControl from 'shared/hooks/misc/useAccessControl';

/**
 * @typedef {Object} Props
 * @prop {React.ReactElement|null} [children]
 */
/**
 * Show or hide its `children` components via the useAccessControl() hook.
 * Uses the same options object as the hook.
 *
 * ```js
 *
 * // Checks for either A or B.
 * <AccessControl privilege={['A', 'B']}>
 *  {children}
 * </AccessControl>
 *
 * // Checks for both A and B.
 * <AccessControl all privilege={['A', 'B']}>
 *  {children}
 * </AccessControl>
 * ```
 * @param {import('shared/hooks/misc/useAccessControl').AccessControl & Props} props
 */
function AccessControl(props) {
  const { children, ...options } = props;
  if (!children)
    throw new Error(
      'You must provide a `children` component to <AccessControl> to show/hide.'
    );
  const isAllowed = useAccessControl(options);
  return isAllowed ? children : null;
}

export default AccessControl;
