import React from 'react';
import { Input } from 'shared/components/common/Input';
import searchIcon from 'assets/images/svg/search.svg';
import closeIcon from 'assets/images/svg/close-small.svg';
import { DashboardContext } from 'Dashboard/state';
import { updateMainSearch } from 'Dashboard/reducers/filters';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useFieldConfigsByEntity, useEntityDisplayName } from 'shared/hooks/misc/fields';
import { EntityType } from 'shared/constants/entityTypes';

const StyledSearch = styled.div`
	height: 32px;
	background-color: ${({theme}) => theme.color.palette.background.paper.defaultLight};
`

const Search = ({onChange=()=>{}, ...props}) => {
	const [orderSingular] = useEntityDisplayName(EntityType.ORDER);
    const [sampleSingular] = useEntityDisplayName(EntityType.SAMPLE);
    const [fieldConfig] = useFieldConfigsByEntity(EntityType.SAMPLE);
	
	const {
		state: {
			filters: { mainSearch },
		},
		dispatch,
	} = React.useContext(DashboardContext);
	const { pathname } = useLocation();
	const navigate = useNavigate();

    const [search, setSearch] = React.useState(mainSearch || '');
	React.useEffect(() => {
        if(typeof value === 'string') {
            setSearch(mainSearch)
        }
    }, [mainSearch])
	
	React.useEffect(() => {
        if(search === '' && mainSearch) {
			onEnter();
        }
    }, [search])

	const redirectToResultPage = React.useCallback(() => {
		const path = '/portal/orders';
		search && pathname !== path && navigate(path);
	}, [search, pathname, navigate]);

	const onEnter = React.useCallback(() => {
			dispatch(updateMainSearch(search));
			redirectToResultPage();
		},
		[dispatch, search, redirectToResultPage]
	);

	const handleOnChange = React.useCallback(
		e => {
			const value = e.target.value;
            setSearch(value);
            onChange(value);
		},
		[onChange]
	);

    const handleOnEnter = React.useCallback(
		(e) => {
			if (e.type === 'keydown' && e.key === 'Enter') {
				onEnter();
			} else if (e.type === 'click' && e.currentTarget.id === 'inputSubmit') {
				onEnter();
			}
		},
		[onEnter]
	);

	const onClearIconClick = React.useCallback(() => {
		if (search.length) {
			setSearch('');
			onChange('');			
		}
	}, [onChange]);

	const generatePlaceholder = () => {
		const baseCopy = `Search ${orderSingular} ID, ${sampleSingular} ID,`;
		const itemsToShow = Math.min(fieldConfig.length, 2);
		const separator = itemsToShow ? ' ' : '';
		const extras = fieldConfig.map(field => field.display_name || '').slice(0, itemsToShow).join(', ');
		return props.placeholder || `${baseCopy}${separator}${extras} or others`;
	}

	return (
		<StyledSearch className='searchWrapper'>
			<Input
				type='text'
				value={search}
				onChange={handleOnChange}
				onEnter={handleOnEnter}
				onIconClick={handleOnEnter}
				onClearIconClick={onClearIconClick}
				placeholder={generatePlaceholder()}
				icon={searchIcon}
				clearIcon={closeIcon}
				{...props}
			/>
		</StyledSearch>
	);
};

export default Search;