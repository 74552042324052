import jwt_decode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import intersection from 'lodash/intersection';

/**
 * Verify whether user privilege fulfill certain required permissions.
 * @param {string[]} userPrivilege
 * @param {string|string[]} requiredPrivilege
 * @param {'any'|'all'|'not'} comparison
 * @returns {boolean}
 */
function verifyPrivilege(
  userPrivilege = [],
  requiredPrivilege = [],
  comparison
) {
  let isSufficient = false;

  if (!requiredPrivilege || !requiredPrivilege.length) {
    isSufficient = true;
  } else {
    const validPermissions = intersection(userPrivilege, requiredPrivilege);

    switch (comparison) {
      case 'any':
        isSufficient = validPermissions.length >= 1; break;
      case 'all':
        isSufficient = validPermissions.length === requiredPrivilege.length; break;
      case 'not':
        isSufficient = validPermissions.length === 0; break;
      default:
        // Handle unknown comparison cases if needed
        isSufficient = false;
    }
  }

  return isSufficient;
}

/**
 * @typedef UserAccess
 * @prop {string} role Role ID from Auth0.
 */
/**
 * Validates user privilege according to the access policy
 * specified in `requiredPolicy`.
 * @param {string[]} userPrivilege Frontend privilege specific to user-customer.
 * @param {import('shared/hooks/misc/useAccessControl').AccessControl} requiredPolicy
 * @returns {boolean}
 */
export function verifyPolicy(userPrivilege, requiredPolicy) {
  const {
    privilege = [],
    all: checkAll = false,
    not: shouldInvert = false,
  } = requiredPolicy;

  if (isEmpty(privilege) || isEmpty(userPrivilege)) return false;

  // Check privilege
  const comparison = shouldInvert ? 'not' : checkAll ? 'all' : 'any';
  const privilegeSufficient = verifyPrivilege(
    userPrivilege,
    privilege,
    comparison // If privilege comparison is not provided, default to 'any'.
  );

  return privilegeSufficient;
}

/**
 * Retrieves a list of permissions specified as a custom claim in the user's access token.
 * @param {string} jwt
 * @returns {(Record<[key: number], string[]> | Record<number, never>)}
 */
export function extractUIPrivilegeFromToken(jwt) {
  // @ts-ignore
  const { permissions } = jwt_decode(jwt);

  if (isEmpty(permissions)) return {};

  return mapValues(
    // Group privilege by customer ID.
    groupBy(permissions, (permission) => permission.split(':').at(-1)),
    // Then, remove the customer IDs from the identifiers.
    (privileges) =>
      privileges.map((privilege) => privilege.split(':').slice(0, 2).join(':'))
  );
}
