export const EntityType = {
  ORDER: 'ORDER',
  ORDER_REQUEST: 'ORDER_REQUEST',
  SAMPLE: 'SAMPLE',
  TEST: 'TEST',
  SOURCE: 'SOURCE',
  PROJECT: 'PROJECT',
  BATCH: 'BATCH',
  ASSAY: 'ASSAY',
  PANEL: 'PANEL',
  PATIENT: 'PATIENT',
  CUSTOMER: 'CUSTOMER',
  SUPPLIER: 'SUPPLIER',
  CONTACT: 'CONTACT',
  LOCATION: 'LOCATION',
  ACCESSIONING_TYPE: 'ACCESSIONING_TYPE',
  EQUIPMENT: 'EQUIPMENT',
  INVENTORY_ITEM: 'INVENTORY_ITEM',
  INVENTORY_STOCK: 'INVENTORY_STOCK',
  USER: 'USER',
  WORKSHEET: 'WORKSHEET',
  PROTOCOL: 'PROTOCOL',
  GENERIC_LOOKUP: 'GENERIC_LOOKUP',
  TEAM: 'TEAM',
  SCHEDULE: 'SCHEDULE',
  TURNAROUND: 'TURNAROUND',
  INVOICE: 'INVOICE',
  ISSUE: 'ISSUE',
  QUOTATION: 'QUOTATION',
  QUOTATION_ITEM: 'QUOTATION_ITEM',
  STATUSES: 'STATUSES',
  REPORT: 'REPORT',
}

export const PrintDocsType = {
	CHAIN_OF_CUSTODY: 'chain of custody',
	LABELS: 'labels',
	SHIPPING_MANIFEST: 'shipping manifest',
};

export const QueryKeys = {
  ASSAY: 'assays',
  PANEL: 'panels',
  SOURCE: 'sources',
  PRICING: 'pricing',
  QUOTATION: 'quotations',
  TURNAROUND: 'turnarounds'
};

export const WorksheetType = {
	QWML: 'QWML',
	SPREADSHEET: 'SPREADSHEET',
  DYNAMIC_SPREADSHEET: 'DYNAMIC_SPREADSHEET',
};

export const COMPARISON_OPTIONS = {
  IS_EMPTY: 'IS_EMPTY',
  IS_NOT_EMPTY: 'IS_NOT_EMPTY',
  TEXT_CONTAINS: 'TEXT_CONTAINS',
  TEXT_DOES_NOT_CONTAIN: 'TEXT_DOES_NOT_CONTAIN',
  TEXT_STARTS_WITH: 'TEXT_STARTS_WITH',
  TEXT_ENDS_WITH: 'TEXT_ENDS_WITH',
  TEXT_IS_EXACTLY: 'TEXT_IS_EXACTLY',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO: 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO: 'LESS_THAN_OR_EQUAL_TO',
  IS_EQUAL_TO: 'IS_EQUAL_TO',
  IS_NOT_EQUAL_TO: 'IS_NOT_EQUAL_TO',
  IS_BETWEEN: 'IS_BETWEEN',
  IS_NOT_BETWEEN: 'IS_NOT_BETWEEN',
  IS_BETWEEN_INCLUSIVE: 'IS_BETWEEN_INCLUSIVE',
  IS_BETWEEN_STRICT_UPPER: 'IS_BETWEEN_STRICT_UPPER',
  IS_BETWEEN_STRICT_LOWER: 'IS_BETWEEN_STRICT_LOWER',
  IS_NOT_BETWEEN_INCLUSIVE: 'IS_NOT_BETWEEN_INCLUSIVE',
  IS_NOT_BETWEEN_STRICT_UPPER: 'IS_NOT_BETWEEN_STRICT_UPPER',
  IS_NOT_BETWEEN_STRICT_LOWER: 'IS_NOT_BETWEEN_STRICT_LOWER',
  CUSTOM_FORMULA_IS: 'CUSTOM_FORMULA_IS',
};
