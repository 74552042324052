import { useState, useEffect, useCallback } from "react";
import { getClosestParent } from "shared/utils/misc";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  const onClick = useCallback(
    (e) => {
      const targetParent = getClosestParent(e.target, ".menu-container");
      const elParent = getClosestParent(el.current, ".menu-container");

      if (targetParent && elParent && targetParent === elParent) {
        setIsActive(true);
      } else if (targetParent && targetParent.className === "menu-container") {
        setIsActive(false);
      } else if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [el, setIsActive]
  );
  useEffect(() => {
    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, onClick]);

  return [isActive, setIsActive];
};
